<template>
  <div class="top-player" @click="onPlayerClick">
    <div class="top-player__avatar">
      <component class="top-player__avatar-circle" :is="`${places[place - 1]}CircleIcon`" />
      <Avatar :avatar="avatar" show-winner/>
      <div class="top-player__avatar-icon">
        <component :is="`${places[place - 1]}Icon`" />
        <div class="top-player__place">{{ place }}</div>
      </div>
      <component class="top-player__decorate" :is="`${places[place-1]}DecorateIcon`"/>
    </div>
    <div class="top-player__name">{{ name }}</div>
    <div class="top-player__niche" v-if="niche">
      <span>Ниша: </span>
      {{ niche }}
    </div>
    <div class="top-player__niche--mock" v-else/>
    <div class="top-player__stats">
      <div class="top-player__block">
        <div class="top-player__block-title">Выручка</div>
        <div class="top-player__block-value">{{ proceeds.toLocaleString('ru') }} ₽</div>
      </div>
      <div class="top-player__block">
        <div class="top-player__block-title">Прибыль</div>
        <div class="top-player__block-value">{{ profit.toLocaleString('ru') }} ₽</div>
      </div>
      <div class="top-player__block">
        <div class="top-player__block-title">Гипотез</div>
        <div class="top-player__block-value">{{ hypotheses.toLocaleString('ru') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from '@/controls/Avatar'
import GoldCircleIcon from '@/icons/GoldCircleIcon'
import SilverCircleIcon from '@/icons/SilverCircleIcon'
import BronzeCircleIcon from '@/icons/BronzeCircleIcon'
import GoldIcon from '@/icons/GoldIcon'
import SilverIcon from '@/icons/SilverIcon'
import BronzeIcon from '@/icons/BronzeIcon'
import GoldDecorateIcon from '@/icons/GoldDecorateIcon'
import SilverDecorateIcon from '@/icons/SilverDecorateIcon'
import BronzeDecorateIcon from '@/icons/BronzeDecorateIcon'
export default {
  name: 'TopPlayer',
  components: {
    Avatar,
    GoldCircleIcon,
    SilverCircleIcon,
    BronzeCircleIcon,
    GoldIcon,
    SilverIcon,
    BronzeIcon,
    GoldDecorateIcon,
    SilverDecorateIcon,
    BronzeDecorateIcon,
  },
  props: {
    place: {
      type: Number,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    avatar: {
      type: String,
      required: false,
    },
    niche: {
      type: String,
      required: false,
    },
    proceeds: {
      type: Number,
      required: true,
    },
    profit: {
      type: Number,
      required: true,
    },
    hypotheses: {
      type: Number,
      required: true,
    },
    mockMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      places: [
        'Gold',
        'Silver',
        'Bronze',
      ]
    }
  },
  methods: {
    onPlayerClick() {
      this.$emit('player-click')
    }
  }
}
</script>

<style lang="scss">
.top-player {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background: #21222D;
  border-radius: 24px;
  padding: 145px 20px 20px;
  cursor: pointer;

  &__avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 128px;
    height: 128px;
    top: -40px;

    &-circle {
      position: absolute;
      z-index: 2;
      transform: translateY(4.2%);
    }

    .avatar {
      position: absolute;
      z-index: 3;
      width: 100%;
      height: 100%;
      border: none;
    }

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      z-index: 4;
      bottom: 0;
      transform: translateY(70%);
    }
  }

  &__decorate {
    position: absolute;
    z-index: 1;
    pointer-events: none;
    transform: translateY(1%);
  }

  &__place {
    position: absolute;
    font-size: 14px;
    text-align: center;
    color: #FFFFFF;
    transform: translateY(-35%);
  }

  &__name {
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 4px;
  }

  &__niche {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 18px;
    color: #ABBBF2;
    margin-bottom: 20px;

    & > span {
      color: #546FCE;
      white-space: pre;
      align-self: baseline;
    }

    &--mock {
      margin-bottom: 20px;
    }
  }

  &__stats {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 70px;
    gap: 15px;
  }

  &__block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #171821;
    border-radius: 12px;

    &-title {
      font-size: 12px;
      line-height: 16px;
      color: #546FCE;
      margin-bottom: 2px;
    }

    &-value {
      font-size: 14px;
      line-height: 18px;
      letter-spacing: -0.3px;
      color: #ABBBF2;
    }
  }
}
</style>
