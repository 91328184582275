<template>
  <span class="hypothesis">
    <div class="hypothesis__header">
      <div class="hypothesis__text">{{ hypothesis.text }}</div>
      <div class="hypothesis__expand" @click="$emit('createHypothesis')">
        <ExpandIcon />
      </div>
    </div>
    <div v-if="hypothesis.conclusions" class="hypothesis__result">
      <div :class="[{'positively': hypothesis.isSuccessful, 'negatively': !hypothesis.isSuccessful}, 'hypothesis__indicator']"></div>
      <div class="hypothesis__label">Результат</div>
      <div class="hypothesis__conclusions">{{ hypothesis.conclusions }}</div>
      <div v-if="hypothesis.expenses || hypothesis.revenue" class="hypothesis__stats">
        <MoneyBagIcon class="hypothesis__stat-icon" />
        <div class="hypothesis__stat-label">Потрачено</div>
        <MoneyBagIcon class="hypothesis__stat-icon" />
        <div class="hypothesis__stat-label">Заработано</div>
        <div class="hypothesis__stat-number">{{ hypothesis.expenses.toLocaleString('ru') || 0 }} ₽</div>
        <div class="hypothesis__stat-number">{{ hypothesis.revenue.toLocaleString('ru') || 0 }} ₽</div>
      </div>
    </div>
  </span>
</template>

<script>
import MoneyBagIcon from '@/icons/MoneyBagIcon'
import ExpandIcon from '@/icons/ExpandIcon'

export default {
  name: 'Hypothesis',
  components: {
    MoneyBagIcon,
    ExpandIcon
  },
  props: {
    hypothesis: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss">
.hypothesis {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  padding: 14px;
  background: linear-gradient(94.14deg, #6A9CE5 0.3%, #6562E3 97.95%);
  color: #FFFFFF;
  font-size: var(--edit-font-size);
  line-height: var(--edit-line-height);
  letter-spacing: var(--edit-letter-spacing);

  &__header {
    display: flex;
    align-items: flex-start;
  }

  &__expand {
    transform: translate(4px, -4px);
  }

  &__text {
    flex: 1;
    text-align: start;
    border-bottom: 1px solid rgba(171,187,242, 0.4);
    padding-bottom: 14px;

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
  }

  &__result {
    position: relative;
    text-align: start;
    margin-top: 14px;
  }

  &__label {
    margin-bottom: 6px;
    color: #ABBBF2;
    letter-spacing: -0.3px;
  }

  &__stats {
    margin-top: 14px;
    display: grid;
    grid-template-columns: auto 1fr auto 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 0 15px;
  }

  &__stat-icon {
    grid-row: span 2;
  }

  &__stat-label {
    grod-row: 1;
  }

  &__stat-number {
    grow-row: 2;
  }
}
</style>
