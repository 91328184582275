<template>
  <div class="combobox">
    <div class="combobox__value" @click="toggle">
      <div :class="[{ 'selected': selected }, 'combobox__text']">{{ selected ? (selected.name || selected.text) : placeholder }}</div>
      <TriangleIcon :class="[{ 'active': isCollapsed }, 'combobox__icon']"/>
    </div>
    <div class="combobox__list" v-if="isCollapsed">
      <div class="combobox__scroll">
        <div :class="[{ 'active': selected && (selected.id === obj.id) }, 'combobox__item']"
          v-for="obj in list" :key="obj.id" @click="select(obj.id)">{{ obj.name || obj.text}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import TriangleIcon from '@/icons/TriangleIcon'

export default {
  name: 'Combobox',
  props: {
    placeholder: {
      type: String,
      default: 'Выберите значение'
    },
    list: {
      type: Array,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isCollapsed: false
    }
  },
  computed: {
    selected() {
      return this.list.find(item => item.id === this.value) || null
    }
  },
  components: {
    TriangleIcon
  },
  methods: {
    select(index) {
      this.$emit('setValue', index)
      this.toggle()
    },
    toggle() {
      this.isCollapsed = !this.isCollapsed
    }
  }
}
</script>

<style lang="scss">
.combobox {
  position: relative;
  display: flex;
  align-items: center;
  letter-spacing: var(--edit-letter-spacing);
  font-size: var(--edit-font-size);
  line-height: var(--edit-line-height);
  color: var(--edit-color-normal);
  width: 210px;
  user-select: none;

  &__text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    
    &.selected {
      color: var(--edit-color);
    }
  }

  &__value {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 14px 10px;
    background-color: var(--edit-bg-form);
    border-radius: 8px;
    z-index: 4;
  }

  &__icon {
    transform-origin: 50% 50%;
    transition: .3s;
    &.active{
      transform: rotate(180deg);
    }
  }

  &__list {
    width: 250px;
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 0;
    bottom: -6px;
    transform: translateY(100%);
    background: #21222D;
    border: 1px solid var(--edit-color-normal);
    border-radius: 14px;
    z-index: 3;
  }
  &__scroll {
    overflow-y: auto;
    padding: 20px 14px;

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  &__item {
    cursor: pointer;
    width: 100%;
    font-size: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    text-align: left;
    padding: 7px 0;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover, &.active {
      color: var(--edit-color);
    }
  }
}
</style>
