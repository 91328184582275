import config from '@/config'
import net from './net'

export default class Game {
  constructor(id) {
    this.id = id
  }

  async getUser() {
    // return await net.get(`${config.authApi}/user/info/get`)
    return await net.get(`${config.api}/user/info/get`)
  }

  async getWaiters() {
    return await net.get(`${config.api}/game/${this.id}/waitors/get`)
  }

  async postWait() {
    return await net.get(`${config.api}/game/${this.id}/waitors/rush`)
  }

  async getBusinessTypes() {
    return await net.get(`${config.authApi}/auth/business_types/get`)
  }

  async getProfile(profileId) {
    return await net.get(`${config.api}/game/${this.id}/profile/${profileId}`)
  }

  async updateProfile(user) {
    return await net.post(`${config.authApi}/auth/user/edit`, { user })
  }

  async createAPoint(aPoint) {
    return await net.post(`${config.api}/game/${this.id}/a-point/create`, aPoint)
  }

  async updateAPoint(aPoint, id) {
    return await net.post(`${config.api}/game/${this.id}/a-point/${id}/update`, aPoint)
  }

  async updatePassword(passwords) {
    return await net.post(`${config.authApi}/auth/user/changePassword`, passwords)
  }

  async uploadAvatar(file) {
    return await net.post(`${config.authApi}/auth/upload/avatar`, file)
  }

  async getBusinessPhotos(id) {
    return await net.get(`${config.lms}/api/v2/business-photo/${id}`)
  }

  async uploadBusinessPhoto(file) {
    const formData = new FormData()
    formData.append('image', file)
    return await net.post(`${config.lms}/api/v2/business-photo`, formData)
  }

  async deleteBusinessPhoto(id) {
    return await net.delete(`${config.lms}/api/v2/business-photo/${id}`)
  }

  async getDashboardData() {
    return await net.get(`${config.api}/game/${this.id}/main`)
  }

  async getTrackerPlayers(userId, params) {
    return await net.get(`${config.api}/game/${this.id}/tracker/${userId}`, { params })
  }

  async getRating(params) {
    return await net.get(`${config.api}/game/${this.id}/rating/game`, { params })
  }

  async getRatingTrackers(params) {
    return await net.get(`${config.api}/game/${this.id}/rating/tracker/game`, { params })
  }

  async createSprint(sprint) {
    return await net.post(`${config.api}/game/${this.id}/sprint/create`, sprint)
  }

  async getSprint(sprintId) {
    return await net.get(`${config.api}/game/${this.id}/sprint/${sprintId}`)
  }

  async updateSprint(sprint, payload) {
    return await net.post(`${config.api}/game/${this.id}/sprint/${sprint.id}/update`, payload)
  }

  async createDayReport(payload) {
    return await net.post(`${config.api}/game/${this.id}/day-report/create`, payload)
  }

  async getDayReport(dayReportId) {
    return await net.get(`${config.api}/game/${this.id}/day-report/${dayReportId}`)
  }

  async updateDayReport(report, payload) {
    return await net.post(`${config.api}/game/${this.id}/day-report/${report.id}/update`, payload)
  }

  async createHypothesis(hypothesis) {
    return await net.post(`${config.api}/hypothesis/create`, hypothesis)
  }

  async likePost(hypothesisId) {
    return await net.post(`${config.api}/hypothesis/${hypothesisId}/like`)
  }

  async getHypothesis(hypothesisId) {
    return await net.get(`${config.api}/hypothesis/${hypothesisId}`)
  }

  async updateHypothesis(hypothesis, payload) {
    return await net.post(`${config.api}/hypothesis/${hypothesis.id}/update`, payload)
  }

  async deleteHypothesis(hypothesisId) {
    return await net.post(`${config.api}/hypothesis/${hypothesisId}/delete`)
  }

  async createHypothesisAction(payload) {
    return await net.post(`${config.api}/hypothesis/action/create`, payload)
  }

  async updateHypothesisAction(actionId, payload) {
    return await net.post(`${config.api}/hypothesis/action/${actionId}/update`, payload)
  }

  async deleteHypothesisAction(actionId) {
    return await net.post(`${config.api}/hypothesis/action/${actionId}/delete`)
  }

  async getHypothesisComments(id) {
    return await net.get(`${config.api}/hypothesis/${id}/comment/get`)
  }

  async createHypothesisComment(comment) {
    return await net.post(`${config.api}/hypothesis/comment/create`, comment)
  }

  async updateHypothesisComment(id, comment) {
    return await net.post(`${config.api}/hypothesis/comment/${id}/update`, comment)
  }

  async deleteHypothesisComment(id) {
    return await net.post(`${config.api}/hypothesis/comment/${id}/delete`)
  }

  async likeHypothesisComment(id) {
    return await net.post(`${config.api}/hypothesis/comment/${id}/like`)
  }

  async dislikeComment(id) {
    return await net.post(`${config.api}/hypothesis/comment/${id}/dislike`)
  }

  // params: {
  //     "dayNum": 3,
  //       "sprintNum": 2
  // }
  async getTrackerDay(trackerId, params) {
    return await net.get(`${config.api}/game/${this.id}/tracker/${trackerId}`, params)
  }
}
