import config from '@/config'

export function tzParseDate(text) {
  const date = new Date(text)
  const offset = date.getTimezoneOffset() / 60
  date.setHours(date.getHours() + offset + config.timezoneOffset)
  return date
}

export function tzDateToString(date) {
  return date.toISOString()
}
