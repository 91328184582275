const TIMEOUT = 400
const TRANSITION_CORRECTION = '0.1s'


export default class ProfileDayScroller {
  constructor(slider, currentScroll) {
    this.slider = slider;
    this.currentScroll = currentScroll;
    this.dayWidth = 400;
    this.gap = 20;
    this.isDown = false;
    this.elementMouse;
    this.mouseDownX;
    this.previousOffset = 0;
    this.offset = 0;
    this.maxMargin = 0;
    this.minMargin = -(4*this.dayWidth + 4*this.gap);
    this.timerId = 0;
  }

  enter() {
    this.slider.addEventListener('wheel', this.onWheel)
    this.slider.addEventListener('mousedown', this.onMousedown)
    this.slider.addEventListener('mouseleave', this.onMouseleave)
    this.slider.addEventListener('mousemove', this.onMousemove)
    this.slider.addEventListener('mouseup', this.onMouseup)
  }

  exit() {
    this.slider.removeEventListener('wheel', this.onWheel)
    this.slider.removeEventListener('mousedown', this.onMousedown)
    this.slider.removeEventListener('mouseleave', this.onMouseleave)
    this.slider.removeEventListener('mousemove', this.onMousemove)
    this.slider.removeEventListener('mouseup', this.onMouseup)
    this.clearTimeout()
  }

  clearTimeout() {
    clearTimeout(this.timerId)
    this.timerId = 0
  }

  scroll() {
    this.slider.style.transform = `translateX(${this.currentScroll + this.offset}px)`
  }

  alignScroll() {
    if (this.previousOffset !== this.offset) {
      this.previousOffset = this.offset
    }
    if (this.offset < 0 && Math.abs(this.offset) > (this.dayWidth/2)) {
      const move = this.currentScroll - ((this.gap + this.dayWidth)*Math.floor(Math.abs(this.offset)/(this.dayWidth + this.gap)) + (this.gap + this.dayWidth))
      this.currentScroll = move
    } else if (this.offset > 0 && this.offset > (this.dayWidth/2)) {
      const move = this.currentScroll + ((this.gap + this.dayWidth)*Math.floor(Math.abs(this.offset)/(this.dayWidth + this.gap)) + (this.gap + this.dayWidth))
      this.currentScroll = move
    }
    if (this.currentScroll > this.minMargin && this.currentScroll < this.maxMargin) {
      this.slider.style.transform = `translateX(${this.currentScroll}px)`
    } else if (this.currentScroll <= this.minMargin) {
      this.slider.style.transform = `translateX(${this.minMargin}px)`
      this.currentScroll = this.minMargin
    } else {
      this.slider.style.transform = `translateX(${this.maxMargin}px)`
      this.currentScroll = this.maxMargin
    }
    this.previousOffset = this.offset
    this.offset = 0
  }

  onWheel = (e) => {
    const dx = e.shiftKey ? e.deltaY : e.deltaX
    const dy = e.deltaY
    if (Math.abs(dx) < Math.abs(dy)) return
    e.preventDefault()
    this.offset -= dx
    this.slider.style.transition = '0s'
    this.scroll()

    if (this.timerId) this.clearTimeout()
    this.timerId = setTimeout(() => {
      this.slider.style.transition = TRANSITION_CORRECTION
      this.timerId = 0
      this.alignScroll()
    }, TIMEOUT)
  }

  onMousedown = (e) => {
    this.isDown = true
    this.slider.style.transition = 0
    this.slider.classList.add('profile-days--active')
    // Если мышка попала на блок с инпутом блокировать перемещение мышкой
    // Сделано для выделения текста мышкой и тп
    this.elementMouse = document.elementFromPoint(e.clientX, e.clientY);
    if (this.elementMouse.tagName === 'INPUT') {
      this.isDown = false
      return
    }
    this.mouseDownX = e.pageX
  }

  onMouseleave = () => {
    this.isDown = false
    this.slider.classList.remove('profile-days--active')
    this.alignScroll()
  }

  onMousemove = (e) => {
    if(!this.isDown) return
    const mouseMovement = e.pageX - this.mouseDownX
    this.slider.style.transform = `translateX(${this.currentScroll + mouseMovement}px)`
    if (mouseMovement) {
      this.offset = mouseMovement 
    }
  }

  onMouseup = () => {
    this.isDown = false
    this.slider.classList.remove('profile-days--active')
    this.alignScroll()
  }
}