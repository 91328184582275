export default function getCategoryColor(category) {
  let color
  let bgColor
  category = category.toLowerCase()
  switch (category) {
    case 'продажи':
      color = '#6979F8'
      bgColor = 'rgba(105, 121, 248, 0.15)'
      break
    case 'реклама':
      color = '#F880A2'
      bgColor = 'rgba(248, 128, 162, 0.15)'
      break
    case 'продвижение':
      color = '#EB5757'
      bgColor = 'rgba(235, 87, 87, 0.15)'
      break
    case 'мероприятия':
      color = '#F2994A'
      bgColor = 'rgba(242, 153, 74, 0.15)'
      break
    case 'анализ ниши':
      color = '#E3AD07'
      bgColor = 'rgba(242, 201, 76, 0.15)'
      break
    case 'анализ конкурентов':
      color = '#219653'
      bgColor = 'rgba(33, 150, 83, 0.15)'
      break
    case 'mvp':
      color = '#27AE60'
      bgColor = 'rgba(39, 174, 96, 0.15)'
      break
    case 'трафик':
      color = '#384DF5'
      bgColor = 'rgba(48, 64, 194, 0.15)'
      break
    case 'инвестиции':
      color = '#2F80ED'
      bgColor = 'rgba(47, 128, 237, 0.15)'
      break
    case 'команда':
      color = '#2D9CDB'
      bgColor = 'rgba(45, 156, 219, 0.15)'
      break
    case 'продукт':
      color = '#26B4E1'
      bgColor = 'rgba(86, 204, 242, 0.15)'
      break
    case 'другое':
      color = '#9B51E0'
      bgColor = 'rgba(155, 81, 224, 0.15)'
      break
    case 'маркетинг':
      color = '#DF3FF9'
      bgColor = 'rgba(223, 63, 249, 0.15)'
      break
    default:
      color = '#9B51E0'
      bgColor = 'rgba(155, 81, 224, 0.15)'
      break
  }
  return { color, bgColor }
}
