<template>
  <div class="send-message">
    <input type="text" v-model="comment" class="send-message__input" :placeholder="placeholder" @keydown.enter="send">
    <div class="send-message__button" @click="send"><SendIcon/></div>
  </div>
</template>

<script>
import SendIcon from '@/icons/SendIcon'
export default {
  name: 'SendMessage',
  components: {
    SendIcon,
  },
  props: {
    placeholder: {
      type: String,
      default: 'Введите сообщение...',
    }
  },
  data: () => ({
    comment: ''
  }),
  methods: {
    send() {
      this.$emit('send', this.comment)
      this.comment = ''
    }
  }
}
</script>

<style lang="scss">
.send-message {
  display: flex;
  align-items: center;
  height: 50px;
  min-height: 50px;
  background: #21222D;
  border-radius: 8px;
  margin-bottom: 8px;

  &__input {
    width: 100%;
    height: 100%;
    padding: 0 14px;
    background-color: transparent;
    border: none;
    outline: none;
    font-size: var(--edit-font-size);
    letter-spacing: var(--edit-letter-spacing);
    color: #546FCE;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &::placeholder {
      color: inherit;
      font-size: inherit;
    }
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0 14px;
    cursor: pointer;
  }
}
</style>
