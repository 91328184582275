<template>
  <div class="profile-reward">
    <BlueStarsIcon class="profile-reward__blue-stars" v-if="isReward"/>
    <RedStarsIcon class="profile-reward__red-stars" v-else/>
    <div class="profile-reward__title">{{ isReward ? 'Награда' : 'Цена слова' }} за {{ weekNumber }} неделю</div>
    <div class="profile-reward__text">{{ text }}</div>
    <div class="profile-reward__decorate"></div>
  </div>
</template>

<script>
import BlueStarsIcon from '@/icons/BlueStarsIcon'
import RedStarsIcon from '@/icons/RedStarsIcon'
export default {
  name: 'ProfileReward',
  components: {
    BlueStarsIcon,
    RedStarsIcon,
  },
  props: {
    isReward: {
      type: Boolean,
      default: false,
    },
    weekNumber: {
      type: Number,
      required: true,
    },
    text: {
      type: String,
      required: false,
    }
  }
}
</script>

<style lang="scss">
.profile-reward {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  position: relative;
  padding: 73px 10px 10px 10px;
  letter-spacing: var(--edit-letter-spacing);
  @extend %card-gradient;

  &__blue-stars, &__red-stars, &__title, &__text {
    z-index: 1;
  }

  &__blue-stars, &__red-stars {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  &__blue-stars {
    top: -6px;
  }

  &__red-stars {
    top: 20px;
  }

  &__title {
    color: #FFFFFF;
    margin-bottom: 10px;

    &:before {
      width: 375px;
      height: 375px;
    }

    &:after {
      width: 506px;
      height: 506px;
    }
  }

  &__text {
    font-size: 14px;
    line-height: 18px;
    color: #ABBBF2;
  }

  &__decorate:before, &__decorate:after, &__title:before, &__title:after {
    position: absolute;
    content: '';
    border-radius: 50%;
    top: 0;
    left: 0;
    transform: translateX(-45%) translateY(-50%);
    border: 2px solid rgba(255, 255, 255, 0.06);
    pointer-events: none;
  }

  &__decorate {
    &:before {
      width: 113px;
      height: 113px;
    }

    &:after {
      width: 241px;
      height: 241px;
    }
  }
}
</style>
