<template>
  <div class="money-edit">
    <input type="text" min="0" step="1" :readonly="readonly" v-model="currentValue"
      :class="[{ 'editing': showConfirmButton }, { 'readonly': readonly }, 'money-edit__value']"
      ref="input"
      @input="checkInput"
      @blur="onChanged"
      @keyup.enter="onChangedWithBlur"
    />
    <transition name="editing">
      <div v-if="showConfirmButton" class="money-edit__button" @click="notifyChanged">
        <MarkIcon />
      </div>
    </transition>
    <div class="money-edit__symbol">{{ symbol }}</div>
  </div>
</template>

<script>
import MarkIcon from '@/icons/MarkIcon'

export default {
  name: 'MoneyEdit',
  components: {
    MarkIcon
  },
  props: {
    value: {
      type: Number,
    },
    symbol: {
      type: String,
      default: '₽',
    },
    confirm: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      currentValue: '',
      oldValue: '',
    }
  },
  computed: {
    showConfirmButton() {
      return this.confirm && ((this.value !== null ? this.value.toString() : '') !== this.numberText)
    },
    numberText() {
      return this.removeSpace(this.currentValue)
    }
  },
  watch: {
    value() {
      this.syncValue()
    }
  },
  methods: {
    syncValue() {
      const valueText = this.value?.toString() || ''
      this.oldValue = valueText
      this.currentValue = this.formatNumber(valueText)
    },
    notifyChanged() {
      const number = parseInt(this.numberText, 10)
      this.$emit('input', number)
    },
    onChanged() {
      const number = parseInt(this.numberText, 10)
      this.$emit('change', number)
    },
    onChangedWithBlur() {
      this.$refs.input.blur()
    },
    removeSpace(str) {
      return str.replace(/\s+/g, '')
    },
    checkInput() {
      if (!this.validateInput(this.numberText)) {
        this.currentValue = this.oldValue
        return
      }
      this.currentValue = this.formatNumber(this.numberText)
      this.oldValue = this.currentValue
      if (!this.confirm) this.notifyChanged()
    },
    validateInput(value) {
      return !isNaN(value) || /^0$|^([1-9]\d*)$/.test(value) && value === ''
    },
    formatNumber(str) {
      const buf = new Array(str.length + Math.trunc((str.length - 1) / 3))
      for (let b = buf.length - 1, s = str.length - 1, f = 1; b >= 0; --b, ++f) buf[b] = (f % 4) === 0 ? ' ' : str[s--]
      return buf.join('')
    }
  },
  created() {
    this.syncValue()
  },
}
</script>

<style lang="scss">
.money-edit {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  font-size: var(--edit-font-size);
  line-height: var(--edit-line-height);
  overflow: hidden;


  &__value {
    outline: none;
    border: 1px solid transparent;
    text-align: left;
    width: 100%;
    padding: 14px 10px;
    border-radius: 8px;
    background-color: var(--edit-bg-form);
    color: var(--nav-item-active-color);

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    &:focus:not(.readonly), &.editing {
      border-color: var(--edit-form-border-color);
    }

    &.readonly {
      cursor: default;
    }
  }

  &__value::placeholder, &__symbol {
    color: var(--edit-form-placeholder);
  }

  &__symbol, &__button {
    position: absolute;
  }

  &__symbol {
    right: 10px;
    pointer-events: none;
    z-index: 1;
  }

  &__button {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    right: 2px;
    width: 32px;
    border-radius: 6px;
    height: calc(100% - 4px);
    background-color: var(--edit-form-border-color);
  }

  .editing-enter-active, .editing-leave-active {
    transition: all .3s ease;
  }

  .editing-leave-active {
    transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }

  .editing-enter, .editing-leave-to {
    opacity: 0;
  }

  .editing-enter {
    transform: translateX(10px);
  }
}
</style>
