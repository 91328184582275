<template>
  <div class="toolbar-menu">
    <div class="toolbar-menu__scroll">
      <div class="toolbar-menu__title">Внесение плана <div class="toolbar-menu__prompt">{{ selectedWeekNumber }} неделя</div></div>
      <div class="toolbar-menu__desc">
        <GoalIcon class="toolbar-menu__desc-icon"/>
        <div class="toolbar-menu__desc-info">
          <div class="toolbar-menu__desc-header">Поставь свои цели на {{ selectedWeekNumber }} неделю</div>
          <!-- <div class="toolbar-menu__desc-text">Ты можешь редактировать цели до 18:00 сегодня.</div> -->
        </div>
      </div>
      <div class="toolbar-menu__section">
        <div class="toolbar-menu__block">
          <div class="toolbar-menu__label">Выручка план</div>
          <MoneyEdit :value="week.userRevenueTarget || 0" @input="setRevenueTarget"/>
        </div>
        <div class="toolbar-menu__block">
          <div class="toolbar-menu__label">Прибыль план</div>
          <MoneyEdit :value="week.userProfitTarget || 0" @input="setProfitTarget"/>
        </div>
        <div class="toolbar-menu__block toolbar-menu__row">
          <div class="toolbar-menu__label">Награда</div>
          <Textarea ref="reward" :max="255" :value="reward" @input="setReward" placeholder="Укажи, какая награда тебя ждёт" />
        </div>
        <div class="toolbar-menu__block toolbar-menu__row">
          <div class="toolbar-menu__label">Цена слова</div>
          <Textarea ref="word" :max="255" :value="valueOfWord" @input="setValueOfWord" placeholder="Укажи, что сделаешь, если проиграешь" />
        </div>
      </div>
    </div>
    <div class="toolbar-menu__control">
      <Button :disabled="!isValid || loading" class="toolbar-menu__save" text="Сохранить" @click="save"/>
    </div>
  </div>
</template>

<script>
import MoneyEdit from '@/controls/MoneyEdit'
import Textarea from '@/controls/Textarea'
import Button from '@/controls/Button'
import GoalIcon from '@/icons/GoalIcon'
/* import { copyProps } from '@/utils/data' */
import { mapProps } from '@/utils/model'
import model from '@/model'
import controller from '@/controller'
import { sprintSchema } from '@/model/schema/game'

export default {
  name: 'SprintForm',
  components: {
    MoneyEdit,
    Textarea,
    Button,
    GoalIcon,
  },
  data() {
    return {
      week: sprintSchema.createDefault(),
      loading: false
    }
  },
  computed: {
    ...mapProps(model.game, ['selectedWeekNumber']),
    selectedWeek() {
      return model.game.traction.find(t => t.number === model.game.selectedWeekNumber)
    },
    reward() {
      return this.week.reward || ''
    },
    valueOfWord() {
      return this.week.valueOfWord || ''
    },
    isValid() {
      return this.week.totalRevenueTarget && this.week.totalProfitTarget && this.week.reward && this.week.valueOfWord
    }
  },
  created() {
    sprintSchema.copy(this.week, this.selectedWeek)
  },
  methods: {
    setRevenueTarget(totalRevenueTarget) {
      this.week.totalRevenueTarget = totalRevenueTarget
      this.week.userRevenueTarget = totalRevenueTarget
    },
    setProfitTarget(totalProfitTarget) {
      this.week.totalProfitTarget = totalProfitTarget
      this.week.userProfitTarget = totalProfitTarget
    },
    setReward(reward) {
      this.week.reward = reward
    },
    setValueOfWord(valueOfWord) {
      this.week.valueOfWord = valueOfWord
    },
    save() {
      this.updateData().then(() => {
        this.$emit('successForm')
      }).catch(error => {
        controller.app.addNotification({text:error.message || error.toString()}, 5000)
      })
    },
    async updateData() {
      this.loading = true
      if (this.week.id) {
        await controller.game.updateSprint(this.selectedWeek, this.week)
      }
      else {
        await controller.game.createSprint(this.selectedWeek, this.week)
      }
      this.loading = false
    }
  },
}
</script>
