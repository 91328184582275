<template>
<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_558:17685)">
<path d="M12.2201 8.19681C12.0597 6.10887 11.0876 4.80044 10.23 3.64582C9.43586 2.5769 8.75 1.65383 8.75 0.292166C8.75 0.182791 8.68875 0.0828225 8.59163 0.0327014C8.4942 -0.0177205 8.37714 -0.00946268 8.28857 0.0549045C7.00057 0.976553 5.92591 2.52992 5.55051 4.01206C5.28989 5.04394 5.25541 6.20397 5.25057 6.97011C4.06112 6.71606 3.79168 4.93686 3.78883 4.91747C3.77543 4.82519 3.71905 4.74488 3.63702 4.70102C3.55414 4.65773 3.45671 4.65459 3.37269 4.69618C3.31032 4.72637 1.84171 5.47258 1.75626 8.45198C1.75027 8.5511 1.75 8.65052 1.75 8.74992C1.75 11.6444 4.10525 13.9996 7 13.9996C7.00399 13.9998 7.00826 14.0004 7.01168 13.9996C7.01282 13.9996 7.01395 13.9996 7.01537 13.9996C9.903 13.9913 12.25 11.6393 12.25 8.74992C12.25 8.60437 12.2201 8.19681 12.2201 8.19681ZM7 13.4163C6.03498 13.4163 5.25 12.5801 5.25 11.5522C5.25 11.5172 5.24973 11.4818 5.25227 11.4385C5.26395 11.0051 5.34628 10.7092 5.43657 10.5123C5.60577 10.8758 5.90825 11.2098 6.39959 11.2098C6.5608 11.2098 6.69126 11.0794 6.69126 10.9182C6.69126 10.503 6.69982 10.0239 6.80321 9.59153C6.89522 9.20817 7.11509 8.80031 7.39367 8.47336C7.51756 8.89774 7.75912 9.2412 7.99496 9.57644C8.33249 10.056 8.68139 10.5519 8.74264 11.3975C8.74634 11.4477 8.75006 11.4981 8.75006 11.5522C8.75 12.58 7.96502 13.4163 7 13.4163Z" :fill="color"/>
</g>
<defs>
<clipPath id="clip0_558:17685">
<rect width="14" height="14" fill="white"/>
</clipPath>
</defs>
</svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#546FCE'
    }
  }
}
</script>