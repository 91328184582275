<template>
  <div class="textarea">
    <textarea class="textarea__edit" :value="value" :maxlength="max" :readonly="readonly" @input="setValue"></textarea>
    <div v-if="placeholder && value.length === 0" class="textarea__placeholder">{{ placeholder }}</div>
    <div v-if="!readonly" :class="[{'textarea__counter--limit': value.length >= limit}, 'textarea__counter']">{{ value.length }} / {{ limit }}</div>
  </div>
</template>

<script>
/* import logger from '@/logger' */

export default {
  name: 'Textarea',
  props: {
    value: {
      type: String,
      required: true,
    },
    max: {
      type: Number,
      default: 0,
    },
    placeholder: {
      required: false,
      type: String
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      limit: 255
    }
  },
  methods: {
    setValue(event) {
      if (event.target.value.length <= this.limit) {
        this.$emit('input', event.target.value)
      }
    }
  }
}
</script>

<style lang="scss">
.textarea {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 10px;
  
  &:last-child {
    margin-bottom: 0;
  }

  &__edit, &__placeholder {
    font-size: var(--edit-font-size);
    line-height: var(--edit-line-height);
    letter-spacing: var(--edit-letter-spacing);
  }

  &__edit {
    width: 100%;
    min-height: 125px;
    outline: none;
    resize: none;
    padding: 14px;
    border: none;
    border-radius: 12px;
    background-color: var(--edit-bg-color);
    color: var(--nav-item-active-color);

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  &__placeholder {
    position: absolute;
    left: 14px;
    top: 14px;
    color: var(--edit-color);
    pointer-events: none;
  }

  &__counter {
    font-size: 12px;
    position: absolute;
    right: 14px;
    bottom: 14px;
    color: #546FCE;
    pointer-events: none;

    &--limit {
      color: #e84848;
    }
  }
}
</style>
