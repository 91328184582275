<template>
  <div>
    <div class="profile-preview">
      <ProfileCard
        :name="fullname"
        :city="user.userInfo.city"
        :avatar="user.userInfo.avatar"
        :bizArea="user.userInfo.business.businessCategory.name"
        :bizNiche="user.userInfo.business.niche"
        :bizName="user.userInfo.business.name"
        :point-a-revenue="pointA.aPointRevenue"
        :point-a-profit="pointA.aPointProfit"
        :tracker="trackerName"
        @openSettings="openSidebar('ProfileForm')"
        @openTrackerProfile="openTrackerProfile"
      />
      <ProfileGallery :images="businessPhotos" @addPhoto="addPhoto" @deletePhoto="deletePhoto"/>
      <template v-if="week.userRevenueTarget && week.userProfitTarget">
        <ProfileReward isReward :weekNumber="week.number" :text="week.reward" class="profile-profit__reward" />
        <ProfileReward :weekNumber="week.number" :text="week.valueOfWord" class="profile-margin__reward"/>
      </template>
      <ProfileAddSprint v-else :week="week" @add="openSidebar('SprintForm', { week })" />
      <ProfileStat :factValue="week.totalRevenueFact" :targetValue="week.userRevenueTarget" desc="Выручка"
        class="profile-profit__stat" @change="openSidebar('SprintForm', { week })" />
      <ProfileStat :factValue="week.totalProfitFact" :targetValue="week.userProfitTarget" desc="Прибыль"
        class="profile-profit__stat" @change="openSidebar('SprintForm', { week })" />
    </div>
    <div class="profile-weeks-wrap">
      <div class="profile-weeks">
        <Button v-for="item in weeks" :key="item.number" class="profile-week" :text="item.number + ' неделя'"
          :active="item.number === selectedWeekNumber" :disabled="item.number > currentWeekNumber"
          :light="true"
          @click="selectSprint(item)"/>
      </div>
      <ProfileRating :value="userRating.position" :maxValue="userRating.totalCount" class="profile-rating"/>
    </div>
    <div class="profile-days" ref="scroller">
      <ProfileDay v-for="day in days" :key="day.day" :day="day" :week="week" @changeDay="changeDay"
        @createHypothesis="openNewHypothesisForm(day)"
        @closeHypothesis="(hypothesis) => openEditHypothesisForm(hypothesis, week, day)"/>
    </div>
    <Sidebar
      v-if="showSidebar"
      :canCloseWithoutSend="canCloseSidebar"
      :component="sidebarComponent"
      :classes="[]"
      :params="sidebarParams"
      @closeSidebar="closeSidebar"
    />
  </div>
</template>

<script>
import ProfileCard from '@/controls/ProfileCard'
import ProfileGallery from '@/controls/ProfileGallery'
import ProfileDay from '@/controls/ProfileDay'
import ProfileStat from '@/controls/ProfileStat'
import ProfileReward from '@/controls/ProfileReward'
import ProfileRating from '@/controls/ProfileRating'
import ProfileAddSprint from '@/controls/ProfileAddSprint'
import Button from '@/controls/Button'
import Sidebar from '@/controls/Sidebar'
import ProfileDayScroller from "@/controls/ProfileDayScroller.js"
/* import GoalIcon from '@/icons/GoalIcon' */
import model from '@/model'
import controller from '@/controller'
import { mapProps } from '@/utils/model'
import { daySchema, sprintSchema, hypothesisSchema } from '@/model/schema/game'
import logger from '@/logger'

const SPRINT_DAYS = 7

export default {
  name: 'Profile',
  components: {
    ProfileCard,
    ProfileGallery,
    ProfileDay,
    ProfileStat,
    ProfileReward,
    ProfileRating,
    ProfileAddSprint,
    Button,
    Sidebar,
    /* GoalIcon, */
  },
  data() {
    return {
      sidebarComponent: '',
      sidebarParams: null,
      showSidebar: false,
      canCloseSidebar: true,
      currentPositionScroll: 0,
      daysMargin: [
        0,
        0,
        -420,
        -820,
        -1260,
        -1680,
        -1680,
      ],
    }
  },
  computed: {
    ...mapProps(model.game, ['user', 'selectedWeekNumber', 'currentWeek', 'traction', 'userRating', 'tracker']),
    businessPhotos() {
      return model.game.businessPhotos
    },
    fullname() {
      let name = this.user.userInfo !== null ? `${this.user.userInfo.name} ${this.user.userInfo.lastname}` : ''
      return name.length > 1 ? name : 'Не указано'
    },
    week() {
      return this.traction.find(t => t.number === this.selectedWeekNumber) || sprintSchema.createDefault()
    },
    currentWeekNumber() {
      return this.traction.find(t => t.isCurrent)?.number || 0
    },
    weeks() {
      return this.traction
    },
    days() {
      return this.week?.dayReports?.length ? this.week.dayReports : this.createEmptyWeekReports()
    },
    pointA() {
      return this.user.aPoint
          ? {
            aPointProfit: this.user.aPoint.aPointProfit.toLocaleString('ru') + ' ₽',
            aPointRevenue: this.user.aPoint.aPointRevenue.toLocaleString('ru') + ' ₽' }
          : { aPointRevenue: undefined, aPointProfit: undefined }
    },
    trackerName() {
      return this.tracker.id ? this.tracker.userInfo.name + ' ' + this.tracker.userInfo.lastname : undefined
    }
  },
  methods: {
    async addPhoto(file) {
      const data = await controller.game.uploadBusinessPhoto(file)
      if (data.id) {
        const index = model.game.businessPhotos.findIndex(p => p.id <= 0)
        model.game.businessPhotos.splice(index, 1, data, model.game.businessPhotos[index])
      }
    },
    async deletePhoto(id) {
      const { status } = await controller.game.deleteBusinessPhoto(id)
      if (status === 204) {
        model.game.businessPhotos = model.game.businessPhotos.filter(p => p.id !== id)
      }
    },
    // initScrollListeners() {
    //   const slider = this.$refs.scroller
    //   const dayWidth = 400
    //   const gap = 20
    //   let isDown = false;
    //   let elementMouse;
    //   let mouseDownX
    //   let previousPersistentMouseMovement = 0
    //   let persistentMouseMovement = 0
    //   let maxMargin = 0
    //   let minMargin = -(4*dayWidth + 4*gap)
    //   console.log(minMargin)

    //   slider.addEventListener('mousedown', (e) => {
    //     isDown = true
    //     slider.style.transition = 0
    //     slider.classList.add('profile-days--active')
    //     // Если мышка попала на блок с инпутом блокировать перемещение мышкой
    //     // Сделано для выделения текста мышкой и тп
    //     elementMouse = document.elementFromPoint(e.clientX, e.clientY);
    //     if (elementMouse.tagName === 'INPUT') {
    //       isDown = false
    //       return
    //     }
    //     mouseDownX = e.pageX
    //   })

    //   slider.addEventListener('mouseleave', () => {
    //     isDown = false
    //     slider.classList.remove('profile-days--active')
    //     if (previousPersistentMouseMovement != persistentMouseMovement) {
    //       previousPersistentMouseMovement = persistentMouseMovement
    //       if (persistentMouseMovement < 0) {
    //         const move = this.currentPositionScroll - ((gap + dayWidth)*Math.floor(Math.abs(persistentMouseMovement)/(dayWidth + gap)) + (gap + dayWidth))
    //         this.currentPositionScroll = move
    //       } else {
    //         const move = this.currentPositionScroll + ((gap + dayWidth)*Math.floor(Math.abs(persistentMouseMovement)/(dayWidth + gap)) + (gap + dayWidth))
    //         this.currentPositionScroll = move
    //       }
    //     }
    //     if (this.currentPositionScroll > minMargin && this.currentPositionScroll < maxMargin) {
    //       slider.style.transform = `translateX(${this.currentPositionScroll}px)`
    //     } else if (this.currentPositionScroll <= minMargin) {
    //       slider.style.transform = `translateX(${minMargin}px)`
    //       this.currentPositionScroll = minMargin
    //     } else {
    //       slider.style.transform = `translateX(${maxMargin}px)`
    //       this.currentPositionScroll = maxMargin
    //     }
    //   })

    //   slider.addEventListener('mousemove', (e) => {
    //     if(!isDown) return

    //     const mouseMovement = e.pageX - mouseDownX
    //     slider.style.transform = `translateX(${this.currentPositionScroll + mouseMovement}px)`
    //     if (mouseMovement) {
    //       persistentMouseMovement = mouseMovement 
    //     }
    //   })

    //   slider.addEventListener('mouseup', (e) => {
    //     isDown = false
    //     slider.classList.remove('profile-days--active')
    //     elementMouse = document.elementFromPoint(e.clientX, e.clientY);
    //     if (elementMouse.tagName === 'INPUT') {
    //       return
    //     }
    //     if (previousPersistentMouseMovement != persistentMouseMovement) {
    //       previousPersistentMouseMovement = persistentMouseMovement
    //       if (persistentMouseMovement < 0 && Math.abs(persistentMouseMovement) > (dayWidth/2)) {
    //         const move = this.currentPositionScroll - ((gap + dayWidth)*Math.floor(Math.abs(persistentMouseMovement)/(dayWidth + gap)) + (gap + dayWidth))
    //         this.currentPositionScroll = move
    //       } else if (persistentMouseMovement > 0 && persistentMouseMovement > (dayWidth/2)) {
    //         const move = this.currentPositionScroll + ((gap + dayWidth)*Math.floor(Math.abs(persistentMouseMovement)/(dayWidth + gap)) + (gap + dayWidth))
    //         this.currentPositionScroll = move
    //       }
    //     }
    //     if (this.currentPositionScroll >= minMargin && this.currentPositionScroll < maxMargin) {
    //       slider.style.transform = `translateX(${this.currentPositionScroll}px)`
    //     } else if (this.currentPositionScroll <= minMargin) {
    //       slider.style.transform = `translateX(${minMargin}px)`
    //       this.currentPositionScroll = minMargin
    //     } else {
    //       slider.style.transform = `translateX(${maxMargin}px)`
    //       this.currentPositionScroll = maxMargin
    //     }
    //   })
    // },
    openTrackerProfile() {
      this.$router.push({ path: `/profile/${this.user.id}` })
    },
    openNewHypothesisForm(day) {
      const hypothesis = hypothesisSchema.createDefault()
      this.openSidebar('HypothesisForm', { hypothesis, sprint: this.week, day })
    },
    openEditHypothesisForm(hypothesis, sprint, day) {
      this.openSidebar('HypothesisForm', { hypothesis, sprint, day })
    },
    openSidebar(componentName, params, canCloseSidebar = true) {
      this.sidebarComponent = componentName
      this.sidebarParams = params
      this.showSidebar = true
      this.canCloseSidebar = canCloseSidebar
    },
    closeSidebar() {
      this.showSidebar = false
      this.sidebarParams = null
      this.sidebarComponent = ''
    },
    selectSprint(sprint) {
      if (this.selectedWeekNumber === sprint.number) return
      controller.game.selectSprint(sprint)
    },
    createEmptyWeekReports() {
      const days = new Array(SPRINT_DAYS)
      for (let d = 0; d < SPRINT_DAYS; ++d) {
        const day = daySchema.createDefault()
        day.day = d + 1
        days[d] = day
      }

      return days
    },
    checkEmptyTargets() {
      if (!this.week.userProfitTarget && !this.week.userRevenueTarget) {
        this.openSidebar('SprintForm', { week: this.week }, false)
      }
    },
    changeDay({ day, changes }) {
      if (day.id) controller.game.updateDayReport(this.week, day, changes)
      else controller.game.createDayReport(this.week, day, changes)
    },
    onDayMousedown(event) {
      logger.warn('-- onDayMousedown event:', event)
    },
    onDayMouseup(event) {
      logger.warn('-- onDayMouseup event:', event)
    },
    onDayMousemove(event) {
      logger.warn('-- onDayMousemove event:', event)
    }
  },
  created() {
    const sprint = this.week

    logger.warn('-- Profile.created sprint:', sprint)

    if (sprint.id) controller.game.loadSprint(sprint)
  },
  mounted () {
    this.currentPositionScroll = this.daysMargin[this.days.indexOf(this.days.find(day => day.status === 'current'))]
    this.checkEmptyTargets()
    if (window.innerWidth >= 1240) {
      this.$refs.scroller.style.transform = `translateX(${this.currentPositionScroll}px)`
      this.profileDayScroller = new ProfileDayScroller(this.$refs.scroller, this.currentPositionScroll)
      this.profileDayScroller.enter()
    }
  },
  beforeDestroy() {
    if (window.innerWidth >= 1240) {
      this.profileDayScroller.exit()
    }
  }
}
</script>

<style lang="scss">
.profile-preview {
  display: grid;
  grid-template-columns: $size*4+$padding*3 $size*3+$padding*2 $size*2.5+$padding*2-$padding/2 $size*2.5+$padding*2-$padding/2;
  /* grid-template-areas: */
  /* grid-template-columns: var(--profile-preview-columns); */
  /* grid-template-columns: repeat(auto-fit, $size); */
  /* grid-template-columns: $size*4 $size*3 $size*2.5 $size*2.5; */
  gap: $padding;
}

.profile-card {
  /* width: $size * 4; */
  /* grid-column: span 4; */
  border-radius: 24px;
  background-color: #21222D;
}

.profile-gallery {
  /* width: $size * 3; */
  /* grid-column: span 3; */
  border-radius: 24px;
  background-color: #21222D;
}

.profile-profit, .profile-margin {
  /* grid-column: span 3; */
  /* display: flex; */
  /* flex-direction: column; */
  /* width: $size * 2.5; */

  &__reward {
    border-radius: 14px;
    background-color: #21222D;
  }

  &__stat {
    height: 80px;
    border-radius: 14px;
  }
}

.making-plan {
  display: flex;
  align-items: center;
  @extend %card-gradient;
  padding: 25px 63px 35px 30px;
  border-radius: 14px;
  grid-column-start: 3;
  grid-column-end: 5;

  &__icon {
    margin-right: 30px;
    min-width: 160px;
    width: 160px;
    height: 160px;
    z-index: 1;
  }

  &__text {
    text-align: left;
    font-size: 18px;
    line-height: 22px;
    color: #ABBBF2;
    margin-bottom: 30px;
  }
}

.profile-weeks-wrap {
  display: grid;
  /* grid-template-areas: var(--profile-weeks-template-areas); */
  /* grid-template-columns: var(--profile-weeks-columns); */
  /* grid-template-rows: var(--profile-weeks-rows); */
  gap: 20px;
  margin-top: 40px;
}

.profile-weeks {
  display: contents;
}

.profile-week {
  height: 50px;
  border-radius: 14px;
  background-color: #21222D;
}

.profile-rating {
  height: 50px;
  border-radius: 14px;
  background-color: #21222D;
}

.profile-days {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 40px;
  padding-bottom: 20px;
}

.profile-day {
  background: linear-gradient(112.32deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 101.12%);
  backdrop-filter: blur(42px);
  flex: 1 0 400px;
  border-radius: 24px;
}

@media screen and (max-width: $medium - 2) {

  .profile-preview, .profile-weeks-wrap, .profile-days {
    padding: 0 15px;
  }

  .profile-preview {
    grid-template-columns: 1fr;
  }

  .profile-weeks-wrap {
    grid-template-columns: repeat(2, 1fr);
    margin-top: $margin;
  }

  .profile-rating {
    grid-row: 1;
    grid-column: span 2;
    margin-bottom: $margin;
  }

  .profile-add-sprint {
    flex-direction: column;

    &__icon {
      margin: 0;
    }

    &__content {
      margin: 0;
    }
  }
}

@media screen and (min-width: $medium) and (max-width: $large - 2) {
  .page {
    width: $medium;
  }

  .profile-preview {
    grid-template-columns: repeat(auto-fit, $size);
  }
  .profile-card {
    grid-column: span 4;
  }
  .profile-gallery {
    grid-column: span 4;
  }
  .profile-profit, .profile-margin {
    grid-column: span 4;
  }
  .profile-weeks-wrap {
    grid-template-columns: repeat(4, $size*2+$padding);
    grid-template-rows: 1fr 1fr;
  }
  .profile-week {
    grid-row: 2;
  }
  .profile-rating {
    grid-row: 1;
    grid-column: 3 / span 2;
  }
  .profile-add-sprint {
    grid-column: span 8;
  }
  .profile-profit__reward, .profile-margin__reward, .profile-profit__stat, .profile-margin__stat {
    grid-column: span 4;
  }
}

@media screen and (min-width: $large) {
  .page {
    width: $large;
  }
  .profile-preview {
    grid-template-rows: 1fr 80px;
    grid-template-columns: 1.75fr 1fr 1fr 1fr;
  }
  .profile-card, .profile-gallery {
    grid-row: span 2;
  }
  .profile-weeks-wrap {
    grid-template-columns: repeat(6, [col] $size*2+$padding);
    gap: $padding;
  }
  .profile-rating {
    grid-row: 1;
    grid-column: 6;
  }
  .profile-add-sprint {
    grid-column: span 2;
  }
  .profile-days {
    // scroll-behavior: smooth;
    display: flex;
    align-items: flex-start;
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab; // В хроме просто grab не отрабатывает
    min-width: 2920px;
    overflow: hidden;
    transition: .05s;

    &--active {
      cursor: grabbing;
      transition: 0s;
    }

    &::-webkit-scrollbar {
      height: 0;
      opacity: 0;
    }
  }
}
</style>
