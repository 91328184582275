import { tzParseDate, tzDateToString } from '@/utils/time'
import { copyProps } from '@/utils/data'
import logger from '@/logger'

export const DAY_STATUS_CURRENT = 'current'
export const DAY_STATUS_FUTURE = 'future'
export const DAY_STATUS_PAST = 'past'

export const GAME_ROLE_PARTICIPANT = 'participant'
export const GAME_ROLE_TRACKER = 'tracker'
export const GAME_ROLE_WATCHER = 'watcher'

export const userSchema = Object.freeze({
  createDefault() {
    return {
      id: undefined,
      userInfo: {
        business: {
          name: '',
          niche: '',
          businessCategory: {
            id: 0,
            name: ''
          }
        },
        name: '',
        lastname: '',
        city: '',
        email: '',
        phone: '',
        avatar: ''
      },
      aPoint: {
        aPointRevenue: 0,
        aPointProfit: 0
      }
    }
  },
  serialize(user) {
    return this.copy({
      userInfo: {
        business: {
          businessCategory: {}
        }
      }
    }, user)
  },
  deserialize(data) {
    return this.copy({
      userInfo: {
        business: {
          businessCategory: {}
        }
      },
      aPoint: {}
    }, data)
  },
  copy(dest, source) {
    dest.id = source.id
    copyProps(dest.userInfo.business, source.userInfo.business, ['name', 'niche'])
    copyProps(dest.userInfo.business.businessCategory, source.userInfo.business.businessCategory, ['id', 'name'])
    copyProps(dest.userInfo, source.userInfo, ['name', 'lastname', 'city', 'email', 'phone', 'avatar'])
    if (source.aPoint && Object.keys(source.aPoint).length > 0) {
      dest.aPoint = {}
      copyProps(dest.aPoint, source.aPoint, ['aPointRevenue', 'aPointProfit', 'id'])
    }
    return dest
  },
  copyPointA(dest, source) {
    dest.aPoint = source
    return dest
  }
})

export const sprintSchema = Object.freeze({
  createDefault() {
    return {
      id: null,
      number: 0,
      valueOfWord: '',
      reward: '',
      totalRevenueTarget: 0,
      totalRevenueFact: 0,
      totalProfitTarget: 0,
      totalProfitFact: 0,
      userProfitTarget: 0,
      userRevenueTarget: 0,
      isCurrent:false,
      dayReports:[]
    }
  },
  serialize(sprint) {
    return copyProps({
      dayReports: sprint.dayReports.map(d => daySchema.serialize(d))
    }, sprint, ['id', 'number', 'valueOfWord', 'reward',
      'totalRevenueTarget', 'totalRevenueFact', 'totalProfitTarget',
      'totalProfitFact', 'userProfitTarget', 'userRevenueTarget', 'isCurrent'])
  },
  deserialize(data) {
    return copyProps({
      dayReports: data.dayReports ? data.dayReports.map(d => daySchema.deserialize(d)) : []
    }, data, ['id', 'number', 'valueOfWord', 'reward',
      'totalRevenueTarget', 'totalRevenueFact', 'totalProfitTarget',
      'totalProfitFact', 'userProfitTarget', 'userRevenueTarget', 'isCurrent'])
  },
  copy(dest, source) {
    copyProps(dest, source, ['id', 'number', 'valueOfWord', 'reward',
      'totalRevenueTarget', 'totalRevenueFact', 'totalProfitTarget',
      'totalProfitFact', 'userProfitTarget', 'userRevenueTarget'])
    dest.dayReports = source.dayReports.map(d => daySchema.copy({}, d))
    // disable isCurrent modification 1 -> 0
    if (!dest.isCurrent) dest.isCurrent = source.isCurrent
    return dest
  }
})

export const waitersSchema = Object.freeze({
  createDefault() {
    return {
      isChecked: 0,
      num: 0
    }
  },
  serialize(waiters) {
    return this.copy({}, waiters)
  },
  deserialize(data) {
    return this.copy({}, data)
  },
  copy(dest, source) {
    dest.isChecked = source.isChecked
    dest.num = source.count
    return dest
  }
})

export const dashboardSchema = Object.freeze({
  createDefault() {
    return {
      top: {
        day: 1,
        totalDays: 31,
        totalRevenue: 0,
        totalProfit: 0,
        activeUsers: {
          participantCount: 0
        },
        totalUsers: {
          participantCount: 0
        }
      },
      participantTop: [1, 2, 3].map(i => ({
        hypothesesCount: 0,
        profitFact: 0,
        profitTarget: 0,
        revenueFact: 0,
        revenueTarget: 0,
        user: {
          id: i,
          userInfo: {
            name: '',
            lastname: '',
            city: '',
            avatar: '',
            business: {
              name: '',
              niche: '',
              businessCategory: {
                id: 0,
                name: ''
              }
            }
          },
        }
      })),
      hypothesisTop: [1].map(i => ({
        id: i,
        text: '',
        ddl: new Date(),
        status: '',
        category: '',
        businessCategory: '',
        isPublic: 1,
        isSuccessful: 1,
        revenue: 1000,
        expenses: 100,
        profit: 900,
        conclusion: '',
        likes: 0,
        hypothesisCommentsCount: 0,
        hypothesisActions: [
          {
            id: 10000000,
            action: 'action',
            isComplete: 0
          }
        ],
        user: {
          id: 0,
          userInfo: {
            name: '',
            lastname: '',
            city: '',
            avatar: ''
          }
        }
      }))
    }
  },
  createEmpty() {
    return {
      top: {
        day: 1,
        totalDays: 31,
        totalRevenue: 0,
        totalProfit: 0,
        activeUsers: {
          participantCount: 0
        },
        totalUsers: {
          participantCount: 0
        }
      },
      participantTop: [],
      hypothesisTop: []
    }
  },
  serialize(dashboard) {
    return {
      top: copyProps({}, dashboard.top, ['day', 'totalDays', 'totalRevenue', 'totalProfit', 'activeUsers', 'totalUsers']),
      participantTop: dashboard.participantTop.map(p => copyProps({
        user: copyProps({
          userInfo: p.user.userInfo
            ? copyProps({
              business: copyProps({
                businessCategory: copyProps({}, p.user.userInfo.business.businessCategory, ['id', 'name'])
              }, p.user.userInfo.business, ['name', 'niche'])
            }, p.user.userInfo, ['name', 'lastname', 'city', 'avatar'])
            : { name: '', lastname: '', city: '', avatar: '', business: { name: '', niche: '', businessCategory: { id: 0, name: '' } } }
        }, p.user, ['id']),
      }, p, ['hypothesesCount', 'profitFact', 'profitTarget', 'revenueFact', 'revenueTarget'])),
      hypothesisTop: dashboard.hypothesisTop.map(h => h ? copyProps({
        hypothesisActions: h.hypothesisActions.map(ha => copyProps({}, ha, ['id', 'action', 'isComplete'])),
      }, h, ['id', 'isPublic', 'isSuccessful', 'likes', 'profit', 'revenue', 'hypothesisCommentCount',
        'status', 'text', 'expenses', 'ddl', 'conclusions', 'category', 'businessCategory']): null)
        .map(c => c ? ({ ...c, ddl: tzParseDate(c.ddl)}) : null)
    }
  },
  deserialize(data) {
    return {
      top: copyProps({}, data.top, ['day', 'totalDays', 'totalRevenue', 'totalProfit', 'activeUsers', 'totalUsers']),
      participantTop: data.participantTop.map(p => copyProps({
        user: copyProps({
          userInfo: p.user.userInfo
            ? copyProps({
              business: copyProps({
                  businessCategory: copyProps({}, p.user.userInfo.business.businessCategory, ['id', 'name'])
                }, p.user.userInfo.business, ['name', 'niche'])
              }, p.user.userInfo, ['name', 'lastname', 'city', 'avatar'])
            : { name: '', lastname: '', city: '', avatar: '', business: { name: '', niche: '', businessCategory: { id: 0, name: '' } } }
        }, p.user, ['id']),
      }, p, ['hypothesesCount', 'profitFact', 'profitTarget', 'revenueFact', 'revenueTarget'])),
      hypothesisTop: data.hypothesisTop.map(h => h ? copyProps({
        hypothesisActions: h.hypothesisActions.map(ha => copyProps({}, ha, ['id', 'action', 'isComplete'])),
      }, h, ['id', 'isPublic', 'isSuccessful', 'likes', 'profit', 'revenue', 'hypothesisCommentCount',
        'status', 'text', 'expenses', 'ddl', 'conclusions', 'category', 'businessCategory', 'user']) : null)
        .map(c => c ? ({ ...c, ddl: tzParseDate(c.ddl)}) : null)
    }
  },
  copy(dest, source) {
    dest.dashboardData = source
    return dest
  }
})

export const hypothesisSchema = Object.freeze({
  STATUS_NONE: '',
  STATUS_NEW: 'Новая',
  STATUS_TESTED: 'Протестирована',
  createDefault() {
    return {
      id: 0,
      text: '',
      status: hypothesisSchema.STATUS_NONE,
      category: '',
      ddl: null,
      businessCategory: '',
      revenue: 0,
      expenses: 0,
      profit: 0,
      hypothesisLikeCount: 0,
      likes: {
        count: 0,
        isChecked: 0
      },
      isSuccessful: false,
      isPublic: true,
      conclusions: '',
      actions: [],
      hypothesisActions: []
    }
  },
  createDefaultAction() {
    return {
      id: null,
      action: '',
      isComplete: false
    }
  },
  serialize(hypothesis) {
    return copyProps({
      ddl: tzDateToString(hypothesis.ddl),
      actions: hypothesis.actions.map(a => ({ id: a.id, action: a.action, isComplete: +a.isComplete })),
      // hypothesisActions: hypothesis.actions.map(a => copyProps({}, a, ['id', 'action', 'isComplete'])),
      isSuccessful: +hypothesis.isSuccessful,
      isPublic: +hypothesis.isPublic
    }, hypothesis, ['id', 'text', 'status', 'category', 'businessCategory', 'revenue',
      'expenses', 'profit', 'hypothesisLikeCount', 'conclusions'])
  },
  deserialize(data) {
    return copyProps({
      ddl: tzParseDate(data.ddl),
      actions: data.hypothesisActions.map(a => ({id: a.id, action: a.action, isComplete: !!a.isComplete})),
      // actions: data.actions.map(a => copyProps({}, a, ['id', 'action', 'isComplete']))
      isSuccessful: !!data.isSuccessful,
      isPublic: !!data.isPublic
    }, data, ['id', 'text', 'status', 'category', 'businessCategory', 'revenue',
      // 'expenses', 'profit', 'hypothesisLikeCount', 'isSuccessful', 'conclusions'])
      'expenses', 'profit', 'hypothesisLikeCount', 'conclusions'])
  },
  copy(dest, source) {
    // dest.actions = source.hypothesisActions.map(a => copyProps({}, a, ['id', 'action', 'isComplete']))
    dest.actions = source.actions.map(a => copyProps({}, a, ['id', 'action', 'isComplete']))
    return copyProps(dest, source, ['id', 'text', 'status', 'category', 'ddl', 'businessCategory', 'revenue',
      'expenses', 'profit', 'hypothesisLikeCount', 'isSuccessful', 'isPublic', 'conclusions'])
  },
  cloneAction(source) {
    return {
      id: source.id,
      action: source.action,
      isComplete: source.isComplete
    }
  },
  getNextStatus(status) {
    switch (status) {
      case hypothesisSchema.STATUS_NONE:
        return hypothesisSchema.STATUS_NEW
      case hypothesisSchema.STATUS_NEW:
        return hypothesisSchema.STATUS_TESTED
      case hypothesisSchema.STATUS_TESTED:
        return status
      default:
        logger.error(`[schema.hypothesis.getNextStatus] unknown status "${status}"`)
        break
    }
    return null
  },
})

export const hypothesisCommentsSchema = Object.freeze({
  createDefault() {
    return [{
      id: 0,
      createdAt: new Date().toISOString(),
      comment: '',
      user: {
        id: 0,
        userInfo: {
          name: '',
          lastname: '',
          avatar: ''
        }
      },
      likes: {
        count: 0,
        isChecked: 0
      },
      dislikes: {
        count: 0,
        isChecked: 0
      }
    }]
  },
  serialize(comments) {
    return comments.map(c => copyProps({}, c, ['id', 'createdAt', 'comment', 'user', 'likes', 'dislikes']))
  },
  deserialize(data) {
    return data.map(c => copyProps({}, c, ['id', 'createdAt', 'comment', 'user', 'likes', 'dislikes']))
  },
  copy(dest, source) {
    dest = source.map(c => copyProps({}, c, ['id', 'createdAt', 'comment', 'user', 'likes', 'dislikes']))
    console.log(dest)
    return dest
  },
})

export const daySchema = Object.freeze({
  createDefault() {
    return {
      id: null,
      day: 0,
      availableFrom: null,
      availableTo: null,
      revenueTarget: null,
      revenueFact: null,
      profitTarget: null,
      profitFact: null,
      status: '',
      hypotheses: []
    }
  },
  serialize(day) {
    const data = copyProps({}, day, ['id', 'day', 'revenueTarget', 'revenueFact',
      'profitTarget', 'profitFact', 'status'])
    data.availableFrom = tzDateToString(day.availableFrom)
    data.availableTo = tzDateToString(day.availableTo)
    data.hypotheses = day.hypotheses.map(h => hypothesisSchema.serialize(h))
    return data
  },
  deserialize(data) {
    const day = copyProps({}, data, ['id', 'day', 'revenueTarget', 'revenueFact',
      'profitTarget', 'profitFact', 'status'])
    day.availableFrom = tzParseDate(data.availableFrom)
    day.availableTo = tzParseDate(data.availableTo)
    day.hypotheses = data.hypotheses.map(h => hypothesisSchema.deserialize(h))
    return day
  },
  copy(dest, source) {
    dest.hypotheses = source.hypotheses.map(h => hypothesisSchema.copy({}, h))
    return copyProps(dest, source, ['id', 'day', 'availableFrom', 'availableTo',
      'revenueTarget', 'revenueFact', 'profitTarget', 'profitFact', 'status'])
  }
})

export const profileSchema = Object.freeze({
  ROLE_NOBODY: '',
  ROLE_PARTICIPANT: 'participant',
  ROLE_TRACKER: 'tracker',
  ROLE_WATCHER: 'watcher',
  createDefault() {
    return {
      authUser: {
        id: 0,
        authUserId: 0,
        gameRole: ''
      },
      gameRole: '',
      user: userSchema.createDefault(),
      total: [],
      traction: [],
      selectedWeekNumber: 0,
      currentWeek: sprintSchema.createDefault(),
      businessCategories: [],
      hypothesisCategories: [
        'Анализ ниши',
        'Анализ конкурентов',
        'MVP',
        'Продажи',
        'Трафик',
        'Инвестиции',
        'Команда',
        'Продукт',
        'Другое'
      ],
      tracker: {
        id: 0,
        userInfo: {
          avatar: '',
          lastname: '',
          name: ''
        }
      },
      userRating: {
        position: 0,
        totalCount: 0
      },
      rating: ratingSchema.createDefault(),
    }
  },
  serialize(profile) {
    return {
      gameRole: profile.gameRole,
      user: profile.user,
      traction: profile.traction
    }
  },
  deserialize(data) {
    if (data.gameRole === GAME_ROLE_WATCHER) {
      return {
        gameRole: data.gameRole,
        user: data
      }
    }

    if (data.gameRole === GAME_ROLE_TRACKER) {
      return {
        gameRole: data.gameRole,
        user: data.user,
        total: data.total,
        traction: [] // В трекере используется массив из trackerSchema
      }
    }

    return {
      gameRole: data.gameRole,
      user: data.user,
      traction: data.traction.map((sdata, s) => sprintSchema.deserialize({ ...sdata, number: s + 1 })),
      userRating: copyProps({}, data.rating, ['position', 'totalCount']),
      tracker: copyProps({}, data.tracker, ['id', 'userInfo'])
    }
  },
  copy(dest, source) {
    dest.gameRole = source.gameRole
    dest.user = source.user
    dest.traction = source.traction?.map(t => sprintSchema.copy({}, t)) ?? []
    dest.userRating = source.userRating
    if (source.gameRole !== 'tracker') {
      dest.tracker = source.tracker
    } else {
      dest.tracker = trackerSchema.createDefault()
    }
    return dest
  }
})

export const trackerSchema = Object.freeze({
  createDefault() {
    return {
      traction: [],
      day: 1,
      players: [],
      total: null,
      week: {},
      startedWeek: {},
    }
  },
  deserialize(data) {
    // TODO Дождаться нормального метода но получение дней со всех недель
    if (data.week?.days && data.week.days.length === 0) {
      data.week.days = [
          {day:1, participants:[], status:"past"},
          {day:2, participants:[], status:"past"},
          {day:3, participants:[], status:"past"},
          {day:4, participants:[], status:"past"},
          {day:5, participants:[], status:"past"},
          {day:6, participants:[], status:"past"},
          {day:7, participants:[], status:"past"},
      ]
    }
    return {
      traction: data.traction.map((sdata, s) => {
        const sprint = sdata
        sprint.number = s + 1
        return sprint
      }),
      day: data.day,
      players: data.players,
      week: data.week,
      startedWeek: data.startedWeek,
      total: data.startedWeek
    }
  },
  copy(dest, source) {
    dest.traction = source.traction
    dest.day = source.day
    dest.players = source.players
    dest.week = source.week
    dest.startedWeek = source.startedWeek
    dest.total = source.total
    return dest
  }
})

export const ratingSchema = Object.freeze({
  createDefault() {
    return {
      day: 1,
      dayTrackers: 1,
      participants: [],
      disabledLoad: false,
      trackers: [],
      disabledLoadTrackers: false,
    }
  },

  copyParticipants(dest, source) {
    if (source.day === 1) {
      dest.participants = [...source.participants]
      dest.disabledLoad = false

      return dest;
    }
    dest.day = source.day
    if (source && source.participants.length > 0) {
      dest.participants = [...dest.participants, ...source.participants]
    } else {
      dest.disabledLoad = true
    }

    return dest
  },
  copyTrackers(dest, source) {
    if (source.dayTrackers === 1) {
      dest.trackers = [...source.trackers]
      dest.disabledLoadTrackers = false

      return dest;
    }
    dest.dayTrackers = source.dayTrackers
    if (source.trackers && source.trackers.length > 0) {
      dest.trackers = [...dest.trackers, ...source.trackers]
    } else {
      dest.disabledLoadTrackers = true
    }

    return dest
  }
})

export const businessPhotosSchema = Object.freeze({
  createDefault() {
    return [-1, -2, -3].map(i => ({
      id: i,
      width: 0,
      height: 0,
      publicPath: ''
    }))
  },
  serialize(photos) {
    return photos.map(p => copyProps({}, p, ['id', 'width', 'height', 'publicPath']))
  },
  deserialize(data) {
    return data.map(p => copyProps({}, p, ['id', 'width', 'height', 'publicPath']))
  },
  copy(dest, source) {
    dest.businessPhotos = source
    return dest
  }
})
