import { isArrayLike } from './data'
import logger from '@/logger'

// TODO add dot field name support
export function mapProps(model, props) {
  const result = {}

  if (isArrayLike(props)) {
    props.forEach(name => {
      if (!(name in model)) {
        logger.error(`[mapProps] name "${name}" is not found in model`)
        return
      }
      result[name] = () => model[name]
    })
  } else {
    for (const [alias, name] of Object.entries(props)) {
      if (!(name in model)) {
        logger.error(`[mapProps] name "${name}" is not found in model`)
        continue
      }
      result[alias] = () => model[name]
    }
  }

  return result
}
