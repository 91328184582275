
// Usage: Hasher.generate()
export default class Hasher {
  generate () {
    this.increment_ = this.increment_ > Hasher.MAX_INCREMENT
      ? 0 : this.increment_ + 1

    return `${Date.now().toString(16)}-${this.increment_}`
  }

  increment_ = 0

  static instance () {
    if (!this.instance_) this.instance_ = new Hasher()
    return this.instance_
  }

  static generate () {
    return this.instance().generate()
  }

  static MAX_INCREMENT = 1000000
  static instance_ = null
}
