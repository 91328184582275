<template>
  <div class="burger">
    <div class="burger__header" @click="setCollapse">
      <div class="burger__header-value">{{ title }}</div> 
      <TriangleIcon :class="[{ 'active': isCollapsed }, 'burger__header-icon']"/>
    </div>
    <div class="burger__body" v-if="isCollapsed">
      <div class="burger__body-text">{{ text }}</div>
      <div class="burger__key-words">
        <div class="burger__key-word"  v-for="word in words" :key="word.id" :style="[{ backgroundColor: word.bgColor }, {color: word.color}]">{{ word.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import TriangleIcon from '@/icons/TriangleIcon'
export default {
  name: 'Burger',
  components: {
    TriangleIcon,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    words: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      isCollapsed: false
    }
  },
  methods: {
    setCollapse() {
      this.isCollapsed = !this.isCollapsed
    }
  }
}
</script>

<style lang="scss">
.burger {
  display: flex;
  flex-direction: column;
  position: relative;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.3px;
  color: #ABBBF2;


  &__header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    padding: 0 10px;
    background-color: var(--edit-bg-form);
    border-radius: 8px;
    z-index: 2;
    cursor: pointer;

    &-icon {
      transform-origin: 50% 50%;
      transition: .3s;
      
      &.active{
        transform: rotate(180deg);
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    padding: 60px 14px 20px;
    margin-top: -40px;
    z-index: 1;
    background-color: var(--edit-bg-color);
    border-radius: 8px;

    &-text {
      margin-bottom: 34px;
      text-align: left;
      position: relative;
      
      &:before {
        position: absolute;
        content: '';
        width: calc(100% - (2*4px));
        left: 4px;
        bottom: -20px;
        height: 1px;
        background-color: #546FCE;
      }
    }
  }

  &__key-words {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  &__key-word {
    display: flex;
    padding: 4px 10px;
    border-radius: 4px;
    font-size: 12px;
  }
}
</style>
