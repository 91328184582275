<template>
  <div :class="[{ 'large': columns.length > 4 }, 'table']">
    <div class="table__header">
      <div class="table__header-item" v-for="column in columns" :key="column">{{ column }}</div>
    </div>
    <div class="table__scroll" v-if="weeks && weeks.length > 0">
      <component
          :is="row"
          class="table__player"
          v-for="day in weeks[currentWeek.number - 1].days"
          :key="day.day"
          :day="day"
          :week="currentWeek"
          :players="tracker.day.day === day.day ? tracker.players : []"
          :columns="columns"/>
      <div class="virtual__scroll-observer" ref="observer"></div>
    </div>
    <div class="table__scroll" v-else>
      <component
          :is="row"
          class="table__player"
          v-for="(player, index) in players"
          :key="index"
          :player="player"
          :place="index + 1"
          :columns="columns"/>
      <template v-if="losers">
        <div class="table__title">Выбывшие</div>
        <component
            :is="row"
            class="table__player"
            v-for="(player, index) in losers"
            :key="player.place"
            :player="player"
            :isLoser="true"
            :place="index + 1"
            :columns="columns"/>
      </template>
      <div class="virtual__scroll-observer" ref="observer"></div>
    </div>
  </div>
</template>

<script>
import Player from '@/controls/Player'
import Tracker from '@/controls/Tracker'
import Day from '@/controls/Day'
import model from '@/model'
import { mapProps } from '@/utils/model'

export default {
  name: 'Table',
  components: {
    Player,
    Tracker,
    Day,
  },
  props: {
    columns: {
      type: Array,
      required: true,
    },
    row: {
      type: String,
      required: true,
    },
    players: {
      type: Array,
      required: false,
    },
    currentWeek: {
      type: Object,
      required: false,
    },
    losers: {
      type: Array,
      required: false,
    },
    weeks: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      point: 1,
    }
  },
  computed: {
    ...mapProps(model.game, ['tracker', 'rating']),
  },
  mounted () {
    this.addIntersectionObserver()
  },
  methods: {
    addIntersectionObserver() {
      this.observer = new IntersectionObserver(this.handleObserver);
      this.observer.observe(this.$refs.observer)
    },
    handleObserver(entries) {
      let entry = entries[0]
      if (this.rating.participants.length > 0 && !this.rating.disabledLoad) {
        if (entry && entry.isIntersecting) {
          this.point += 1
          this.$emit('scrolled', this.point)
        }
      }
    },
  }
}
</script>

<style lang="scss">

.table {
  display: flex;
  flex-direction: column;
  padding: 20px 40px 30px;
  width: 100%;
  height: calc(100vh - (40px + 195px));
  background: #21222D;
  border-radius: 24px;
  border: 1px solid rgba(255, 255, 255, .1);

  &.large {

    .table__header .table__header-item {
      font-size: 16px;

      &:nth-child(1) {
        min-width: 28%;
      }

      &:last-child {
        max-width: 8%;
      }
    }
  }

  &__header {
    display: none;
    margin-bottom: 20px;

    &-item {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      width: 100%;
      background: #171821;
      border-radius: 8px;
      font-weight: bold;
      font-size: 18px;
      color: #546FCE;
      margin-right: 10px;

      &:nth-child(1) {
        min-width: 41%;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__scroll {
    height: 100%;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 10px;
      background: #171821;
      border-radius: 8px;
      padding: 2px;

      &-thumb {
        width: 6px;
        background: #546FCE;
        border-radius: 8px;
        position: relative;
        border: 2px solid #171821;
      }
    }
  }

  &__player {
    margin-bottom: 6px;
  }

  &__title {
    font-size: 14px;
    letter-spacing: -0.3px;
    color: #546FCE;
    margin: 20px 0 10px;
    text-align: left;
  }
}

.virtual__scroll-observer {
  height: 50px;
  margin: -50px 0;
}
@media screen and (min-width: $large) {
  .table {
    &__header {
      display: flex;
    }
  }
}
@media screen and (max-width: $medium) {
  .table {
    padding: 10px;
    width: 95vw;
    margin: 0 auto;
    height: calc(100vh - 135px);
    &__scroll {
      height: 100%;
      overflow-y: auto;
    }
  }
}
</style>
