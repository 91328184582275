<template>
  <router-link
      :to="routerLink"
      :class="[{
        'player--first': place === 1,
        'player--second': place === 2,
        'player--third': place === 3,
        'eliminated': isLoser },
        'player',
        'participant']">
    <div class="player__info">
      <div class="player__place">
        <component :is="placeView" class="player__place-view"/>
        <div class="player__place-value">{{ place }}</div>
      </div>
      <Avatar class="player__avatar" :avatar="player.user.userInfo ? player.user.userInfo.avatar : ''"/>
      <div class="player__name">{{ fullname }}</div>
    </div>
    <div class="player__column">
      <div class="player__title">{{ columns[1] }}:</div>
      <div class="player__text" v-if="player.user.userInfo && player.user.userInfo.business "> {{ player.user.userInfo.business.niche || '-' }} </div>
      <div class="player__text" v-else> - </div>
    </div>
    <div class="player__column">
      <div class="player__title">{{ columns[2] }}:</div>
      <div class="player__text">{{ player.profitFact.toLocaleString('ru') || '-' }}</div>
    </div>
    <div class="player__column">
      <div class="player__title">{{ columns[3] }}:</div>
      <div class="player__text">{{ player.revenueFact.toLocaleString('ru') || '-' }}</div>
    </div>
  </router-link>
</template>

<script>
import Avatar from '@/controls/Avatar'
import StoneIcon from '@/icons/StoneIcon'
import BronzeIcon from '@/icons/BronzeIcon'
import SilverIcon from '@/icons/SilverIcon'
import GoldIcon from '@/icons/GoldIcon'
import StarIcon from '@/icons/StarIcon'
import StarActiveIcon from '@/icons/StarActiveIcon'
import model from '@/model'

export default {
  name: 'Player',
  components: {
    Avatar,
    StoneIcon,
    BronzeIcon,
    SilverIcon,
    GoldIcon,
    StarIcon,
    StarActiveIcon,
  },
  props: {
    isLoser: {
      type: Boolean,
      required: false,
    },
    player: {
      type: Object,
      required: true,
    },
    columns: {
      type: Array,
      required: true
    },
    place: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      placesList: {
        1: 'GoldIcon',
        2: 'SilverIcon',
        3: 'BronzeIcon',
      }
    }
  },
  computed: {
    fullname() {
      let name = this.player.user.userInfo !== null ? `${this.player.user.userInfo.name} ${this.player.user.userInfo.lastname}` : ''
      return name.length > 1 ? name : 'Аноним'
    },
    placeView() {
      if (this.place <= 3) return this.placesList[this.place]
      return 'StoneIcon'
    },
    routerLink() {
      if (model.game.initialId === this.player.user.id) {
        return '/profile'
      }
      return `/profile/${this.player.user.id}`
    }
  }
}
</script>

<style lang="scss">
.player {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 60px;
  padding: 10px;
  border-radius: 14px;
  font-weight: bold;
  font-size: 18px;
  color: #ABBBF2;
  border: 1px solid rgba(255, 255, 255, .1);
  background-color: #21222D;
  cursor: pointer;

  &.eliminated {
    opacity: .5;
  }
  
  &--first {
    border-color: #CFA365;
  }

  &--second {
    border-color: #C2C4CD;
  }

  &--third {
    border-color: #BD8A65;
  }

  &__place {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 40px;
    min-width: 40px;
    height: 40px;
    margin-right: 20px;

    &-view, &-value {
      position: absolute;
    }

    &-view {
      z-index: 1;
      top: 0;
    }

    &-value {
      z-index: 2;
      font-size: var(--edit-font-size);
      letter-spacing: var(--edit-letter-spacing);
      color: #FFFFFF;
    }
  }

  &__avatar {
    width: 40px;
    min-width: 40px;
    height: 40px;
    margin-right: 20px;
  }

  &__name, &__text {
    @extend %text-ellipsis;
  }

  &__name {
    width: 32%;
    min-width: 32%;
    text-align: left;
  }
  

  &__column {
    width: 100%;
    text-align: center;
  }

  &__set-favorit {
    display: none; // HIDDEN temporary
    position: absolute;
    right: 20px;
    cursor: pointer;
  }
}

@media screen and (min-width: $large) {
  .player {
    &.participant {
      .player__column {
        width: 19.3%;
      }

      .player__info {
        width: 42%;
        min-width: 42%;
      }
    }
  }
}
</style>
