import net from '@/net'
import {
  profileSchema,
  // daySchema,
  sprintSchema,
  userSchema,
  dashboardSchema,
  trackerSchema,
  ratingSchema, waitersSchema, businessPhotosSchema, GAME_ROLE_PARTICIPANT, GAME_ROLE_TRACKER,
  // DAY_STATUS_FUTURE,
} from '@/model/schema/game'
import schema from '@/model/schema'
import { copyProps } from '@/utils/data'
import * as Sentry from '@sentry/vue'
import logger from '@/logger'
import controller from '@/controller'

const ERROR_OK = null

export default class Game {
  constructor(model) {
    this.model = model
  }

  async init() {
    const { data } = await net.game.getUser()

    logger.warn('-- init getUser data:', data)

    // availableGames
    const [ game ] = data.availableGames
    net.game.id = game.id
    this.model.initialId = data.id
    this.model.user.id = data.id
    this.model.authUser.id = data.id
    this.model.user.authUserId = data.authUserId
    this.model.authUser.authId = data.authUserId
    this.model.gameRole = game.role
    this.model.authUser.gameRole = game.role

    Sentry.setUser({ id: data.id })
    Sentry.setContext('gameUser', data)

    // disable timer
    // if (net.game.id === 1) {
    //   controller.auth.model.showTimer = true
    // }
    // userSchema.copy(this.model.user, user)

    // logger.warn('-- init user:', user)

    const businessTypes = await net.game.getBusinessTypes()
    logger.warn('-- getBusinessTypes:', businessTypes.data.data.businessTypes)
    logger.log('model:', this.model)
    this.model.businessCategories = businessTypes.data.data.businessTypes.map(t => ({ id: t.id, name: t.text }))

    await this.loadProfile(this.model.authUser.id)

    logger.warn('-- model:', this.model)
    return data.id
  }

  async loadUser() {
    const response = await net.game.getUser()
    logger.warn('-- controller.game.loadUser response:', response)
    return response
  }

  clearUser() {
    this.model.authUser = {}
  }

  async loadDashboard() {
    try {
      const response = await net.game.getDashboardData()
      const dashboardData = dashboardSchema.deserialize(response.data)
      if (!dashboardData.hypothesisTop[0]) {
        dashboardData.hypothesisTop = []
      }
      dashboardSchema.copy(this.model, dashboardData)
    } catch (e) {
      controller.app.addNotification({text:e.message || e.toString()}, 5000)
      logger.error(e)
      dashboardSchema.copy(this.model, dashboardSchema.createEmpty())
    }
  }

  async loadProfileById(id) {
    try {
      profileSchema.copy(this.model, profileSchema.createDefault())
      const { data } = await net.game.getProfile(id)
      const profile = profileSchema.deserialize(data)
      profileSchema.copy(this.model, profile)

      if (this.model.user.authUserId) {
        businessPhotosSchema.copy(this.model, businessPhotosSchema.createDefault())
        const photosData = await this.getBusinessPhotos(this.model.user.authUserId)
        const photos = businessPhotosSchema.deserialize(photosData)
        businessPhotosSchema.copy(this.model, photos)
      }

      // select current week
      const current = this.model.traction.find(sprint => sprint.isCurrent)
      if (current) this.model.selectedWeekNumber = current.number

      const currentWeek = this.model.traction.find(sprint => sprint.isCurrent)
      if (currentWeek) this.model.selectedWeekNumber = currentWeek.number

      if (this.model.gameRole === GAME_ROLE_TRACKER) {
        const tracker = trackerSchema.deserialize(data)
        const currentWeek = tracker.traction.find(item => item.isCurrent)
        const day = currentWeek?.days.find(item => item.status === 'current')
        const players = await net.game.getTrackerPlayers(this.model.user.id, {dayNum: day.day, sprintNum: currentWeek.number})
        trackerSchema.copy(this.model.tracker, {
          traction: tracker.traction,
          day,
          startedWeek: currentWeek,
          week: currentWeek,
          players: players.data,
          total: data.total
        })
      }
    } catch (error) {
      this.handleError(error)
      logger.error('loadProfile:', error)
    }
  }

  async loadProfile(id) {
    try {
      profileSchema.copy(this.model, profileSchema.createDefault())
      const { data } = await net.game.getProfile(id)
      const profile = profileSchema.deserialize(data)
      profileSchema.copy(this.model, profile)

      if (this.model.authUser.gameRole === GAME_ROLE_PARTICIPANT) {
        businessPhotosSchema.copy(this.model, businessPhotosSchema.createDefault())
        const photosData = await this.getBusinessPhotos(this.model.authUser.authId)
        const photos = businessPhotosSchema.deserialize(photosData)
        businessPhotosSchema.copy(this.model, photos)
      }

      // select current week
      const current = this.model.traction.find(sprint => sprint.isCurrent)
      if (current) this.model.selectedWeekNumber = current.number

      const currentWeek = this.model.traction.find(sprint => sprint.isCurrent)
      if (currentWeek) {
        this.model.selectedWeekNumber = currentWeek.number
        this.model.currentWeek = currentWeek
      }

      if (this.model.gameRole === 'tracker') {
        const tracker = trackerSchema.deserialize(data)
        const currentWeek = tracker.traction.find(item => item.isCurrent)
        const day = currentWeek?.days.find(item => item.status === 'current')
        const players = await net.game.getTrackerPlayers(this.model.user.id, {dayNum: day.day, sprintNum: currentWeek.number})
        trackerSchema.copy(this.model.tracker, {
          traction: tracker.traction,
          day,
          startedWeek: currentWeek,
          week: currentWeek,
          players: players.data,
          total: data.total
        })
      }
    } catch (error) {
      this.handleError(error)
      logger.error('loadProfile:', error)
    }
  }

  async loadWaiters() {
    try {
      const response = await net.game.getWaiters()
      if (response.status === 200) {
        waitersSchema.copy(this.model.waiters, response.data)
      }
    } catch (e) {
      controller.app.addNotification({text:e.message || e.toString()}, 5000)
      logger.error(e)
    }
  }

  async pushWaiter() {
    try {
      const response = await net.game.postWait()
      if (response.status === 200) {
        this.model.waiters.num++
        this.model.waiters.isChecked = true
      }
    } catch (e) {
      this.handleError(e)
      logger.error(e)
    }
  }

  async loadTrackerWeek(week) {
    const newWeek = trackerSchema.deserialize({traction: this.model.tracker.traction, week: week})
    const day = this.model.tracker.traction.find(item => item.number === week.number).days.find(item => item.day === 1)
    try {
      const players = await net.game.getTrackerPlayers(this.model.user.id, {dayNum: 1, sprintNum: week.number})
      trackerSchema.copy(this.model.tracker, {
        traction: this.model.tracker.traction,
        day,
        total: this.model.tracker.total,
        startedWeek: this.model.tracker.startedWeek,
        week: newWeek.week,
        players: players.data
      })
    } catch(e) {
      controller.app.addNotification({text:e.message || e.toString()}, 5000)
      logger.error(e)
    }
  }

  async loadPlayers(week, day) {
    try {
      const players = await net.game.getTrackerPlayers(this.model.user.id, {dayNum: day.day, sprintNum: week.number})
      trackerSchema.copy(this.model.tracker, {
        traction: this.model.tracker.traction,
        day,
        total: this.model.tracker.total,
        startedWeek: this.model.tracker.startedWeek,
        week,
        players: players.data
      })
    } catch(e) {
      controller.app.addNotification({text:e.message || e.toString()}, 5000)
      logger.error(e)
    }
  }

  async loadRating(pageSize = 30) {
    try {
      const {data} = await net.game.getRating({page: this.model.rating.day, pageSize})
      ratingSchema.copyParticipants(this.model.rating, {
        day: this.model.rating.day + 1,
        participants: data
      })
    } catch (e) {
      controller.app.addNotification({text:e.message || e.toString()}, 5000)
      logger.error(e)
    }
  }

  async loadRatingTrackers(pageSize = 30) {
    try {
      const {data} = await net.game.getRatingTrackers({page: this.model.rating.day, pageSize})
      ratingSchema.copyTrackers(this.model.rating, {
        dayTrackers: this.model.rating.dayTrackers + 1,
        trackers: data
      })
    } catch (e) {
      controller.app.addNotification({text:e.message || e.toString()}, 5000)
      logger.error(e)
    }
  }

  async updateProfile(user) {
    const authUser = {
      id: user.id,
      name: user.userInfo.name,
      lastname: user.userInfo.lastname,
      email: user.userInfo.email,
      city: user.userInfo.city,
      businessCategoryId: user.userInfo.business.businessCategory.id,
      company: user.userInfo.business.name,
      niche: user.userInfo.business.niche
    }
    await net.game.updateProfile(authUser)
    userSchema.copy(this.model.user, user)
    controller.app.addNotification({ text: 'Профиль успешно обновлен' }, 5000)
  }

  async updateSimpleProfile(user) {
    const authUser = {
      id: user.id,
      name: user.userInfo.name,
      lastname: user.userInfo.lastname,
      email: user.userInfo.email,
      city: user.userInfo.city,
      avatar: user.userInfo.avatar,
      businessCategoryId: user.userInfo.business.businessCategory.id,
      company: user.userInfo.business.name,
      niche: user.userInfo.business.niche
    }
    try {
      await net.game.updateProfile(authUser)
      userSchema.copy(this.model.user, user)
      controller.app.addNotification({ text: 'Профиль успешно обновлен' }, 5000)
    } catch (e) {
      this.handleError(e)
      logger.error(e)
    }
  }

  async createAPoint(aPoint) {
    const response = await net.game.createAPoint(aPoint)
    userSchema.copyPointA(this.model.user, { ...aPoint, id: response.data.id })
  }

  async updateAPoint(aPoint, id) {
    await net.game.updateAPoint(aPoint, id)
    userSchema.copyPointA(this.model.user,{ ...aPoint, id })
  }

  async updatePassword(passwords) {
    await net.game.updatePassword(passwords)
    controller.app.addNotification({ text: 'Пароль успешно обновлен' }, 5000)
  }

  async uploadAvatar(file) {
    const formData = new FormData()
    formData.append('avatar', file)

    try {
      return await net.game.uploadAvatar(formData)
    } catch (e) {
      this.handleError(e)
      return e.response
    }
  }

  async getBusinessPhotos(id) {
    try {
      const response = await net.game.getBusinessPhotos(id)
      if (response.data.length <= 3) {
        response.data.push(...businessPhotosSchema.createDefault())
      }
      return response.data
    } catch (e) {
      controller.app.addNotification({text:e.message || e.toString()}, 5000)
      logger.error(e)
      return businessPhotosSchema.createDefault()
    }
  }

  async uploadBusinessPhoto(file) {
    try {
      const response = await net.game.uploadBusinessPhoto(file)
      return response.data
    } catch (e) {
      this.handleError(e)
      logger.error(e)
    }
  }

  async deleteBusinessPhoto(id) {
    try {
      return await net.game.deleteBusinessPhoto(id)
    } catch (e) {
      this.handleError(e)
      logger.error(e)
    }
  }

  async createSprint(sprint, changes) {
    const fields = ['userRevenueTarget', 'userProfitTarget', 'valueOfWord', 'reward', 'number']
    copyProps(sprint, changes, fields)
    const payload = copyProps({}, changes, fields)
    const { data } = await net.game.createSprint(payload)
    sprint.id = data.id
    return data
    // TODO create day reports?
    // sprint.dayReports = (new Array(7)).fill(null).map((item, i) => {
    //   const day = daySchema.createDefault()
    //   day.day = i + 1
    //   day.availableFrom = new Date('2021-10-19T00:00:00+00:00')
    //   day.availableTo = new Date('2021-10-19T23:59:59+00:00')
    //   day.status = DAY_STATUS_FUTURE
    //   return day
    // })
  }

  async selectSprint(sprint) {
    if (sprint.id) await this.loadSprint(sprint)
    this.model.selectedWeekNumber = sprint.number
  }

  async loadSprint(sprint) {
    try {
      const { data } = await net.game.getSprint(sprint.id)
      const loaded = sprintSchema.deserialize({ ...data, number: sprint.number })
      sprintSchema.copy(sprint, loaded)

      logger.warn('loadSprint model:', this.model)
    } catch (error) {
      controller.app.addNotification({text:error.message || error.toString()}, 5000)
      logger.error('loadSprint:', error)
    }
  }


  async updateSprint(sprint, changes) {
    const fields = ['userRevenueTarget', 'userProfitTarget', 'valueOfWord', 'reward']
    copyProps(sprint, changes, fields)
    const payload = copyProps({}, changes, fields)
    const { data } = await net.game.updateSprint(sprint, payload)
    return data
  }

  async createDayReport(sprint, report, data) {
    let res
    const fields = ['day']
    if (data.revenueTarget !== null) {
      fields.push('revenueTarget')
    } else {
      controller.app.addNotification({ text: 'Заполни план выручки чтобы данные за день обновились' }, 5000)
      return
    }
    if (data.profitTarget !== null) {
      fields.push('profitTarget')
    } else {
      controller.app.addNotification({ text: 'Заполни план прибыли чтобы данные за день обновились' }, 5000)
      return
    }

    copyProps(report, data, fields)
    const payload = copyProps({ sprint: sprint.id, day: report.day }, data, fields)
    try {
      res = await net.game.createDayReport(payload)
      report.id = res.id
      controller.app.addNotification({ text: 'Данные обновлены успешно' }, 2000)
    } catch (error) {
      this.handleError(error)
      logger.error('createDayReport:', error)
    }
    return res
  }

  async loadDayReport(dayReportId) {
    logger.warn('-- TODO controller.Game.loadDayReport dayReportId:', dayReportId)
    // const response = await net.game.getDayReport(dayReportId)
    // logger.log('loadDayReport response:', response)

    // const sprint = this.model.traction.find(t => t.number === this.model.selectedWeekNumber)
    // const existed = sprint.dayReports.find(d => d.id === dayReportId)
    // daySchema.copy(existed, response.data)
  }

  async updateDayReport(report, data) {
    let res
    const fields = []
    if (data.revenueTarget !== null) fields.push('revenueTarget')
    if (data.profitTarget !== null) fields.push('profitTarget')
    if (data.revenueFact !== null) fields.push('revenueFact')
    if (data.profitFact !== null) fields.push('profitFact')

    copyProps(report, data, fields)
    const payload = copyProps({}, data, fields)
    try {
      res = await net.game.updateDayReport(report, payload)
      controller.app.addNotification({ text: 'Данные обновлены успешно' }, 2000)
    } catch (error) {
      this.handleError(error)
      logger.error('updateDayReport:', error)
    }
    return res
  }

  async createHypothesis(hypothesis, day) {
    logger.warn('-- createHypothes hypothesis:', hypothesis)
    logger.log('day:', day)

    hypothesis.status = schema.hypothesis.STATUS_NEW

    const data = schema.hypothesis.serialize(hypothesis)
    data.actions.forEach(action => {
      delete action.id
    })

    const payload = copyProps({
      dayReport: day.id,
      isPublic: 1,
      hypothesisActions: data.actions.map(a => ({ ...a }))
    }, data, ['text', 'status', 'conclusions', 'category', 'ddl', 'expenses', 'revenue', 'isSuccessful'])

    try {
      const response = await net.game.createHypothesis(payload)

      hypothesis.id = response.data.id
      hypothesis.actions.forEach((action, a) => {
          action.id = response.data.hypothesisActions[a]?.id
      })
      day.hypotheses.push(hypothesis)
    } catch (error) {
      hypothesis.status = schema.hypothesis.STATUS_NONE

      this.handleError(error)
      logger.error('createHypothesis:', error)
      return error
    }

    return ERROR_OK
  }

  async loadHypothesis(hypothesisId) {
    try {
      const response = await net.game.getHypothesis(hypothesisId)
      logger.warn('-- TODO controller.Game.loadHypothesis response:', response)
      return response
    } catch (error) {
      controller.app.addNotification({text:error.message || error.toString()}, 5000)
      logger.error(error)
    }
  }

  async updateHypothesis(hypothesis, changes, day) {
    // set status depending on actions completed
    let actionsCompleted = !!changes.actions.length
    for (const action in changes.actions) {
      if (!action.isComplete) {
        actionsCompleted = false
        break
      }
    }

    if (actionsCompleted && changes.status === schema.hypothesis.STATUS_NEW
      && changes.conclusions && changes.expenses !== null && changes.revenue !== null) {
      changes.status = schema.hypothesis.STATUS_TESTED
    }

    logger.warn('-- updateHypothesis hypothesis:', hypothesis)
    logger.log('changes:', changes)
    logger.log('changes.status:', changes.status)

    const data = schema.hypothesis.serialize(changes)

    const payload = copyProps({
      dayReport: day.day,
      // hypothesisActions: data.actions
    }, data, ['text', 'conclusions', 'category', 'status', 'ddl',
      'expenses', 'revenue', 'isSuccessful', 'isPublic'])
    try {
      await net.game.updateHypothesis(hypothesis, payload)
      await this.syncHypothesisActions(hypothesis, changes)
      schema.hypothesis.copy(hypothesis, changes)
    } catch (error) {
      this.handleError(error)
      logger.error(error)
      return error
    }

    return ERROR_OK
  }

  async syncHypothesisActions(hypothesis, changes) {
    const created = changes.actions.filter(a => !a.id)
    const updated = []
    const deleted = []

    hypothesis.actions.forEach(a => {
      const b = changes.actions.find(ca => ca.id === a.id)
      if (!b) deleted.push(a)
      else if (a.isComplete !== b.isComplete || a.action !== b.action) updated.push({action: a, changed: b})
    })

    logger.warn('-- syncHypothesisActions hypothesis:', hypothesis)
    logger.log('changes:', changes)
    logger.log('created:', created)
    logger.log('updated:', updated)
    logger.log('deleted:', deleted)

    try {
      await Promise.all(created.map(async action => this.createHypothesisAction(hypothesis, action)))
      await Promise.all(updated.map(async pair => this.updateHypothesisAction(pair.action, pair.changed)))
      await Promise.all(deleted.map(async action => this.deleteHypothesisAction(action.id)))
    } catch (error) {
      this.handleError(error)
      logger.error(error)
      return error
    }
  }

  async likePost(hypothesisId) {
    try {
      const response = await net.game.likePost(hypothesisId)
      logger.warn('-- TODO controller.Game.likePost response:', response)
    } catch (error) {
      this.handleError(error)
      logger.error(error)
    }
  }

  async deleteHypothesis(hypothesisId) {
    try {
      const response = await net.game.deleteHypothesis(hypothesisId)
      logger.warn('-- TODO controller.Game.deleteHypothesis response:', response)
    } catch (error) {
      this.handleError(error)
      logger.error(error)
      return error
    }

    return ERROR_OK
  }

  async createHypothesisAction(hypothesis, action) {
    const payload = {
      action: action.action,
      isComplete: action.isComplete,
      hypothesis: hypothesis.id
    }

    try {
      const response = await net.game.createHypothesisAction(payload)
      logger.warn('-- TODO controller.Game.createHypothesisAction response:', response)
      action.id = response.data.id
    } catch (error) {
      this.handleError(error)
      logger.error(error)
    }
  }

  async updateHypothesisAction(action, changes) {
    copyProps(action, changes, ['action', 'isComplete'])
    const payload = {
      action: changes.action,
      isComplete: +changes.isComplete
    }

    try {
      const response = await net.game.updateHypothesisAction(action.id, payload)
      logger.warn('-- TODO controller.Game.updateHypothesisAction response:', response)
    } catch (error) {
      controller.app.addNotification({text:error.message || error.toString()}, 5000)
      logger.error(error)
    }
  }

  async deleteHypothesisAction(actionId) {
    try {
      const response = await net.game.deleteHypothesisAction(actionId)
      logger.warn('-- TODO controller.Game.deleteHypothesisAction response:', response)
    } catch (error) {
      controller.app.addNotification({text:error.message || error.toString()}, 5000)
      logger.error(error)
    }
  }

  async getHypothesisComments(id) {
    try {
      return await net.game.getHypothesisComments(id)
    } catch (e) {
      this.handleError(e)
    }
  }

  async createHypothesisComment(comment) {
    try {
      return await net.game.createHypothesisComment(comment)
    } catch (e) {
      this.handleError(e)
    }
  }

  async updateHypothesisComment(id, comment) {
    try {
      return await net.game.updateHypothesisComment(id, comment)
    } catch (e) {
      this.handleError(e)
    }
  }

  async deleteHypothesisComment(id) {
    try {
      return await net.game.deleteHypothesisComment(id)
    } catch (e) {
      this.handleError(e)
    }
  }

  async likeHypothesisComment(id) {
    try {
      return await net.game.likeHypothesisComment(id)
    } catch (e) {
      this.handleError(e)
    }
  }

  async dislikeComment(id) {
    try {
      return await net.game.dislikeComment(id)
    } catch (e) {
      this.handleError(e)
    }
  }


  handleError(error) {
    logger.error(error)
  }
}
