import logger from '@/logger'

export function isArrayLike(obj) {
  return obj && ('length' in obj)
}

export function copyProps(dest, source, props) {
  if (isArrayLike(props)) {
    props.forEach(name => {
      if (!(name in source)) {
        logger.error(`[copyProps] property "${name}" is not found in source`)
        return
      }
      dest[name] = source[name]
    })
  } else { // mapping { alias: name }
    for (const [alias, name] of Object.entries(props)) {
      if (!(name in source)) {
        logger.error(`[copyProps] name "${name}" is not found in source`)
        continue
      }
      dest[alias] = source[name]
    }
  }

  return dest
}
