<template>
  <div class="rating">
    <div class="rating__nav">
      <!-- <Button text="Участники" :class="[{ active: tab === 'participants' }, 'rating__nav-item']"
              @click="selectTab('participants')" />
      <Button text="Трекеры" :class="[{ active: tab === 'trackers' }, 'rating__nav-item']"
              @click="selectTab('trackers')" /> -->
    </div>
    <Table v-if="tab === 'participants'" row="Player" :columns="participiant.columns" :players="rating.participants" @scrolled="incrementPage"/>
    <Table v-if="tab === 'trackers'" row="Tracker" :columns="trackers.columns" :players="rating.trackers" @scrolled="incrementPage"/>
  </div>
</template>

<script>
import Table from "@/controls/Table";
// import Button from "@/controls/Button";
import model from '@/model'
import { mapProps } from '@/utils/model'
import controller from '@/controller'

export default {
  name: "Rating",
  components: {
    // Button,
    Table,
  },
  data() {
    return {
      tab: "participants",
      participiant: {
        columns: [
          "Участник",
          "Ниша",
          "Факт по прибыли, ₽",
          "Факт по выручке, ₽",
        ],
      },
      trackers: {
        columns: [
          "Трекер",
          "Прибыль команды, ₽",
          "Выручка команды, ₽",
        ],
      },
    };
  },
  created() {
    controller.game.loadRating()
    controller.game.loadRatingTrackers()
  },
  computed: {
    ...mapProps(model.game, ['rating']),
  },
  methods: {
    selectTab(key) {
      this.tab = key;
    },
    incrementPage() {
      if (this.tab === 'participants') controller.game.loadRating()
      if (this.tab === 'trackers') controller.game.loadRatingTrackers()
    }
  },
};
</script>

<style lang="scss">
.rating {
  display: flex;
  flex-direction: column;

  &__nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 17px;

    &-item {
      margin-right: 20px;
    }

    .button {
      width: 100%;
      margin: 0 0 $margin 0;
    }
  }
}

@media screen and (min-width: $medium) and (max-width: $large - 2) {
  .rating {
    &__nav {
      flex-direction: row;
    }

    .button {
      width: 190px;
      margin-right: 20px;
    }
  }
}

@media screen and (min-width: $large) {
  .rating {
    &__nav {
      flex-direction: row;
    }

    .button {
      width: 190px;
      margin-right: 20px;
    }
  }
}
@media screen and (max-width: $medium){
  .rating {
    padding: 0 20px;
  }
}
</style>
