<template>
  <div class="main-layout">
    <div class="nav">
      <input id="nav__checkbox" class="nav__checkbox" type="checkbox" :checked="open" @click="toggleOpen('toggle')"/>
      <label v-if="!showTimer" class="nav__toggle" for="nav__checkbox">
        <MenuIcon class="nav__toggle-icon"/>
      </label>
      <div class="nav__wrapper">
        <a v-if="!showTimer" :class="[{'active': $route.path ==='/profile'}, 'nav__item']"
           @click="toggleOpen('profile')">
          <ProfileIcon class="nav__item-icon"/>
          <span>Профиль</span>
        </a>
        <a v-if="!showTimer" :class="[{'active': $route.path ==='/'}, 'nav__item']" @click="toggleOpen('dashboard')">
          <DashboardIcon class="nav__item-icon"/>
          <span>Главная</span>
        </a>
        <a v-if="!showTimer" :class="[{'active': $route.path ==='/rating'}, 'nav__item']"
           @click="toggleOpen('rating')">
          <RatingIcon class="nav__item-icon"/>
          <span>Рейтинг</span>
        </a>
        <div class="nav__logout" @click="logout">
          <LogoutIcon class="nav__item-icon"/>
          <span>Выйти</span>
        </div>
      </div>
    </div>
    <slot/>
    <div class="nav__overlay" v-if="!open" @click="toggle"></div>
  </div>
</template>

<script>
import MenuIcon from '@/icons/MenuIcon'
import ProfileIcon from '@/icons/ProfileIcon'
import DashboardIcon from '@/icons/DashboardIcon'
import RatingIcon from '@/icons/RatingIcon'
import LogoutIcon from '@/icons/LogoutIcon'
import controller from "@/controller";

export default {
  name: 'MainLayout',
  components: {
    MenuIcon,
    ProfileIcon,
    DashboardIcon,
    RatingIcon,
    LogoutIcon
  },
  computed: {
    showTimer: () => controller.auth.model.showTimer,
  },
  data() {
    return {
      open: true,
    };
  },
  methods: {
    logout() {
      this.toggle()
      this.$router.push({name: "logout"}).catch(() => {})
    },
    toggleOpen(name) {
      switch (name) {
        case "profile":
          this.toggle()
          this.$router.push({name: "profile"}).catch(() => {})
          break;
        case "dashboard":
          this.toggle()
          this.$router.push({name: "dashboard"}).catch(() => {})
          break
        case "rating":
          this.toggle()
          this.$router.push({name: "rating"}).catch(() => {})
          break
        case "toggle":
          this.toggle()
          break;
      }
    },
    toggle() {
      this.open = !this.open;
    },
  }
}
</script>
