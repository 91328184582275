<template>
  <div class="register">
    <img :src="loginBg" alt="bg" class="register__bg" />
    <div class="register__card-border">
      <div class="register__card card">
        <l-c-icon class="card__logo" />
        <h1 class="card__title">Зарегистрируйся, чтобы получить доступ к игре</h1>
        <form class="card__form">
          <div class="card__field">
            <label class="card__label">Email</label>
            <edit v-model.trim="email" placeholder="Введи email" />
          </div>
          <div class="card__field">
            <label class="card__label">Пароль</label>
            <pass-edit v-model.trim="password" placeholder="Введи пароль" />
          </div>
          <div class="card__field">
            <label class="card__label">Повтори пароль</label>
            <pass-edit v-model.trim="passwordRepeat" placeholder="Введи пароль" />
          </div>
          <Button class="card__submit" :disabled="!passwordsEqual" :active="passwordsEqual" text="Зарегистрироваться" @click="onRegister" />
        </form>
        <div class="card__register">
          Есть аккаунт?
          <router-link to="login" >Войти</router-link>
        </div>
        <div class="card__side-auth">
          <a class="card__side-auth-icon" :href="sideAuthLink('google')" target="_self">
            <google-icon/>
          </a>
          <a class="card__side-auth-icon" :href="sideAuthLink('facebook')" target="_self">
            <facebook-icon/>
          </a>
          <a class="card__side-auth-icon" :href="sideAuthLink('vkontakte')" target="_self">
            <vkontakte-icon/>
          </a>
          <a class="card__side-auth-icon" :href="sideAuthLink('yandex')" target="_self">
            <yandex-icon/>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import controller from '@/controller'

import LCIcon from '@/icons/LCIcon'
import GoogleIcon from '@/icons/GoogleIcon'
import FacebookIcon from '@/icons/FacebookIcon'
import VkontakteIcon from '@/icons/VkIcon'
import YandexIcon from '@/icons/YandexIcon'
import Button from '@/controls/Button'
import Edit from '@/controls/Edit'
import PassEdit from '@/controls/PassEdit'

import loginBg from '@/assets/login_bg.png'


export default {
  name: 'Register',
  components: { LCIcon, GoogleIcon, VkontakteIcon, FacebookIcon, YandexIcon, Button, Edit, PassEdit },
  data: () => ({
    email: '',
    password: '',
    passwordRepeat: '',
    loginBg: loginBg
  }),
  computed: {
    passwordsEqual() {
      return !!this.password && this.password === this.passwordRepeat
    }
  },
  methods: {
    async onRegister() {
      this.requestStatus = []
      await controller.auth.register({
        email: this.email,
        plainPassword: this.password,
        repeatPassword: this.passwordRepeat
      })
    },
    sideAuthLink(service) {
      return `https://auth.toolbox.bz/connect/${service}?platform=game`
    }
  }
}
</script>

<style scoped lang="scss">
.register {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  &__bg {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    width: 100vw;
    height: 100vh;
    min-height: 100%;
    object-fit: cover;
    z-index: -1;
  }

  &__card-border {
    width: 600px;
    height: fit-content;
    border-radius: 24px;
    border: 2px solid #7C8CBA;
    box-shadow: 20px 37px 67px rgba(23, 18, 43, 0.45);

    @media (max-width: 630px) {
      width: 100%;
      margin: -20px 15px 0;
    }
  }

  &__card {
    width: 100%;
    border-radius: 22px;
    background: linear-gradient(
      247.99deg, 
      rgba(36, 39, 50, 0.6) 0%, 
      rgba(36, 39, 50, 0.7) 48.89%, 
      rgba(36, 39, 50, 0.7) 98.46%);
    backdrop-filter: blur(20px);
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-direction: column;
    padding: 40px 85px;
    @media (max-width: 630px) {
      padding: 20px 20px;
    }
  }
}

.card {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;

  &__logo {
    align-self: center;
    fill: #ABBBF2;
    margin-bottom: 24px;
  }

  &__title {
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: #ABBBF2;
    margin-top: 0;
    margin-bottom: 20px;
    
    @media (max-width: 630px) {
      font-size: 20px;
    }
  }

  &__field {
    display: flex;
    flex-direction: column;
    margin-bottom: 14px;
    font-size: 12px;
    color: var(--edit-color-normal);
  }

  &__label {
    align-self: flex-start;
    margin-bottom: 5px;
  }

  &__forgot {
    margin-top: 6px;
    align-self: flex-end;
    font-size: 14px;
    letter-spacing: -0.3px;
    text-decoration: none;
    color: var(--edit-color-normal);
  }

  &__submit {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 40px;
  }

  &__register {
    font-size: 14px;
    letter-spacing: -0.3px;
    color: #546FCE;
    margin-bottom: 40px;

    a {
      color: #ABBBF2;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__side-auth {
    display: flex;
    justify-content: center;
  }

  &__side-auth-icon {
    background: #181922;
    height: 40px;
    width: 40px;
    padding: 10px;
    border-radius: 6px;
    cursor: pointer;

    &:hover {
      background: rgba(191, 184, 228, 0.25);
    }

    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}
</style>
