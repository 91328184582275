<template>
  <div :class="[{ 'avatar--empty': !avatar }, 'avatar']">
    <img v-if="avatar" class="avatar__img" :src="avatar"/>
    <div v-else class="avatar__icon">
      <UserIcon />
    </div>
    <div v-if="showWinner && !avatar" class="avatar__icon">
      <WinnerIcon />
    </div>
  </div>
</template>

<script>
import UserIcon from '@/icons/UserIcon'
import WinnerIcon from '@/icons/WinnerIcon'
export default {
  name: 'Avatar',
  components: {
    WinnerIcon,
    UserIcon
  },
  props: {
    avatar: {
      type: String,
      required: false,
    },
    showWinner: {
      type: Boolean,
      default: false
    }
  },
}
</script>

<style lang="scss">
.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  box-sizing: border-box;
  border: 1px solid var(--edit-checkbox-color);

  &--empty {
    border: none;
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__icon {
    width: 100%;
    height: 100%;
  }
}
</style>
