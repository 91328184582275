<template>
  <div class="toolbar-menu">
    <div class="toolbar-menu__scroll">
      <div class="toolbar-menu__title">Гипотеза
        <div class="toolbar-menu__prompt">
          <div class="toolbar-menu__prompt-block">{{ day.day }} день <span></span>{{ week.number }} неделя</div>
          <div class="toolbar-menu__prompt-block">{{ dayMonth }}</div>
        </div>
      </div>
      <div class="toolbar-menu__section">
        <div class="toolbar-menu__block">
          <Combobox :value="category" @setValue="setCategory" :list="categories" placeholder="Выбери категорию"/>
        </div>
        <div class="toolbar-menu__block toolbar-menu__row">
          <div class="toolbar-menu__label">Гипотеза</div>
          <Textarea :max="255" :readonly="true" :value="hypothesis.text" @input="setText" placeholder="Напиши свою гипотезу"/>
        </div>
        <div class="toolbar-menu__block toolbar-menu__row">
          <Burger
            title="Показать пример гипотезы"
            text="Если я выложу продающий пост о том, что в стоимость съемки будет ключена стоимость изветсного визажиста, у меня увеличится число продаж"
            :words="words"
          />
        </div>
      </div>
      <div class="toolbar-menu__title toolbar-menu__btn">Шаги тестирования
        <PlusIcon/>
      </div>
      <TestStep v-for="(action, index) in hypothesis.actions" :key="index" :isDone="action.isComplete" :text="action.action"
        @setText="(text) => setActionValue(action, index, text)" @setChecked="(value) => setActionComplete(action, value)" />
    </div>
    <div class="toolbar-menu__control">
      <Button class="toolbar-menu__save" text="Сохранить гипотезу" :disabled="!isValid" @click="save"/>
    </div>
  </div>
</template>

<script>
import Combobox from '@/controls/Combobox'
import Textarea from '@/controls/Textarea'
import Burger from '@/controls/Burger'
import Button from '@/controls/Button'
import TestStep from '@/controls/TestStep'
import PlusIcon from '@/icons/PlusIcon'
import model from '@/model'
import controller from '@/controller'
import { mapProps } from '@/utils/model'
/* import { copyProps } from '@/utils/data' */
import { hypothesisSchema } from '@/model/schema/game'
import logger from '@/logger'

export default {
  name: 'CreateHypotheseForm',
  components: {
    Combobox,
    Textarea,
    Burger,
    Button,
    TestStep,
    PlusIcon,
  },
  props: {
    day: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      hypothesis: hypothesisSchema.createDefault(),
      category: -1,
      words: [
        {
          id: 'test',
          name: 'Фастфуд и общепит',
          color: '#FFFFFF',
          bgColor: '#1CAA82', 
        },
        {
          id: 'test1',
          name: 'Доставка и логистика',
          color: '#BB6BD9',
          bgColor: 'rgba(187, 107, 217, 0.15)', 
        },
      ]
    }
  },
  computed: {
    ...mapProps(model.game, [ 'hypothesisCategories', 'selectedWeekNumber', 'traction' ]),
    week() {
      return this.traction.find(t => t.number === this.selectedWeekNumber)
    },
    dayMonth() {
      return this.day.availableFrom?.toLocaleString('ru', { month: 'long', day: 'numeric' }) || ''
    },
    categories() {
      return model.game.hypothesisCategories.map((c, i) => ({ id: i, name: c }))
    },
    isValid() {
      return this.hypothesis.text && this.hypothesis.category // && this.hypothesis.actions.length
    }
  },
  created() {
    if (!this.hypothesis.businessCategory) this.hypothesis.businessCategory = model.game.user.userInfo.business.businessCategory.name
    if (!this.hypothesis.ddl) this.hypothesis.ddl = this.day.availableTo
    if (!this.hypothesis.actions.length) this.hypothesis.actions.push({
      id: 1,
      action: '',
      isComplete: false
    })
  },
  methods: {
    setSphere(sphere) {
      this.sphere = sphere
    },
    setCategory(category) {
      this.category = category
      this.hypothesis.category = this.categories.find(c => c.id === category)?.name || ''
    },
    setText(text) {
      this.hypothesis.text = text
    },
    setActionValue(action, index, text) {
      action.action = text

      // add new action if this action is filled and last one
      const actions = this.hypothesis.actions
      const lastIndex = actions.length - 1
      if (text && (index === lastIndex)) {
        const last = actions[lastIndex]
        actions.push({
          id: last.id + 1,
          action: '',
          isComplete: false
        })
      }
    },
    setActionComplete(action, value) {
      action.isComplete = value
    },
    save() {
      logger.warn('-- TODO save hypothesis:', this.hypothesis)

      // remove last buffer action
      const actions = this.hypothesis.actions
      if (actions.length) {
        const last = actions[actions.length - 1]
        if (!last.action) actions.splice(actions.length - 1)
      }

      controller.game.createHypothesis(this.day, this.hypothesis)

      this.$emit('closeSidebar')
    }
  }
}
</script>

<style lang="scss">
.test-steps {
  display: flex;
  flex-direction: column;
}
</style>
