<template>
  <div class="app">
    <Notifications/>
    <div class="page">
      <component :is="$route.meta.layout || 'div'">
        <router-view class="content"/>
      </component>
    </div>
  </div>
</template>

<script>
import Notifications from "@/controls/Notifications";

export default {
  name: "App",
  components: {
    Notifications
  }
};
</script>

<style lang="scss">
@import "@/style/_reset.scss";
@import "@/style/_vars.scss";

.app {
  display: flex;
  position: relative;
  justify-content: center;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  background-color: var(--bg-color);

  &:before,
  &:after {
    position: absolute;
    content: "";
    background: #546FCE;
    border-radius: 50%;
    opacity: 0.4;
    border: 30px solid rgba(102, 68, 175, 0.5);
    box-sizing: border-box;
    filter: blur(50px);
    z-index: 0;
    pointer-events: none;
  }

  &:before {
    width: 160px;
    height: 160px;
    top: 36%;
    left: 20px;
  }

  &:after {
    width: 290px;
    height: 290px;
    top: 45%;
    right: 38px;
  }
}

.page {
  font-family: Helvetica, Avenir, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* width: var(--page-width); */
  width: 1240px;
  z-index: 1;
  height: max-content;
  position: relative;
}

%nav-item {
  display: inline-flex;
  align-items: center;
  order: 2;
  flex: 0 0 auto;
  gap: var(--nav-item-gap);
  height: var(--nav-item-height);
  padding: var(--nav-item-padding);
  border-radius: var(--nav-item-border-radius);
  color: var(--nav-item-color);
  text-decoration: none;
  cursor: pointer;
}

.nav {
  display: block;
  position: relative;
  z-index: $popupZindex;
  justify-content: center;
  flex: 0 1 auto;
  gap: var(--nav-gap);
  height: var(--nav-height);
  margin-top: var(--nav-margin-top);
  padding: var(--nav-padding);
  background-color: var(--nav-bg-color);
  border-radius: var(--nav-border-radius);
  z-index: $popupZindex;

  @media (max-width: $medium) {
    margin-left: 20px;
    margin-right: 20px;
  }

  &__overlay {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: calc(#{$popupZindex} - 1);
  }

  &__wrapper {
    display: flex;
    z-index: 2;
    flex-direction: column;
    gap: $margin;
    align-items: center;
    border-radius: inherit;
    background-color: inherit;
  }

  &__checkbox {
    display: none;
  }

  &__toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: $popupZindex + 1;
    right: 0;
    height: $navItemHeight;
    width: $navItemHeight;
    border-radius: $navItemBorderRadius;
    cursor: pointer;
  }

  &__toggle-icon {
    display: inline-flex;
  }

  &__item {
    border: 1px solid transparent;
  }

  &__item,
  &__logout {
    @extend %nav-item;
    transition: 0.3s;

    &.active {
      cursor: default;
      order: 1;
      box-shadow: 0px 0px 4px rgba(93, 152, 240, 0.3);
      border-color: rgba(166, 188, 245, 0.7);
    }

    &.active,
    &:hover {
      background-color: var(--nav-item-active-bg-color);
      color: var(--nav-item-active-color);

      .nav__item-icon {
        svg {
          path,
          rect {
            fill: var(--nav-item-active-color);
          }
        }
      }
    }
  }

  &__item-icon {
    path,
    rect {
      fill: var(--nav-item-color);
      transition: 0.3s;
    }
  }

  &__logout {
    /* position: absolute; */
    /* top: var(--nav-padding); */
    /* right: var(--nav-padding); */
  }
}

.nav__checkbox:checked ~ .nav__wrapper {
  height: $navItemHeight;
  overflow: hidden;
}

.content {
  margin-top: 20px;
  padding-bottom: 20px;
  @media (max-width: 630px) {
    margin-top: 30px;
  }
}

@media screen and (min-width: $medium) and (max-width: $large - 2) {
  .page {
    width: $medium;
  }
}

/* @media screen and (min-width: $large) { */
/*   .nav__checkbox, .nav__toggle { */
/*     display: none; */
/*   } */
/*   .nav-wrapper { */
/*     display: contents; */
/*   } */
/* } */

@media screen and (min-width: $large) {
  .nav {
    display: flex;
    opacity: var(--nav-opacity);

    &__wrapper {
      display: contents;
    }

    &__toggle, &__overlay {
      display: none;
    }

    &__item,
    &__logout {
      order: 0;
      background-color: var(--nav-item-bg-color);

      &.active {
        order: 0;
      }
    }

    &__logout {
      position: absolute;
      top: var(--nav-padding);
      right: var(--nav-padding);
    }
  }
}
</style>
