// import Vue from 'vue'

function log (...params) {
  if (process.env.NODE_ENV === 'development') {
    console.log(...params)
  }
}

function warn (...params) {
  if (process.env.NODE_ENV === 'development') {
    console.warn(...params)
  }

  // Vue.nextTick(() => {
  //   model.app.incWarnCounter()
  // })
}

function error (...params) {
  if (process.env.NODE_ENV === 'development') {
    console.error(...params)
  }

  // Vue.nextTick(() => {
  //   model.app.incErrorCounter()
  // })
}

export default {
  log,
  warn,
  error
}
