<template>
  <div class="profile-rating">
    <div class="profile-rating__info">
      <div class="profile-rating__desc">Рейтинг:</div>
      <div class="profile-rating__value">{{ value }} / {{ maxValue }}</div>
    </div>
    <UnionIcon class="profile-rating__icon"/>
  </div>
</template>

<script>
import UnionIcon from '@/icons/UnionIcon'
export default {
  name: 'ProfileRating',
  components: {
    UnionIcon,
  },
  props: {
    value: {
      type: Number,
      required: true,
    },
    maxValue: {
      type: Number,
      required: true,
    }
  }
}
</script>

<style lang="scss">
.profile-rating {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 0 16px 14px;
  font-size: 14px;
  letter-spacing: var(--edit-letter-spacing);
  @extend %card-gradient;

  &__info {
    display: flex;
  }

  &__desc, &__value {
    z-index: 1;
  }

  &__desc {
    color: #546FCE;
    margin-right: 14px;
  }

  &__value {
    color: rgba(255, 255, 255, .8);
  }

  &__icon {
    position: relative;
    padding-top: 5px;
  }
}
</style>
