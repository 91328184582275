<template>
  <component :is="profileComponent" />
</template>

<script>
import NobodyProfile from '@/pages/NobodyProfile'
import Profile from '@/pages/Profile'
import TrackerProfile from '@/pages/TrackerProfile'
import WatcherProfile from '@/pages/WatcherProfile'
import { mapProps } from '@/utils/model'
import model from '@/model'
import schema from '@/model/schema'
import controller from '@/controller'

const ROLE_TO_PROFILE = {
  [schema.profile.ROLE_NOBODY]: 'NobodyProfile',
  [schema.profile.ROLE_PARTICIPANT]: 'Profile',
  [schema.profile.ROLE_TRACKER]: 'TrackerProfile',
  [schema.profile.ROLE_WATCHER]: 'WatcherProfile'
}

export default {
  name: 'ProfileWrap',
  components: {
    NobodyProfile,
    Profile,
    TrackerProfile,
    WatcherProfile
  },
  created() {
    if (model.game.initialId !== model.game.user.id) {
      controller.game.loadProfile(model.game.initialId)
    }
  },
  computed: {
    ...mapProps(model.game, ['gameRole']),
    profileComponent() {
      return ROLE_TO_PROFILE[this.gameRole] || ''
    },
  },
}
</script>
