<template>
  <div class="hypothesis-preview">
    <div class="hypothesis-preview__user">
      <div class="hypothesis-preview__header">
        <div class="hypothesis-preview__avatar"><img :src="user.userInfo.avatar"></div>
        <div class="hypothesis-preview__fullname">{{ user.userInfo.name }} {{ user.userInfo.lastname }}</div>
        <div class="hypothesis-preview__deadline">{{ hypothesis.ddl.toLocaleString('ru', { month: 'numeric', day: 'numeric', year: 'numeric' }) }}</div>
      </div>
      <div class="hypothesis-preview__text">{{ hypothesis.text }}</div>
      <div class="hypothesis-preview__tags">
        <div class="hypothesis-preview__business-category">{{ user.userInfo.business.businessCategory.name }}</div>
        <div class="hypothesis-preview__niche">{{ user.userInfo.business.niche }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HypothesisPreview',
  props: {
    user: {
      type: Object,
      required: true
    },
    hypothesis: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss">
.hypothesis-preview {
  background-color: #171821;
  border-radius: 12px;
  padding: 20px;

  &__user {
  }

  &__header {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: 1fr 1fr;
    column-gap: 20px;
    margin-bottom: 10px;
    text-align: start;
  }

  &__avatar {
    width: 40px;
    height: 40px;
    grid-row: span 2;

    & > img {
      width: inherit;
      height: inherit;
    }
  }

  &__fullname {
    color: #ABBBF2;
  }

  &__deadline {
    font-family: Helvetica, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */

    display: flex;
    align-items: center;
    color: #546FCE;
  }

  &__text {
    padding-bottom: 16px;
    border-bottom: 1px solid #546FCE;
    margin-bottom: 14px;
    text-align: start;
    font-family: Helvetica, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.3px;
    color: #ABBBF2;
  }

  &__tags {
    text-align: start;
  }

  &__business-category {
    @extend %tag-button;
    background: #F2994A;
    color: #fff;
    margin-right: 10px;
  }

  &__niche {
    @extend %tag-button;
    background: rgba(105, 121, 248, 0.15);
    border-radius: 3px;
    color: #6979F8;
  }
}
</style>
