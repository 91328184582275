<template>
<div class="profile-add-sprint">
  <GoalIcon class="profile-add-sprint__icon" />
  <div class="profile-add-sprint__content">
    <div class="profile-add-sprint__message">Поставь план по выручке и прибыли на {{ week.number }} неделю!</div>
    <Button class="profile-add-sprint__add" :active="true" text="Внести план" @click="$emit('add')" />
  </div>
</div>
</template>

<script>
import Button from '@/controls/Button'
import GoalIcon from '@/icons/GoalIcon'

export default {
  name: 'ProfileAddSprint',
  components: {
    Button,
    GoalIcon
  },
  props: {
    week: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss">
.profile-add-sprint {
  display: flex;
  padding: 20px 0;
  align-items: center;
  background: linear-gradient(134.06deg, #2B3043 1.9%, #242732 100.02%);
  backdrop-filter: blur(42px);
  border-radius: 14px;

  &__content {
    display: flex;
    flex-direction: column;
    margin-right: 50px;
    padding: 10px;
  }

  &__icon {
    width: 160px;
    height: 160px;
    margin-left: 40px;
    flex-shrink: 0;
  }

  &__message {
    /* H2 - 18 - regular */
    font-family: Helvetica, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    /* or 122% */

    /* Purpure - Light */
    color: #ABBBF2;
  }

  &__add {
    margin-top: 20px;
  }
}
</style>
