<template>
  <div class="toolbar-menu">
    <div class="toolbar-menu__scroll">
      <div class="toolbar-menu__title">Профиль</div>
      <div class="toolbar-menu__avatar">
        <Avatar :avatar="avatarURL" class="toolbar-menu__avatar-view"/>
        <div class="toolbar-menu__avatar-add" @click="openFileSelect">
          <input ref="file-input" type="file" @change="onFileSelect($event)" hidden />
          <AddPhotoIcon/>
        </div>
      </div>
      <div class="toolbar-menu__section">
        <div class="toolbar-menu__block">
          <div class="toolbar-menu__label">Имя</div>
          <Edit :value="user.userInfo.name" placeholder="Укажи имя" @input="setName"/>
        </div>
        <div class="toolbar-menu__block">
          <div class="toolbar-menu__label">Фамилия</div>
          <Edit :value="user.userInfo.lastname" placeholder="Укажи фамилию" @input="setLastname"/>
        </div>
        <div class="toolbar-menu__block">
          <div class="toolbar-menu__label">Город</div>
          <Edit :value="user.userInfo.city" placeholder="Укажи свой город" @input="setCity"/>
        </div>
        <div class="toolbar-menu__block">
          <div class="toolbar-menu__label">Email</div>
          <Edit :value="user.userInfo.email" placeholder="Укажи email" @input="setEmail"/>
        </div>
      </div>
      <div class="toolbar-menu__title">Бизнес</div>
      <div class="toolbar-menu__section">
        <div class="toolbar-menu__block">
          <Combobox
            :list="businessCategories"
            :value="user.userInfo.business.businessCategory.id"
            placeholder="Укажи сферу"
            @setValue="selectBusinessCategory"
          />
        </div>
        <div class="toolbar-menu__block">
          <Edit :value="user.userInfo.business.niche" placeholder="Укажи нишу" @input="setNiche"/>
        </div>
        <div class="toolbar-menu__block">
          <div class="toolbar-menu__label">Точка А: выручка</div>
          <MoneyEdit v-model="user.aPoint.aPointRevenue" placeholder="Укажи точку А по выручке" />
        </div>
        <div class="toolbar-menu__block">
          <div class="toolbar-menu__label">Точка А: прибыль</div>
          <MoneyEdit v-model="user.aPoint.aPointProfit" placeholder="Укажи точку А по прибыли" />
        </div>
        <div class="toolbar-menu__block toolbar-menu__row">
          <div class="toolbar-menu__label">Название бизнеса</div>
          <Edit :value="user.userInfo.business.name" placeholder="Укажи название" @input="setBusinessName"/>
        </div>
      </div>
      <div class="toolbar-menu__title">Смена пароля</div>
      <div class="toolbar-menu__section">
        <div class="toolbar-menu__block toolbar-menu__row">
          <div class="toolbar-menu__label">Старый пароль</div>
          <PassEdit :value="oldPass" placeholder="Укажи старый пароль" @input="setOldPass"/>
        </div>
        <div class="toolbar-menu__block toolbar-menu__row">
          <div class="toolbar-menu__label">Новый пароль</div>
          <PassEdit :value="newPass" placeholder="Укажи новый пароль" @input="setNewPass"/>
        </div>
        <div class="toolbar-menu__block toolbar-menu__row">
          <div class="toolbar-menu__label">Повторите новый пароль</div>
          <PassEdit :value="repeatNewPass" placeholder="Повтори новый пароль" @input="setRepeatNewPass"/>
        </div>
      </div>
    </div>
    <div class="toolbar-menu__control">
      <Button :disabled="!canSave || loading" class="toolbar-menu__save" text="Сохранить" @click="save"/>
    </div>
  </div>
</template>

<script>
import Avatar from '@/controls/Avatar'
import Edit from '@/controls/Edit'
import MoneyEdit from '@/controls/MoneyEdit'
import Combobox from '@/controls/Combobox'
import PassEdit from '@/controls/PassEdit'
import Button from '@/controls/Button'
import AddPhotoIcon from '@/icons/AddPhotoIcon'
import model from '@/model'
import controller from '@/controller'
import { mapProps } from '@/utils/model'
import { copyProps } from '@/utils/data'
import { userSchema } from '@/model/schema/game'

export default {
  name: 'ProfileForm',
  components: {
    Avatar,
    Edit,
    Combobox,
    PassEdit,
    Button,
    AddPhotoIcon,
    MoneyEdit
  },
  data() {
    return {
      avatarURL: '',
      user: userSchema.createDefault(),
      mail: '',
      oldPass: '',
      newPass: '',
      repeatNewPass: '',
      loading: false
    }
  },
  computed: {
    ...mapProps(model.game, ['businessCategories']),
    needToChangePassword() {
      return this.oldPass !== '' && this.newPass !== '' && this.newPass === this.repeatNewPass
    },
    canSave() {
      return this.needToChangePassword || (this.oldPass === '' && this.newPass === '' && this.repeatNewPass === '')
    }
  },
  created() {
    userSchema.copy(this.user, model.game.user)
    this.avatarURL = this.user.userInfo.avatar
  },
  methods: {
    openFileSelect() {
      this.$refs['file-input'].click()
    },
    async onFileSelect(event) {
      const file = event.target.files[0]
      this.avatarURL = URL.createObjectURL(file)

      const response = await controller.game.uploadAvatar(file)
      if (response.status === 200) {
        this.user.userInfo.avatar = this.avatarURL
        controller.game.model.user.userInfo.avatar = this.avatarURL
        controller.app.addNotification({ text: 'Аватар успешно обновлен' }, 5000)
      } else {
        this.avatarURL = ''
      }
    },
    setName(name) {
      this.user.userInfo.name = name
    },
    setLastname(lastname) {
      this.user.userInfo.lastname = lastname
    },
    setCity(city) {
      this.user.userInfo.city = city
    },
    setEmail(email) {
      this.user.userInfo.email = email
    },
    selectBusinessCategory(categoryId) {
      const category = this.businessCategories.find(c => c.id === categoryId)
      copyProps(this.user.userInfo.business.businessCategory, category, ['id', 'name'])
    },
    setNiche(niche) {
      this.user.userInfo.business.niche = niche
    },
    setBusinessName(name) {
      this.user.userInfo.business.name = name
    },
    setOldPass(oldPass) {
      this.oldPass = oldPass
    },
    setNewPass(newPass) {
      this.newPass = newPass
    },
    setRepeatNewPass(repeatNewPass) {
      this.repeatNewPass = repeatNewPass
    },
    save() {
      this.updateData().then(() => {
        this.$emit('successForm')
      }).catch(error => {
        controller.app.addNotification({text:error.message || error.toString()}, 5000)
      })
    },
    async updateData() {
      this.loading = true
      await controller.game.updateProfile(this.user)
      if (model.game.gameRole === 'participant') {
        if (this.user.aPoint.aPointProfit !== model.game.user.aPoint?.aPointProfit
            || this.user.aPoint.aPointRevenue !== model.game.user.aPoint?.aPointRevenue) {
          const aPoint = { aPointProfit: this.user.aPoint.aPointProfit || 0, aPointRevenue: this.user.aPoint.aPointRevenue || 0 }
          if (model.game.user.aPoint) {
            await controller.game.updateAPoint(aPoint, model.game.user.aPoint.id)
          } else {
            await controller.game.createAPoint(aPoint)
          }
        }
      }

      if (this.needToChangePassword) {
        await controller.game.updatePassword({
          currentPassword: this.oldPass,
          newPassword1: this.newPass,
          newPassword2: this.repeatNewPass
        })
      }
      this.loading = false
    }
  }
}
</script>
