<template>
  <router-link :to="`/profile/${player.user.id}`"
    v-if="player.dayReportForTrackerProfile"
    :class="[
      {
        'player--first': place === 1,
        'player--second': place === 2,
        'player--third': place === 3,
        eliminated: isLoser,
      },
      'player',
      'large',
    ]">
    <div class="player__info">
      <div class="player__place">
        <component :is="placeView" class="player__place-view" />
        <div class="player__place-value">{{ place }}</div>
      </div>
      <Avatar
        class="player__avatar"
        :avatar="player.user.userInfo ? player.user.userInfo.avatar : ''"/>
      <div class="player__name">{{ fullname }}</div>
    </div>
    <div class="player__column">
      <div class="player__title">{{ columns[1] }}:</div>
      <div class="player__text">
        {{ player.dayReportForTrackerProfile.revenueFact ? player.dayReportForTrackerProfile.revenueTarget.toLocaleString("ru") : '-' }}
      </div>
    </div>
    <div class="player__column">
      <div class="player__title">{{ columns[2] }}:</div>
      <div class="player__text">
        {{ player.dayReportForTrackerProfile.revenueFact ? player.dayReportForTrackerProfile.revenueFact.toLocaleString("ru") : '-' }}
      </div>
    </div>
    <div class="player__column">
      <div class="player__title">{{ columns[3] }}:</div>
      <div class="player__text">
        {{  player.dayReportForTrackerProfile.profitTarget ? player.dayReportForTrackerProfile.profitTarget.toLocaleString("ru") : '-' }}
      </div>
    </div>
    <div class="player__column">
      <div class="player__title">{{ columns[4] }}:</div>
      <div class="player__text">
        {{ player.dayReportForTrackerProfile.profitFact ? player.dayReportForTrackerProfile.profitFact.toLocaleString("ru") : '-' }}
      </div>
    </div>
    <div
      class="player__column">
      <div class="player__title">{{ columns[5] }}:</div>
      <div class="player__text">
        {{ player.dayReportForTrackerProfile.hypothesisCount || '-' }}
      </div>
    </div>
  </router-link>
</template>

<script>
import Avatar from "@/controls/Avatar";
import StoneIcon from "@/icons/StoneIcon";
import BronzeIcon from "@/icons/BronzeIcon";
import SilverIcon from "@/icons/SilverIcon";
import GoldIcon from "@/icons/GoldIcon";
import StarIcon from "@/icons/StarIcon";
import StarActiveIcon from "@/icons/StarActiveIcon";

export default {
  name: "Player",
  components: {
    Avatar,
    StoneIcon,
    BronzeIcon,
    SilverIcon,
    GoldIcon,
    StarIcon,
    StarActiveIcon,
  },
  props: {
    isLoser: {
      type: Boolean,
      required: false,
    },
    player: {
      type: Object,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    place: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      placesList: {
        1: "GoldIcon",
        2: "SilverIcon",
        3: "BronzeIcon",
      },
    };
  },
  computed: {
    fullname() {
      let name =
        this.player.user.userInfo !== null
          ? `${this.player.user.userInfo.name} ${this.player.user.userInfo.lastname}`
          : "";
      return name.length > 1 ? name : "Аноним";
    },
    placeView() {
      if (this.place <= 3) return this.placesList[this.place];
      return "StoneIcon";
    },
  },
};
</script>

<style lang="scss">
.player {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  height: auto;
  padding: 10px;
  border-radius: 14px;
  font-weight: bold;
  font-size: 18px;
  color: #ABBBF2;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: #21222d;
  text-decoration: none;

  &.eliminated {
    opacity: 0.5;
  }

  &--first {
    border-color: #cfa365;
  }

  &--second {
    border-color: #c2c4cd;
  }

  &--third {
    border-color: #bd8a65;
  }

  &__info {
    display: flex;
    align-items: center;
    padding: $padding;
    width: 100%;
  }

  &__title {
    color: #546FCE;
  }

  &__place {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 40px;
    min-width: 40px;
    height: 40px;
    margin-right: 20px;

    &-view,
    &-value {
      position: absolute;
    }

    &-view {
      z-index: 1;
      top: 0;
    }

    &-value {
      z-index: 2;
      font-size: var(--edit-font-size);
      letter-spacing: var(--edit-letter-spacing);
      color: #ffffff;
    }
  }

  &__avatar {
    width: 40px;
    min-width: 40px;
    height: 40px;
    margin-right: 20px;
  }

  &__name, &__text {
    @extend %text-ellipsis
  }

  &__name {
    width: 100%;
    min-width: 32%;
    text-align: left;
  }

  &__column {
    display: flex;
    text-align: left;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: $padding/2 $padding;
    font-size: 14px;
  }

  &__set-favorit {
    position: absolute;
    right: 20px;
    cursor: pointer;
  }
}

@media screen and (min-width: $medium) and (max-width: $large - 2) {
  .player {
    &__column {
      justify-content: flex-start;
    }

    &__title {
      margin-right: 10px;
    }
  }
}

@media screen and (min-width: $large) {
  .player {
    align-items: center;
    flex-direction: row;
    height: 60px;

    &__column {
      justify-content: center;
      text-align: center;
      padding: 0;
      font-size: 18px;
    }

    &__info {
      padding: 0;
      min-width: 42%;
    }

    &__title {
      display: none;
    }

    &.large {
      .player__name {
        min-width: 19%;
      }

      .player__column {
        &:last-child {
          max-width: 7%;
        }
      }

      .player__info {
        padding: 0;
        min-width: 28%;
      }
    }
  }
}
</style>
