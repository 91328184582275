<template>
  <div class="hypothesis-action">
    <Checkbox class="hypothesis-action__complete" :value="action.isComplete" :readOnly="readonly"
      @input="flag => $emit('setComplete', flag)" />
    <input class="hypothesis-action__text" type="text" :readonly="readonly" :value="action.action"
      placeholder="Укажи шаг тестирования..." @input="event => $emit('setText', event.target.value)" />
    <div v-if="!readonly" class="hypothesis-action__delete" @click="$emit('delete')">
      <DeleteIcon />
    </div>
    <!-- <div class="hypothesis-action__drag"><MoveIcon/></div> -->
  </div>
</template>

<script>
import Checkbox from '@/controls/Checkbox'
import DeleteIcon from '@/icons/DeleteIcon'
/* import MoveIcon from '@/icons/MoveIcon' */

export default {
  name: 'HypothesisAction',
  components: {
    Checkbox,
    DeleteIcon,
    /* MoveIcon, */
  },
  props: {
    action: {
      type: Object,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
.hypothesis-action {
  display: flex;
  width: 100%;
  align-items: center;
  background: #171821;
  border-radius: 8px;

  &__complete {
    margin: 15px;
  }

  &__text {
    flex: 1 1 auto;
    height: 40px; // HARDCODE - replace with edit control
    outline: none;
    border: none;
    text-align: left;
    background-color: transparent;
    color: #ABBBF2;

    font-family: Helvetica;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.3px;

    &::placeholder {
      color: var(--edit-form-placeholder);
    } 
  }

  &__delete {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 40px;
    height: 40px;

    & svg {
      width: 16px;
      height: 16px;
    }
    
    path {
      fill: #546FCE;
    }
  }
}
</style>
