import Vue from 'vue'
import {businessPhotosSchema, dashboardSchema, profileSchema, waitersSchema} from './schema/game'

export default Vue.observable({
  ...profileSchema.createDefault(),
  dashboardData: dashboardSchema.createDefault(),
  waiters: waitersSchema.createDefault(),
  businessPhotos: businessPhotosSchema.createDefault()
})

// export default Vue.observable({
//   gameRole: 'participant',
//   user: {
//     id: 0
//   },
//   userInfo: {
//     name: '',
//     lastname: '',
//     city: ''
//   },
//   business: {
//     name: '',
//     niche: '',
//     businessCategory: {
//       id: 0,
//       name: ''
//     }
//   },
//   selectedWeekNumber: 0,
//   traction: []
// })

// export default Vue.observable({
//   user: {
//     id: 1
//   },
//   userInfo: {
//     name:'\u0418\u043c\u044f2',
//     lastname:'\u0424\u0430\u043c\u0438\u043b\u0438\u044f3',
//     city:'\u041c\u043e\u0441\u043a\u0432\u0430'
//   },
//   business: {
//     name:'\u041d\u043e\u0432\u0430\u044f \u043a\u043e\u043c\u043f\u0430\u043d\u0438\u044f',
//     niche:'123',
//     businessCategory: {
//       id:0,
//       name:'\u041d\u0435 \u043e\u043f\u0440\u0435\u0434\u0435\u043b\u0435\u043d\u043e'
//     }
//   },
//   selectedWeekNumber: 0,
//   traction: [
//     {
//       id:1,
//       number:1,
//       valueOfWord:'asdf',
//       reward:'dasdfasdf',
//       totalRevenueTarget:0,
//       totalRevenueFact:0,
//       totalProfitTarget:0,
//       totalProfitFact:0,
//       isCurrent:false,
//       dayReports:[]
//     },
//     {
//       id:2,
//       number:2,
//       valueOfWord:'\u0422\u0443\u0442 \u0442\u0438\u043f\u044b \u043d\u0430\u043f\u0438\u0441\u0430\u043d\u0430 \u0446\u0435\u043d\u0430 \u0441\u043b\u043e\u0432\u0430 \u0437\u0430 \u043d\u0435\u0434\u0435\u043b\u044e 2',
//       reward:'\u0422\u0443\u0442 \u0442\u0438\u043f\u044b \u043d\u0430\u043f\u0438\u0441\u0430\u043d\u0430 \u043d\u0430\u0433\u0440\u0430\u0434\u0430 \u0437\u0430 \u043d\u0435\u0434\u0435\u043b\u044e 2',
//       totalRevenueTarget:0,
//       totalRevenueFact:0,
//       totalProfitTarget:0,
//       totalProfitFact:0,
//       isCurrent:false,
//       dayReports:[]
//     },
//     {
//       id:12,
//       number:3,
//       valueOfWord:'asdf',
//       reward:'dasd',
//       totalRevenueTarget:0,
//       totalRevenueFact:0,
//       totalProfitTarget:0,
//       totalProfitFact:0,
//       isCurrent:true,
//       dayReports: [
//         {
//           id:null,
//           day:1,
//           availableFrom:'2021-10-18T00:00:00+00:00',
//           availableTo:'2021-10-18T23:59:59+00:00',
//           revenueTarget:null,
//           revenueFact:null,
//           profitTarget:null,
//           profitFact:null,
//           status:'current'
//         },
//         {
//           id:null,
//           day:2,
//           availableFrom:'2021-10-19T00:00:00+00:00',
//           availableTo:'2021-10-19T23:59:59+00:00',
//           revenueTarget:null,
//           revenueFact:null,
//           profitTarget:null,
//           profitFact:null,
//           status:'future'
//         },
//         {
//           id:null,
//           day:3,
//           availableFrom:'2021-10-20T00:00:00+00:00',
//           availableTo:'2021-10-20T23:59:59+00:00',
//           revenueTarget:null,
//           revenueFact:null,
//           profitTarget:null,
//           profitFact:null,
//           status:'future'
//         },
//         {
//           id:null,
//           day:4,
//           availableFrom:'2021-10-21T00:00:00+00:00',
//           availableTo:'2021-10-21T23:59:59+00:00',
//           revenueTarget:null,
//           revenueFact:null,
//           profitTarget:null,
//           profitFact:null,
//           status:'future'
//         },
//         {
//           id:null,
//           day:5,
//           availableFrom:'2021-10-22T00:00:00+00:00',
//           availableTo:'2021-10-22T23:59:59+00:00',
//           revenueTarget:null,
//           revenueFact:null,
//           profitTarget:null,
//           profitFact:null,
//           status:'future'
//         },
//         {
//           id:null,
//           day:6,
//           availableFrom:'2021-10-23T00:00:00+00:00',
//           availableTo:'2021-10-23T23:59:59+00:00',
//           revenueTarget:null,
//           revenueFact:null,
//           profitTarget:null,
//           profitFact:null,
//           status:'future'
//         },
//         {
//           id:null,
//           day: 7,
//           availableFrom: '2021-10-24T00:00:00+00:00',
//           availableTo: '2021-10-24T23:59:59+00:00',
//           revenueTarget: null,
//           revenueFact: null,
//           profitTarget: null,
//           profitFact: null,
//           status: 'future'
//         }
//       ]
//     },
//     {
//       id: null,
//       number: 4,
//       valueOfWord: null,
//       reward: null,
//       totalRevenueTarget: 0,
//       totalRevenueFact: 0,
//       totalProfitTarget: 0,
//       totalProfitFact: 0,
//       isCurrent: false,
//       dayReports: []
//     }
//   ],
//   gameRole: 'participant'
// })
