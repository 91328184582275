<template>
  <div class="toolbar-menu">
    <!-- <div class="toolbar-menu"> -->
      <div class="toolbar-menu__scroll">
        <div class="toolbar-menu__title">Гипотеза
          <div class="toolbar-menu__prompt">
            <div class="toolbar-menu__prompt-block">{{ day.day }} день <span></span> {{ sprint.number }} неделя</div>
            <div class="toolbar-menu__prompt-block">{{ dayMonth }}</div>
          </div>
        </div>
        <div class="toolbar-menu__section">
          <div class="toolbar-menu__block toolbar-menu__row">
            <HypothesisPreview :user="user" :hypothesis="hypothesis" />
          </div>
        </div>
        <div class="toolbar-menu__title toolbar-menu__btn toolbar-menu__title-mobile">Шаги тестирования
          <PlusIcon/>
        </div>
        <div class="toolbar-menu__section">
          <div class="toolbar-menu__block toolbar-menu__row" v-for="(action, index) in buffer.actions" :key="index">
            <TestStep :text="action.action" isDone @setChecked="(value) => setActionComplete(action, value)" />
          </div>
        </div>
        <template v-if="isTested">
          <div class="toolbar-menu__title">Выводы
            <div class="toolbar-menu__prompt">Шагов закрыто: {{ completedActionsCount }}/{{ actionsCount }}</div>
          </div>
          <div class="toolbar-menu__section">
            <div class="toolbar-menu__block toolbar-menu__row">
              <Textarea :max="255" :value="buffer.conclusions" @input="setConclusions" placeholder="Опиши результат тестирования" />
            </div>
            <!-- <div class="toolbar-menu__block toolbar-menu__row">
              <Textarea :max="255" :value="difficulties" @input="setDifficulties" placeholder="Опиши, какие трудности возникли"/>
            </div> -->
            <div class="toolbar-menu__block">
              <div class="toolbar-menu__label">Потрачено</div>
              <MoneyEdit :value="expenses" @input="setExpenses"/>
            </div>
            <div class="toolbar-menu__block">
              <div class="toolbar-menu__label">Заработано</div>
              <MoneyEdit :value="revenue" @input="setRevenue"/>
            </div>
          </div>
          <div class="toolbar-menu__section">
            <div class="toolbar-menu__block">
              <div class="toolbar-menu__checkbox">
                <Checkbox :value="isSuccessful" @input="setSuccessfull"/> Гипотеза успешна
              </div>
            </div>
          </div>
        </template>
        <div class="toolbar-menu-comments" v-if="!showCommentsRight">
          <SendMessage placeholder="Написать комментарий к гипотезе..." @send="createComment($event)"/>
          <Comment
              v-for="comment in filteredComments"
              :key="comment.id"
              :name="commentatorName(comment.user)"
              :text="comment.comment"
              :avatar="comment.user.userInfo.avatar"
              :likes="comment.likes.count"
              :dislikes="comment.dislikes.count"
              :liked="!!comment.likes.isChecked"
              :disliked="!!comment.dislikes.isChecked"
              :date="comment.createdAt"
              @like="likeComment(comment.id)"
              @dislike="dislikeComment(comment.id)"
          />
          <div class="toolbar-menu-comments__loader comment" v-if="isLoadingComments">
            Загружаем комментарии...
          </div>
          <div class="toolbar-menu-comments__empty comment" v-if="!isLoadingComments && !this.comments.length">
            Комментарии отсутствуют
          </div>
          <div v-if="!showMoreComments && this.comments.length > 5" class="toolbar-menu__show-comments" @click="showMoreComments = true">
            Показать все комментарии ({{ comments.length - 5 }})
          </div>
        </div>
      </div>
    <div class="toolbar-menu__control">
      <Button class="toolbar-menu__save" text="Сохранить гипотезу" :disabled="disabled || loading" @click="save" />
    </div>

    <div class="toolbar-menu-comments" v-if="showCommentsRight">
      <div class="toolbar-menu__desc">
        <ReactionIcon class="toolbar-menu__desc-icon"/>
        <div class="toolbar-menu__desc-info">
          <div class="toolbar-menu__desc-header">Напиши свой комментарий</div>
          <div class="toolbar-menu__desc-text">Комментарии к гипотезе видны всем участникам и наблюдателям.</div>
        </div>
      </div>
      <SendMessage placeholder="Написать комментарий к гипотезе..." @send="createComment($event)"/>
      <Comment
          v-for="comment in filteredComments"
          :key="comment.id"
          :name="commentatorName(comment.user)"
          :text="comment.comment"
          :avatar="comment.user.userInfo.avatar"
          :likes="comment.likes.count"
          :dislikes="comment.dislikes.count"
          :liked="!!comment.likes.isChecked"
          :disliked="!!comment.dislikes.isChecked"
          :date="comment.createdAt"
          @like="likeComment(comment.id)"
          @dislike="dislikeComment(comment.id)"
      />
      <div class="toolbar-menu-comments__loader comment" v-if="isLoadingComments">
        Загружаем комментарии...
      </div>
      <div class="toolbar-menu-comments__empty comment" v-if="!isLoadingComments && !this.comments.length">
        Комментарии отсутствуют
      </div>
      <div v-if="!showMoreComments && this.comments.length > 5" class="toolbar-menu__show-comments" @click="showMoreComments = true">
        Показать все комментарии ({{ comments.length - 5 }})
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/controls/Button'
import TestStep from '@/controls/TestStep'
import Comment from '@/controls/Comment'
import SendMessage from '@/controls/SendMessage'
import Textarea from '@/controls/Textarea'
import MoneyEdit from '@/controls/MoneyEdit'
import Checkbox from '@/controls/Checkbox'
import HypothesisPreview from '@/controls/HypothesisPreview'
import PlusIcon from '@/icons/PlusIcon'
import ReactionIcon from '@/icons/ReactionIcon'
import model from '@/model'
import controller from '@/controller'
import { tzParseDate } from '@/utils/time'
import { hypothesisCommentsSchema, hypothesisSchema} from '@/model/schema/game'
import { mapProps } from '@/utils/model'
import logger from '@/logger'

export default {
  name: 'ClosingHypotheseForm',
  components: {
    Button,
    TestStep,
    Comment,
    SendMessage,
    Textarea,
    MoneyEdit,
    Checkbox,
    HypothesisPreview,
    PlusIcon,
    ReactionIcon
  },
  props: {
    hypothesis: {
      type: Object,
      required: true
    },
    sprint: {
      type: Object,
      required: true
    },
    day: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      buffer: hypothesisSchema.createDefault(),
      loading: false,
      width: innerWidth,
      comments: [],
      isLoadingComments: false,
      showMoreComments: false
    }
  },
  computed: {
    ...mapProps(model.game, ['user', 'selectedWeekNumber', 'traction']),
    isSuccessful() {
      return this.hypothesis.isSuccessfull || false
    },
    expenses() {
      return this.hypothesis.expenses || 0
    },
    revenue() {
      return this.hypothesis.revenue || 0
    },
    conclusions() {
      return this.hypothesis.conclusions
    },
    dayMonth() {
      const day = tzParseDate(this.hypothesis.ddl)
      return day?.toLocaleString('ru', { month: 'long', day: 'numeric' }) || ''
    },
    avatar() {
      return this.user.userInfo.avatar
    },
    fullname() {
      let name = this.user.userInfo !== null ? `${this.user.userInfo.name} ${this.user.userInfo.lastname}` : ''
      return name.length > 1 ? name : 'Не указано'
    },
    completedActionsCount() {
      return this.buffer.actions.reduce((sum, a) => a.isComplete ? sum + 1 : sum, 0)
    },
    actionsCount() {
      return this.buffer.actions.length
    },
    isTested() {
      return this.completedActionsCount == this.actionsCount
    },
    disabled() {
      return !(this.isTested && this.buffer.conclusions)
    },
    showCommentsRight() {
      return this.width >= 820
    },
    filteredComments() {
      return this.showMoreComments ? this.comments : this.comments.slice(0, 5)
    }
  },
  created() {
    logger.warn('-- buffer:', this.buffer)
    logger.log('hypothesis:', this.hypothesis)

    hypothesisSchema.copy(this.buffer, this.hypothesis)
    if (!this.buffer.actions.length) this.buffer.actions.push({
      id: 1,
      action: '',
      isComplete: false
    })

    this.getComments(this.hypothesis.id)
    logger.warn('-- ClosingHypotheseForm.created hypothesis:', this.hypothesis)
    logger.log('day:', this.day)
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    save() {
      this.updateData().then(() => {
        this.$emit('successForm')
      }).catch(error => {
        controller.app.addNotification({text:error.message || error.toString()}, 5000)
      })
    },
    async updateData() {
      this.loading = true
      await controller.game.updateHypothesis(this.hypothesis, this.buffer, this.day)
      this.loading = false
    },
    setConclusions(conclusions) {
      this.buffer.conclusions = conclusions
    },
    /* setDifficulties(difficulties) { */
    /*   this.difficulties = difficulties */
    /* }, */
    setExpenses(expenses) {
      this.buffer.expenses = expenses
    },
    setRevenue(revenue) {
      this.buffer.revenue = revenue
    },
    setSuccessfull(flag) {
      this.buffer.isSuccessful = +flag
    },
    setActionComplete(action, value) {
      action.isComplete = value
    },
    async getComments(id) {
      this.isLoadingComments = true
      const { data } = await controller.game.getHypothesisComments(id)
      this.comments = hypothesisCommentsSchema.deserialize(data)
      this.isLoadingComments = false
    },
    async createComment(comment) {
      this.isLoadingComments = true
      const obj = { comment, hypothesis: this.hypothesis.id }
      await controller.game.createHypothesisComment(obj)
      await this.getComments(this.hypothesis.id)
      this.isLoadingComments = false
    },
    async likeComment(id) {
      await controller.game.likeHypothesisComment(id)
      const comment = this.comments.find(c => c.id === id)
      if (!comment.likes.isChecked) {
        comment.likes.count++
        comment.likes.isChecked = true
        if (comment.dislikes.isChecked) {
          comment.dislikes.count--
          comment.dislikes.isChecked = false
        }
      } else {
        comment.likes.count--
        comment.likes.isChecked = false
      }
    },
    async dislikeComment(id) {
      await controller.game.dislikeComment(id)
      const comment = this.comments.find(c => c.id === id)
      if (!comment.dislikes.isChecked) {
        comment.dislikes.count++
        comment.dislikes.isChecked = true
        if (comment.likes.isChecked) {
          comment.likes.count--
          comment.likes.isChecked = false
        }
      } else {
        comment.dislikes.count--
        comment.dislikes.isChecked = false
      }
    },
    commentatorName(user) {
      return this.user.userInfo.name || this.userInfo.lastname ? user.userInfo.name + ' ' + user.userInfo.lastname : 'Аноним'
    },
    onResize() {
      this.width = innerWidth
    }
  }
}
</script>

<style lang="scss">
.test-steps {
  display: flex;
  flex-direction: column;
}

.toolbar-menu-comments__loader.comment {
  width: 100%;
  height: 60px;
  font-size: 14px;
  text-align: center;
  vertical-align: center;
  padding: 20px;
}

.toolbar-menu-comments__empty {
  width: 100%;
  height: 60px;
  font-size: 14px;
  text-align: center;
  vertical-align: center;
  padding: 20px;
  background-color: #171821;
}
</style>
