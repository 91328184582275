<template>
  <div class="hypothesis-form toolbar-menu sidebar__body" :class="{'sidebar__nocomments': !showComments}">
    <div class="toolbar-menu__scroll">
      <div class="toolbar-menu__title">Гипотеза
        <div class="toolbar-menu__prompt">
          <div class="toolbar-menu__prompt-block">{{ day.day }} день <span></span>{{ week.number }} неделя</div>
          <div class="toolbar-menu__prompt-block">{{ dayMonth }}</div>
        </div>
      </div>
      <div class="toolbar-menu__section"><div class="toolbar-menu__prompt-block" v-tooltip="statusTooltip">Статус: {{ buffer.status }}</div></div>
      <div class="toolbar-menu__section">
        <div class="toolbar-menu__block">
          <Combobox :value="category" @setValue="setCategory" :list="categories" placeholder="Выбери категорию"/>
        </div>
        <div class="toolbar-menu__block toolbar-menu__row">
          <div class="toolbar-menu__label">Гипотеза</div>
          <Textarea :max="255" :value="buffer.text" @input="setText" placeholder="Напиши свою гипотезу"/>
        </div>
        <div class="toolbar-menu__block toolbar-menu__row">
          <Burger
            title="Показать пример гипотезы"
            text="Если я выложу продающий пост о том, что в стоимость съемки будет ключена стоимость изветсного визажиста, у меня увеличится число продаж"
            :words="words"
          />
        </div>
      </div>
      <div class="toolbar-menu__title toolbar-menu__btn">Шаги тестирования</div>
      <div class="toolbar-menu__section hypothesis-form__actions">
        <HypothesisAction v-for="(action, index) in buffer.actions" :key="index" :action="action" :readonly="readonly"
          @setComplete="flag => setActionComplete(action, flag)"
          @setText="text => setActionText(action, text)"
          @delete="deleteAction(action)" />
      </div>
      <template v-if="statusNew || statusTested">
        <div class="toolbar-menu__title">Выводы
          <div class="toolbar-menu__prompt">Шагов закрыто: {{ completedActionsCount }}/{{ actionsCount }}</div>
        </div>
        <div class="toolbar-menu__section">
          <div class="toolbar-menu__block toolbar-menu__row">
            <Textarea :max="255" :value="buffer.conclusions" @input="setConclusions" placeholder="Опиши результат тестирования" />
          </div>
          <div class="toolbar-menu__block">
            <div class="toolbar-menu__label">Потрачено</div>
            <MoneyEdit :value="expenses" @input="setExpenses"/>
          </div>
          <div class="toolbar-menu__block">
            <div class="toolbar-menu__label">Заработано</div>
            <MoneyEdit :value="revenue" @input="setRevenue"/>
          </div>
        </div>
        <div class="toolbar-menu__section">
          <div class="toolbar-menu__block">
            <div class="toolbar-menu__checkbox">
              <Checkbox :value="buffer.isSuccessful" @input="setSuccessfull" /> Гипотеза успешна
            </div>
          </div>
        </div>
      </template>
    </div>
    <div class="toolbar-menu__control">
      <Button class="toolbar-menu__save" text="Сохранить" @click="save" v-tooltip="saveTooltip"/>
      <!-- <Button v-if="statusNew || statusTested" class="toolbar-menu__delete" text="Удалить" @click="remove"/> -->
    </div>
    <!-- <HypothesisComments v-if="showComments" :hypothesis="hypothesis" /> -->
  </div>
</template>

<script>
import MoneyEdit from '@/controls/MoneyEdit'
import Checkbox from '@/controls/Checkbox'
import Combobox from '@/controls/Combobox'
import Textarea from '@/controls/Textarea'
import Burger from '@/controls/Burger'
import Button from '@/controls/Button'
import HypothesisAction from './HypothesisAction'
/* import HypothesisComments from './HypothesisComments' */
import model from '@/model'
import controller from '@/controller'
import { mapProps } from '@/utils/model'
/* import { copyProps } from '@/utils/data' */
import schema from '@/model/schema'
import logger from '@/logger'

export default {
  name: 'HypotheseForm',
  components: {
    MoneyEdit,
    Checkbox,
    Combobox,
    Textarea,
    Burger,
    Button,
    HypothesisAction,
    /* HypothesisComments */
  },
  props: {
    hypothesis: {
      type: Object,
      required: true
    },
    day: {
      type: Object,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      buffer: schema.hypothesis.createDefault(),
      category: -1,
      words: [
        {
          id: 'test',
          name: 'Фастфуд и общепит',
          color: '#FFFFFF',
          bgColor: '#1CAA82', 
        },
        {
          id: 'test1',
          name: 'Доставка и логистика',
          color: '#BB6BD9',
          bgColor: 'rgba(187, 107, 217, 0.15)', 
        },
      ]
    }
  },
  computed: {
    ...mapProps(model.game, [ 'hypothesisCategories', 'selectedWeekNumber', 'traction' ]),
    isEditing() {
      return this.buffer.id
    },
    statusNone() {
      return this.buffer.status === schema.hypothesis.STATUS_NONE
    },
    statusNew() {
      return this.buffer.status === schema.hypothesis.STATUS_NEW
    },
    statusTested() {
      return this.buffer.status === schema.hypothesis.STATUS_TESTED
    },
    week() {
      return this.traction.find(t => t.number === this.selectedWeekNumber)
    },
    dayMonth() {
      return this.day.availableFrom?.toLocaleString('ru', { month: 'long', day: 'numeric' }) || ''
    },
    categories() {
      return model.game.hypothesisCategories.map((c, i) => ({ id: i, name: c }))
    },
    expenses() {
      return this.hypothesis.expenses || 0
    },
    revenue() {
      return this.hypothesis.revenue || 0
    },
    conclusions() {
      return this.hypothesis.conclusions
    },
    filledActions() {
      return this.buffer.actions.filter(action => action.action.trim())
    },
    completedActionsCount() {
      return this.filledActions.reduce((sum, a) => a.isComplete ? sum + 1 : sum, 0)
    },
    actionsCount() {
      return this.filledActions.length
    },
    isValid() {
      switch (this.buffer.status) {
        case schema.hypothesis.STATUS_NONE:
          return this.buffer.text && this.buffer.category
        case schema.hypothesis.STATUS_NEW:
          return this.buffer.text && this.buffer.category
        case schema.hypothesis.STATUS_TESTED:
          return this.buffer.text && this.buffer.category
      }

      logger.error(`HypothesisForm: unknown hypothesis.status="${this.buffer.status}"`)

      return false
    },
    statusTooltip() {
      return `-> Статус Новая - выбери категорию, заполни текст гипотезы и шаги тестирования, сохрани
      -> Статус Протестирована - отметь шаги тестирования как завершенные и
        заполни выводы, потрачено и заработано, сохрани`
    },
    saveTooltip() {
      const fields = []

      if (!this.buffer.category.trim()) fields.push('Категория')
      if (!this.buffer.text.trim()) fields.push('Гипотеза')
      if (!this.filledActions.length) fields.push('Шаги тестирования')

      return fields.length ? `Заполни поля: ${fields.join(', ')}` : null
    },
    showComments() {
      return this.hypothesis.status !== schema.hypothesis.STATUS_NONE
    }
  },
  created() {
    logger.warn('-- HypothesisForm.created hypothesis:', this.hypothesis)

    schema.hypothesis.copy(this.buffer, this.hypothesis)
    this.appendDefaultAction()

    if (!this.buffer.businessCategory) this.buffer.businessCategory = model.game.user.userInfo.business.businessCategory.name
    if (this.buffer.category) {
      this.category = this.categories.find(c => c.name === this.buffer.category)?.id || -1
    }
    if (!this.buffer.ddl) this.buffer.ddl = this.day.availableTo

    logger.log('buffer:', this.buffer)
  },
  methods: {
    setCategory(category) {
      this.category = category
      this.buffer.category = this.categories.find(c => c.id === category)?.name || ''
    },
    setText(text) {
      this.buffer.text = text
    },
    setConclusions(conclusions) {
      this.buffer.conclusions = conclusions
    },
    setExpenses(expenses) {
      this.buffer.expenses = expenses
    },
    setRevenue(revenue) {
      this.buffer.revenue = revenue
    },
    setSuccessfull(flag) {
      this.buffer.isSuccessful = flag
    },
    appendDefaultAction() {
      this.buffer.actions.push(schema.hypothesis.createDefaultAction())
    },
    setActionComplete(action, flag) {
      logger.warn('-- setActionComplete:', flag, action)

      action.isComplete = flag
    },
    setActionText(action, text) {
      action.action = text

      // append default action
      const index = this.buffer.actions.findIndex(a => a === action)
      const lastIndex = this.buffer.actions.length - 1
      if (text && index === lastIndex) this.appendDefaultAction()
    },
    deleteAction(action) {
      const index = this.buffer.actions.findIndex(a => a === action)
      if (index !== -1) this.buffer.actions.splice(index, 1)

      // append default action
      const lastIndex = this.buffer.actions.length - 1
      const last = lastIndex >= 0 ? this.buffer.actions[lastIndex] : null

      if (!last || last.action.trim()) this.appendDefaultAction()
    },
    async save() {
      if (!this.isValid) return

      this.buffer.actions = this.filledActions

      /* let error = null */
      if (this.buffer.id) {
        /* error = await controller.game.updateHypothesis(this.hypothesis, this.buffer, this.day) */
        await controller.game.updateHypothesis(this.hypothesis, this.buffer, this.day)
      } else {
        /* error = await controller.game.createHypothesis(this.buffer, this.day) */
        await controller.game.createHypothesis(this.buffer, this.day)
        schema.hypothesis.copy(this.hypothesis, this.buffer)
      }

      this.appendDefaultAction()

      /* if (this.buffer.status === schema.hypothesis.STATUS_TESTED) this.$emit('closeSidebar') */
      /* if (!error) this.$emit('closeSidebar') */
    },
    async remove() {
      const error = await controller.game.deleteHypothesis(this.buffer.id)
      if (!error) this.$emit('closeSidebar')
    }
  }
}
</script>

<style lang="scss">
.hypothesis-form {
  &__actions {
    flex: 1 1 auto;
  }

  &.toolbar-menu {
    grid-template-areas:
      'hypothesis'
      'control';
    grid-template-rows: 1fr auto;
  }
}

.form__buttons {
  /* display: flex; */
  width: 100%;
  height: 100px;
  padding: 20px 40px;
  flex: 0 0 auto;
  border-top: 1px solid #546FCE;
}
</style>
