<template>
  <div class="reset-password">
    <img :src="loginBg" alt="bg" class="reset-password__bg" />
    <div class="reset-password__card-border">
      <div class="reset-password__card card">
        <l-c-icon class="card__logo" />
        <h1 class="card__title">Восстановить доступ</h1>
        <form class="card__form">
          <div class="card__field">
            <label class="card__label">Пароль</label>
            <pass-edit v-model.trim="password" placeholder="Введи пароль" />
          </div>
          <div class="card__field">
            <label class="card__label">Повтори пароль</label>
            <pass-edit v-model.trim="repeatPassword" placeholder="Введи пароль" />
          </div>
          <Button class="card__submit" :active="passwordsEqual" :disabled="!passwordsEqual" text="Войти" @click="onResetPassword" />
        </form>
        <div class="card__register">
          Нет аккаунта?
          <router-link to="register" >Зарегистрироваться</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import controller from '@/controller'

import LCIcon from '@/icons/LCIcon'
import Button from '@/controls/Button'
import PassEdit from '@/controls/PassEdit'

import loginBg from '@/assets/login_bg.png'


export default {
  name: 'ResetPassword',
  components: { LCIcon, Button, PassEdit },
  props: {
    hash: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    password: '',
    repeatPassword: '',
    loginBg: loginBg
  }),
  computed: {
    passwordsEqual() {
      return this.password === this.repeatPassword && this.password
    }
  },
  methods: {
    onResetPassword() {
      if (this.passwordsEqual) {
        controller.auth.resetPassword(this.password, this.repeatPassword, this.hash)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.reset-password {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  &__bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
    min-height: 100%;
    object-fit: cover;
    z-index: -1;
  }

  &__card-border {
    width: 600px;
    height: fit-content;
    border-radius: 24px;
    padding: 2px;
    background: linear-gradient(rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.1) 100%);

    @media (max-width: 630px) {
      width: 100%;
      margin: 0 15px;
    }
  }

  &__card {
    width: 100%;
    border-radius: 22px;
    background: linear-gradient(
            247.99deg,
            rgba(53, 30, 89, 0.97) 0%,
            rgba(42, 32, 82, 0.97) 48.89%,
            rgba(37, 18, 62, 0.97) 98.46%
    );
    backdrop-filter: blur(19,5px);
    box-shadow: 20px 37px 67px rgba(23, 18, 43, 0.45);
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-direction: column;
    padding: 40px 85px;
    @media (max-width: 630px) {
      padding: 40px 20px;
    }
  }
}

.card {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;

  &__logo {
    align-self: center;
  }

  &__title {
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: var(--edit-color);
  }

  &__field {
    display: flex;
    flex-direction: column;
    margin-bottom: 14px;
    font-size: 12px;
    color: var(--edit-color-normal);
  }

  &__label {
    align-self: flex-start;
    margin-bottom: 5px;
  }

  &__forgot {
    margin-top: 6px;
    align-self: flex-end;
    font-size: 14px;
    font-kerning: -0.3px;
    text-decoration: none;
    color: var(--edit-color-normal);
  }

  &__request-status {
    margin: 0 15px;
    font-size: 14px;
    letter-spacing: -0.3px;

    &--positive {
      color: #1DCD40;
    }

    &--negative {
      color: #CD1D1D;
    }
  }

  &__submit {
    width: 100%;
    margin-bottom: 60px;
  }

  &__register {
    font-size: 14px;
    font-kerning: -0.3px;
    color: var(--edit-color-normal);

    a {
      color: var(--edit-color);
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
