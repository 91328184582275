export default function getBusinessCategoryColor(category) {
  const color = '#FFFFFF'
  let bgColor
  category = category.toLowerCase()
  switch (category) {
    case 'Фастфуд и общепит':
      bgColor = '#1CAA82'
      break
    case 'Доставка и логистика':
      bgColor = '#219653'
      break
    case 'Стартаперы':
      bgColor = '#EB5757'
      break
    case 'Консалтинг':
      bgColor = '#F2994A'
      break
    case 'Маркетинг':
      bgColor = '#2D9CDB'
      break
    case 'Оптовые продажи':
      bgColor = '#2F80ED'
      break
    case 'Продажа товаров':
      bgColor = '#F2C94C'
      break
    case 'Спорт и здоровье':
      bgColor = '#BB6BD9'
      break
    case 'Красота':
      bgColor = '#9B51E0'
      break
    case 'Ремонт, строительство':
      bgColor = '#69B3F8'
      break
    case 'Образование':
      bgColor = '#3040C2'
      break
    case 'Недвижимость':
      bgColor = '#6979F8'
      break
    case 'Авто':
      bgColor = '#DF3FF9'
      break
    case 'Туризм':
      bgColor = '#BD8AF6'
      break
    case 'Производство':
      bgColor = '#F880A2'
      break
    case 'Сезонный бизнес':
      bgColor = '#FF9051'
      break
    default:
      bgColor = '#F2994A'
      break
  }
  return { color, bgColor }
}
