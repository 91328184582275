<template>
  <div class="currency-input">
    <input type="text" :readonly="readonly" v-model="inputValue"
           :class="[{ 'editing': showConfirmButton }, { 'readonly': readonly }, 'currency-input__value']"
           ref="input"
           @blur="onChange"
           @keyup.enter="enterWithBlur"
           @keyup.esc="undo"
           @keypress="validateInput"
    />
    <transition name="editing">
      <div v-if="showConfirmButton" class="currency-input__button" @click="onConfirm">
        <MarkIcon />
      </div>
    </transition>
    <div class="currency-input__symbol">{{ symbol }}</div>
  </div>
</template>

<script>
import MarkIcon from '@/icons/MarkIcon'

export default {
  name: 'CurrencyInput',
  components: {
    MarkIcon
  },
  props: {
    value: {
      type: Number
    },
    symbol: {
      type: String,
      default: '₽',
    },
    confirm: {
      type: Boolean,
      default: false
    },
    allowNegative: {
      type: Boolean,
      default: false
    },
    allowNull: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    showConfirmButton() {
      return this.confirm && ((this.value !== null ? this.value.toString() : '') !== this.inputValue)
    },
    inputValue: {
      get() {
        return this.formatValue(this.value)
      },
      set(value) {
        if (value === '') {
          this.$emit('input', this.allowNull ? null : 0)
          return
        }
        if (value === '-') {
          this.$emit('input', -0)
          return
        }
        const maskedValue = value.replace(this.allowNegative ? /[^\d\- ]/g : /[^\d ]/g, '')
        const parsedValue = parseInt(maskedValue)
        if (!isNaN(parsedValue)) {
          this.$emit('input', parsedValue)
        } else {
          this.$emit('input', null)
        }
      }
    },
  },
  methods: {
    onChange() {
      if (!this.readonly && !this.confirm) {
        this.$emit('change', this.value)
      }
    },
    onConfirm() {
      this.$emit('change', this.value)
    },
    enterWithBlur() {
      this.$refs.input.blur()
    },
    undo() {
      this.$emit('undo')
    },
    formatValue(value) {
      return value !== null && !isNaN(value) ? new Intl.NumberFormat('ru-RU', {
        style: 'decimal',
        maximumFractionDigits: 0
      }).format(value) : '–'
    },
    validateInput(e) {
      if (this.allowNegative && e.key === '-') {
        this.inputValue = '-'
        e.preventDefault()
        return
      }
      if ((this.allowNegative ? /[^\d-]/g : /[^\d]/g).test(e.key)) {
        e.preventDefault()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.currency-input {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  font-size: var(--edit-font-size);
  line-height: var(--edit-line-height);
  overflow: hidden;


  &__value {
    outline: none;
    border: 1px solid transparent;
    text-align: left;
    width: 100%;
    padding: 14px 10px;
    border-radius: 8px;
    background-color: #181922;
    color: #ABBBF2;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    &:focus:not(.readonly), &.editing {
      border-color: var(--edit-form-border-color);
    }

    &.readonly {
      cursor: default;
    }
  }

  &__value::placeholder, &__symbol {
    color: #546FCE;
  }

  &__symbol, &__button {
    position: absolute;
  }

  &__symbol {
    right: 10px;
    pointer-events: none;
    z-index: 1;
  }

  &__button {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    right: 2px;
    width: 32px;
    border-radius: 6px;
    height: calc(100% - 4px);
    background-color: var(--edit-form-border-color);
  }

  .editing-enter-active, .editing-leave-active {
    transition: all .3s ease;
  }

  .editing-leave-active {
    transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }

  .editing-enter, .editing-leave-to {
    opacity: 0;
  }

  .editing-enter {
    transform: translateX(10px);
  }
}
</style>
