<template>
  <div></div>
</template>

<script>
import controller from '@/controller'

export default {
  name: 'Logout',
  created() {
    controller.auth.logout()
  }
}
</script>
