<template>
  <div class="sidebar" :class="classes">
    <CloseIcon class="sidebar__close" @click.native="closeSidebar"/>
    <component class="sidebar__body" :is="component" v-bind="params" @closeSidebar="closeSidebar"/>
    <div class="sidebar__back" @click="closeSidebar"></div>
  </div>
</template>

<script>
import ProfileForm from '@/controls/ProfileForm'
import TrackerProfileForm from '@/controls/TrackerProfileForm'
import SprintForm from '@/controls/profileReadOnly/SprintForm'
import CreateHypothesisForm from '@/controls/profileReadOnly/CreateHypothesisForm'
import CloseHypothesisForm from '@/controls/profileReadOnly/CloseHypothesisFormReadOnly'
import CloseIcon from '@/icons/CloseIcon'

export default {
  name: 'SidebarReadOnly',
  components: {
    ProfileForm,
    TrackerProfileForm,
    SprintForm,
    CreateHypothesisForm,
    CloseHypothesisForm,
    CloseIcon
  },
  props: {
    component: {
      type: String,
      required: true,
    },
    params: {
      type: Object,
      default() {
        return {}
      }
    },
    classes: {
      type: Array,
      default() {
        return []
      }
    }
  },
  methods: {
    closeSidebar() {
      this.$emit('closeSidebar')
    }
  }
}
</script>

<style lang="scss">
.sidebar {
  display: grid;
  grid-template-columns: 600px;
  justify-content: flex-start;
  position: fixed;
  z-index: $modalZindex;
  right: 0;
  top: 0;
  width: 100%;
  height: 100vh;

  &__hypothesis {
    grid-template-columns: 75vw;

    @media (max-width: 1024px) {
      grid-template-columns: 90vw;
    }
    .toolbar-menu {
      @media (min-width: $medium) {
        grid-template-areas:
          "hypothesis comments"
          "control comments";
        grid-template-columns: 1.25fr 1fr;
        grid-template-rows: 10fr 1fr;
      }
    }
  }

  &__back {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  &__body {
    height: 100%;
    overflow: hidden;
    z-index: 2;
    animation: showBody .2s;
    background-color: #21222d;
    width: 100vw;
  }

  &__back {
    width: 100%;
    height: 100%;
    background: rgba(0, 2, 12, .65);
    z-index: 1;
    animation: showBack .2s;
  }

  &__close {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: $popupZindex+1;
  }

  .toolbar-menu {
    animation: showBody .2s;
    &__scroll {
      padding: 15px 15px 0;
    }

    &__block {
      width: 100%;
    }

    &__control {
      padding: 20px;
    }
  }

  @keyframes showBack {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes showBody {
    from {
      transform: translateX(100%);
    }

    to {
      transform: translateX(0%);
    }
  }
}
@media screen and (min-width: $medium) and (max-width: $large - 2) {
  .sidebar {
    justify-content: flex-end;

    &__body {
      width: auto;
    }

    &__close {
      display: none;
    }

    .toolbar-menu__scroll {
      padding: 50px 40px 0;
    }

    .toolbar-menu__block {
      width: 210px;
    }

    .toolbar-menu__row {
      width: 100%;
    }

    .toolbar-menu__control {
      padding: 20px 80px;
    }
  }
}

@media screen and (min-width: $large) {
  .sidebar {
    justify-content: flex-end;

    &__body {
      width: auto;
    }

    &__close {
      display: none;
    }

    .toolbar-menu__scroll {
      padding: 50px 40px 0;
      max-width: 560px;
    }

    .toolbar-menu__block {
      width: 210px;
    }

    .toolbar-menu__row {
      width: 100%;
    }

    .toolbar-menu__control {
      padding: 20px 80px;
    }
  }
}
</style>
