
export default {
  mainDomain: process.env.MAIN_DOMAIN,
  // api: 'https://api-games.toolbox.bz',
  // authApi: 'https://auth.toolbox.bz',
  api: 'https://api-games.toolbox.bz',
  lms: 'https://lms.toolbox.bz',
  authApi: 'https://auth.toolbox.bz',
  timezoneOffset: +3, // Moscow
}
