import config from '@/config'
import net from '@/net/net'

export default class Auth {
  constructor(gameId) {
    this.gameId = gameId
  }

  login({ email, password }, fingerprint) {
    // logger.warn('TODO Auth.login user:', user)
    // axios.get(`${config.lms}`)
    // return axios.post('https://auth.toolbox.bz/auth/login', user)
    return net.post(`${config.authApi}/auth/login?platform=game`, {
      email,
      password,
      fingerprint: fingerprint
    })
  }

  refresh(refreshToken, fingerprint) {
    return net.post(`${config.authApi}/auth/refresh`, {
      refreshToken,
      fingerprint: fingerprint
    })
  }

  register({ email, plainPassword, repeatPassword }, fingerprint) {
    // https://auth.pgtb.ru/auth/register?platform=game
    // return axios.post('https://api-games.toolbox.bz/auth/register', user)
    return net.post(`${config.authApi}/auth/register?platform=game&game=${this.gameId}`, {
      email,
      plainPassword,
      repeatPassword,
      agreeTerms: true,
      fingerprint: fingerprint
    })
  }

  requestPasswordReset(email) {
    return net.post(`${config.authApi}/auth/requestPasswordReset?platform=game`, {
      user: { email }
    })
  }

  resetPassword(password, passwordRepeat, hash, fingerprint) {
    return net.post(`${config.authApi}/auth/resetPassword/${hash}`, {
      user: {
        newPassword1: password,
        newPassword2: passwordRepeat
      },
      fingerprint: fingerprint
    })
  }
}
