import Auth from './auth'
import Game from './game'

function getGameId() {
  const value = localStorage.getItem('game_id')
  return value ? parseInt(value) : 4
}

const GAME_ID = getGameId()

export default {
  auth: new Auth(GAME_ID),
  game: new Game(GAME_ID)
}
