<template>
  <div class="profile-gallery">
    <div class="profile-gallery__image" v-for="(image, index) in viewImages" :key="index">
      <div v-if="image.id >= 0" class="profile-gallery__delete-photo" @click="deletePhoto(image.id)"><DeleteIcon/></div>
      <img
        v-if="image.publicPath"
        alt="Фото бизнеса"
        :src="image.publicPath"
        class="profile-gallery__image-view"
        @click="openPopup(image)"
      />
      <template v-else>
        <PhotoIcon />
        <div v-if="index === 0" class="profile-gallery__image-prompt">Добавь фото своего бизнеса</div>
      </template>
      <div class="profile-gallery__button" v-if="index === 0 && !galleryIsFull" @click="openInput">
        <input type="file" ref="photo" hidden @change="addPhoto($event.target.files)"/>
        <AddPhotoIcon/>
      </div>
    </div>
    <photo-popup v-if="selectedPhoto" :photo="selectedPhoto.publicPath" @close="closePopup"/>
  </div>
</template>

<script>
import PhotoIcon from '@/icons/PhotoIcon'
import AddPhotoIcon from '@/icons/AddPhotoIcon'
import DeleteIcon from '@/icons/DeleteIcon'
import PhotoPopup from '@/controls/PhotoPopup'

export default {
  name: 'ProfileGallery',
  props: {
    images: {
      required: true,
      type: Array
    }
  },
  components: {
    PhotoIcon,
    AddPhotoIcon,
    DeleteIcon,
    PhotoPopup
  },
  data() {
    return {
      VIEW_IMAGE_COUNT: 3,
      selectedPhoto: undefined
    }
  },
  computed: {
    viewImages() {
      return [...this.images.slice(0, this.VIEW_IMAGE_COUNT)]
    },
    galleryIsFull() {
      let count = 0
      this.viewImages.forEach(element => {
        if (element.publicPath) ++count
      })
      return count >= this.VIEW_IMAGE_COUNT;
    }
  },
  methods: {
    addPhoto(files) {
      this.$emit('addPhoto', files[0])
    },
    openInput() {
      this.$refs['photo'][0].click()
    },
    deletePhoto(id) {
      this.$emit('deletePhoto', id)
    },
    openPopup(photo) {
      this.selectedPhoto = photo
    },
    closePopup() {
      this.selectedPhoto = undefined
    }
  }

}
</script>

<style lang="scss">
.profile-gallery{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 180px 1fr;
  gap: 10px;
  padding: 20px;
  @extend %card-gradient;

  &__delete-photo{
    opacity: 0;
    transition: .3s;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 10px;
    top: 10px;
    width: 18px;
    height: 18px;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .4);
  }

  &__image{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 8px;
    background-color: var(--nav-bg-color);
    overflow: hidden;

    &-prompt{
      max-width: 121px;
      margin-top: 17px;
      font-size: var(--edit-font-size);
      line-height: var(--edit-line-height);
      letter-spacing: var(--edit-letter-spacing);
      color: var(--nav-item-color);

    }

    &:nth-child(1) {
      grid-column: span 2;
    }

    &-view{
      object-fit: cover;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }

    &:hover{
      .profile-gallery__delete-photo{
        opacity: 1
      }
    }
  }

  &__button{
    position: absolute;
    right: 14px;
    bottom: 14px;
    cursor: pointer;
  }
}
</style>
