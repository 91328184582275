<template>
  <div class="dashboard">
    <Timer
        v-if="showTimer"
        :waiting="waiters.num"
        :can-click="!waiters.isChecked"
        :ddl="ddl"
        @wait-clicked="onWaitClicked"
        @ddl-reached="onDdlReached"
    />
    <template>
      <div v-if="top" class="total-stats">
        <div class="dashboard-block dashboard-bg">
          <CalendarIcon class="dashboard-block__icon"/>
          <div class="dashboard-block__info">
            <div class="dashboard-block__title">День:</div>
            <div class="dashboard-block__value">{{ top.day }} / {{ top.totalDays }}</div>
          </div>
        </div>
        <div class="dashboard-block dashboard-bg">
          <FinishIcon class="dashboard-block__icon"/>
          <div class="dashboard-block__info">
            <div class="dashboard-block__title">Участников:</div>
            <div class="dashboard-block__value">
              {{ top.activeUsers.participantCount }} / {{ top.totalUsers.participantCount }}
            </div>
          </div>
        </div>
        <div class="dashboard-block dashboard-bg">
          <MoneyHandIcon class="dashboard-block__icon"/>
          <div class="dashboard-block__info">
            <div class="dashboard-block__title">Общая выручка участников:</div>
            <div class="dashboard-block__value">{{ top.totalRevenue || 0 | numberFormat }} ₽</div>
          </div>
        </div>
        <div class="dashboard-block dashboard-bg">
          <MoneyIcon class="dashboard-block__icon"/>
          <div class="dashboard-block__info">
            <div class="dashboard-block__title">Общая прибыль участников:</div>
            <div class="dashboard-block__value">{{ top.totalProfit || 0 | numberFormat }} ₽</div>
          </div>
        </div>
      </div>
      <div v-if="participantTop.length" class="first-winners">
        <TopPlayer
            v-for="(participant, index) in participantTop"
            :key="participant.user.id"
            :avatar="participant.user.userInfo.avatar"
            :name="username(participant)"
            :niche="participant.user.userInfo.business.niche"
            :place="index + 1"
            :proceeds="participant.revenueFact"
            :profit="participant.profitFact"
            :hypotheses="participant.hypothesesCount"
            @player-click="onPlayerClick(participant.user.id)"
        />
      </div>
      <div v-else class="first-winners">
        <TopPlayer
            v-for="(participant, index) in mocks.players"
            :key="participant.id"
            :avatar="participant.avatar"
            :name="participant.name"
            :place="index + 1"
            :proceeds="participant.revenue"
            :profit="participant.profit"
            :hypotheses="participant.hypothesesCount"
        />
      </div>
      <div class="dashboard-title">ТОП гипотез</div>
      <div v-if="hypothesisTop.length" class="top-hypotheses">
        <Hypothese
            v-for="hypothesis in hypothesisTop"
            :key="hypothesis.id"
            class="dashboard-bg"
            :name="hypothesis.user.userInfo.name + ' ' + hypothesis.user.userInfo.lastname"
            :words="[{ id: 1, name: hypothesis.businessCategory, ...getBusinessCategoryColor(hypothesis.businessCategory) },
        { id: 2, name: hypothesis.category, ...getCategoryColor(hypothesis.category) }]"
            :avatar="hypothesis.user.userInfo.avatar"
            :date="hypothesis.ddl"
            :text="hypothesis.text"
            :comments="hypothesis.hypothesisCommentCount"
            :likes="hypothesis.likes"
            :dislikes="0"
        />
      </div>
      <div v-else class="top-hypotheses__mock">
        <lamp-icon />
        <h4 class="top-hypotheses__mock-title">Скоро тут появятся лучшие гипотезы!</h4>
        <h3 class="top-hypotheses__mock-description">Мы покажем гипотезы, которые принесли больше всего прибыли.</h3>
      </div>
    </template>
  </div>
</template>

<script>
import Timer from '@/controls/Timer'
import TopPlayer from '@/controls/TopPlayer'
import Hypothese from '@/controls/Hypothese'
import CalendarIcon from '@/icons/CalendarIcon'
import LampIcon from '@/icons/LampIcon'
import FinishIcon from '@/icons/FinishIcon'
import MoneyHandIcon from '@/icons/MoneyHandIcon'
import MoneyIcon from '@/icons/MoneyIcon'
import model from '@/model'
import controller from '@/controller'
import numberFormat from '@/utils/numberFormat'
import getCategoryColor from '@/utils/getCategoryColor'
import getBusinessCategoryColor from '@/utils/getBusinessCategoryColor'
import { tzParseDate } from '@/utils/time'

export default {
  name: 'Dashboard',
  filters: {
    numberFormat
  },
  components: {
    Timer,
    TopPlayer,
    Hypothese,
    CalendarIcon,
    FinishIcon,
    LampIcon,
    MoneyHandIcon,
    MoneyIcon,
  },
  data() {
    return {
      ddl: tzParseDate('2021-10-29T09:00:00Z'),
      mocks: {
        players: [
          {
            id: 3,
            name: 'Здесь можешь быть ты',
            revenue: 1000,
            profit: 500,
            hypothesesCount: 2
          },
          {
            id: 2,
            name: 'Здесь можешь быть ты',
            revenue: 500,
            profit: 0,
            hypothesesCount: 0
          },
          {
            id: 1,
            name: 'Здесь можешь быть ты',
            revenue: 0,
            profit: 0,
            hypothesesCount: 0
          },
        ]
      }
    }
  },
  computed: {
    top: () => model.game.dashboardData?.top,
    participantTop: () => model.game.dashboardData?.participantTop,
    hypothesisTop: () => model.game.dashboardData?.hypothesisTop,
    waiters: () => model.game.waiters,
    showTimer: {
      get() {
        return model.auth.showTimer
      },
      set(val) {
        model.auth.showTimer = val
      }
    }
  },
  created() {
    if (this.showTimer) {
      controller.game.loadWaiters()
    } else {
      controller.game.loadDashboard()
    }
  },
  methods: {
    getCategoryColor: getCategoryColor,
    getBusinessCategoryColor: getBusinessCategoryColor,
    username(participant) {
      return participant.user.userInfo.name ? participant.user.userInfo.name + ' ' + participant.user.userInfo.lastname : 'Аноним'
    },
    onPlayerClick(id) {
      this.$router.push(`/profile/${id}`)
    },
    onWaitClicked() {
      controller.game.pushWaiter()
    },
    onDdlReached() {
      this.showTimer = false
      controller.game.loadDashboard()
    }
  }
}
</script>

<style lang="scss">
/* @use '/src/style/vars' as *; */

.dashboard {
  @media (max-width: $medium) {
    padding: 0 20px;
  }
}

.dashboard-bg {
  background: linear-gradient(112.32deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0) 101.12%), linear-gradient(112.83deg, rgba(179, 163, 244, 0.02) 0%, rgba(255, 255, 255, 0) 110.84%);
  background-color: #21222D;
  border-radius: 14px;
}

.total-stats, .first-winners, .top-hypotheses {
  display: grid;
  grid-gap: 20px;
}

.total-stats {
  grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
  grid-auto-rows: 1fr;
  margin-bottom: 96px;

  @media (min-width: 1240px) {
    grid-template-columns: 1fr 1fr 400px 400px;
  }

  @media (max-width: 896px) {
    grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  }
}

.dashboard-block {
  display: flex;
  align-items: center;
  padding: 19px 16px;
  text-align: left;

  &__icon {
    margin-right: 16px;
  }

  &__info {
    display: flex;
    flex-direction: column;
  }

  &__title {
    font-size: var(--edit-font-size);
    line-height: var(--edit-line-height);
    letter-spacing: var(--edit-letter-spacing);
    color: #546FCE;
    margin-bottom: 4px;
  }

  &__value {
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    color: #ABBBF2;
    white-space: pre;
  }
}

.top-hypotheses {
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  & > div {
    min-width: 300px;
  }

  &__mock {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px;
    background: #21222D;
    border-radius: 24px;
  }

  &__mock-title {
    max-width: 40ch;
    font-size: 16px;
    font-weight: normal;
    color: var(--edit-color);
    margin-top: 25px;
    margin-bottom: 13px;
  }

  &__mock-description {
    max-width: 40ch;
    font-size: 14px;
    font-weight: normal;
    margin: 0;
    color: var(--edit-color-normal);
  }
}

.first-winners {
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  grid-auto-rows: 300px;
  margin-bottom: 30px;
  row-gap: 96px;

  & > div {
    min-width: 270px;
  }

  @media (min-width: 1240px) {
    & > div:nth-child(2) {
      order: 1
    }
    & > div:nth-child(1) {
      order: 2
    }
    & > div:nth-child(3) {
      order: 3
    }
  }

  @media (min-width: 589px) and (max-width: 1240px) {
    & > div:first-child {
      grid-column: span 2;
    }
  }
}

.dashboard-title {
  font-weight: bold;
  text-align: left;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: var(--edit-letter-spacing);
  color: #ABBBF2;
  margin-bottom: 20px;
}
</style>
