<template>
  <div class="pass-edit">
    <input class="pass-edit__value" :type="hide ? 'password' : 'text'" @input="setValue" :placeholder="placeholder">
    <div :class="[{ 'hidden': hide }, 'pass-edit__set-hide']" @click="setHide">
      <EyeIcon class="pass-edit__icon"/>
    </div>
  </div>
</template>

<script>
import EyeIcon from '@/icons/EyeIcon'
export default {
  name: 'PassEdit',
  components: {
    EyeIcon
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    placeholder: {
      default: 'Введите текст...',
      type: String
    },
  },
  data() {
    return {
      hide: true,
    }
  },
  methods: {
    setValue(event) {
      this.$emit('input', event.target.value)
    },
    setHide() {
      this.hide = !this.hide
    }
  }
}
</script>

<style lang="scss">
.pass-edit {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  font-size: var(--edit-font-size);
  line-height: var(--edit-line-height);

  &__value {
    outline: none;
    border: 1px solid transparent;
    text-align: left;
    width: 100%;
    padding: 14px 10px;
    border-radius: 8px;
    background: #181922;
    color: var(--edit-color);

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    &:focus, &.editing {
      border-color: var(--edit-form-border-color);
    }

    &::placeholder {
      color: #ABBBF2;
    }
  }

  &__set-hide {
    cursor: pointer;
    position: absolute;
    right: 14px;

    &.hidden {
      opacity: .5;
    }
  }

  &__icon {
    path {
      fill: #546FCE;
    }
  }

}
</style>
