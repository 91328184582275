import axios from 'axios'
// import { getCookieMulti } from '@/utils/cookie'
import { getCookie } from '@/utils/cookie'
import controller from '@/controller'
// import logger from '@/logger'

const ACCESS_TOKEN = 'accessToken'
const REFRESH_TOKEN = 'refreshToken'

const origin = `${window.location.protocol}//${document.domain}${window.location.port ? ':' : ''}${window.location.port}`

let isRefreshing = false
let failedQueue = []

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error)
    } else {
      prom.resolve(token)
    }
  })

  failedQueue = [];
}

const net = axios.create({
  // withCredentials: true,
  headers: {
    'Access-Control-Allow-Origin': origin,
    // 'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS',
    'Access-Control-Allow-Headers': 'Content-Type'
    // 'Access-Control-Allow-Headers': 'Authorization, Origin, Content-Type, X-Requested-With',
    // 'Access-Control-Allow-Credentials': 'true'
  }
})

net.interceptors.request.use( config => {
  const accessToken = getCookie(ACCESS_TOKEN)
  if (accessToken) {
    config.headers.Authorization = 'Bearer ' + accessToken
  }
  return config
})

net.interceptors.response.use(function(response) { return response },
  function(error) {
  console.log(error)
    const originalRequest = error.config;

    if (error.response.data.errors && error.response.data.errors.length) {
      error.response.data.errors.forEach(({ message }) =>
        controller.app.addNotification({text: message || error.toString() }, 5000))
    }

    if (error.response.status === 401 && !originalRequest._retry) {

      if (isRefreshing) {
        return new Promise(function(resolve, reject) {
          failedQueue.push({resolve, reject})
        }).then(token => {
          originalRequest.headers['Authorization'] = 'Bearer ' + token;
          return axios(originalRequest);
        }).catch(err => {
          return Promise.reject(err);
        })
      }

      originalRequest._retry = true;
      isRefreshing = true;

      const refreshToken = getCookie(REFRESH_TOKEN);
      return new Promise(function (resolve, reject) {
        controller.auth.refresh(refreshToken)
          .then(({data}) => {
            net.defaults.headers.common['Authorization'] = 'Bearer ' + data.accessToken
            originalRequest.headers['Authorization'] = 'Bearer ' + data.accessToken
            processQueue(null, data.accessToken)
            resolve(net.request(originalRequest))
          })
          .catch(err => {
            processQueue(err, null)
            reject(err)
          })
          .finally(() => isRefreshing = false)
      })
    }

    return Promise.reject(error);
  }
)

export default net
