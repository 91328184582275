<template>
  <div class="checkbox">
      <input class="checkbox__value" type="checkbox" :checked="value" :readonly="readOnly" @input="setValue">
      <label><CheckboxIcon class="checkbox__value-icon" /></label>
      <div v-if="readOnly" class="checkbox__locked"></div>
  </div>
</template>

<script>
import CheckboxIcon from '@/icons/CheckboxIcon'

export default {
  name: 'Checkbox',
  components: {
    CheckboxIcon
  },
  props: {
    readOnly: {
      type: Boolean,
      default: false
    },
    value: {
      type: Boolean,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    setValue(event) {
      this.$emit('input', event.target.checked)
    }
  }
}
</script>

<style lang="scss">
.checkbox { 
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  &__locked {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 200;
    left: 0;
    top: 0;
  }

  &__value {
    opacity: 0;

    &-icon{
      position: absolute;
      z-index: 2;
      opacity: 0;
      margin: 1px 0 0 1px;
    }

    & + label {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      pointer-events: none;
      opacity: 1;

      &:before, &:after {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
      }

      &:before {
        border-radius: 4px;
        background-color: var(--edit-checkbox-color);
        border: 1px solid var(--edit-checkbox-border-color);
      }

      &:after {
        background-color: var(--edit-checkbox-border-color);
        opacity: 0;
      }
    }
    &:checked {
      & + label {
        &:after, .checkbox__value-icon {
          opacity: 1;
        }
      }
    }
  }
}
</style>
