<template>
  <div :class="[{ 'active': active }, 'profile-day']">
    <template v-if="loading">
      <div class="profile-day__header">
        <div class="profile-day__number">День {{ day.day }}</div>
        <div class="profile-day__date"><div class="profile-day__plug"></div></div>
        <div class="profile-day__lamp"></div>
      </div>
      <div class="profile-day__profit-desk">
        <div class="profile-day__profit-block">
          <div class="profile-day__profit-block-title"><div class="profile-day__plug"></div></div>
          <div class="profile-day__plug"></div>
          <div class="profile-day__plug profile-day__plug-md"></div>
        </div>
        <div class="profile-day__profit-block">
          <div class="profile-day__profit-block-title"><div class="profile-day__plug"></div></div>
          <div class="profile-day__plug"></div>
          <div class="profile-day__plug profile-day__plug-md"></div>
        </div>
        <div class="profile-day__profit-block">
          <div class="profile-day__profit-block-title"><div class="profile-day__plug"></div></div>
          <div class="profile-day__plug"></div>
          <div class="profile-day__plug profile-day__plug-md"></div>
        </div>
        <div class="profile-day__profit-block">
          <div class="profile-day__profit-block-title"><div class="profile-day__plug"></div></div>
          <div class="profile-day__plug"></div>
          <div class="profile-day__plug profile-day__plug-md"></div>
        </div>
      </div>
      <div class="profile-day__hypotheses">
        <div class="profile-day__hypotheses-add"><PlusIcon /></div>
        <div class="profile-day__hypotheses-title">Гипотезы</div>
        <div class="profile-day__wrap">
          <div class="profile-day__plug profile-day__plug-sm"></div>
          <div class="profile-day__plug profile-day__plug-md"></div>
          <div class="profile-day__plug profile-day__plug-sm"></div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="profile-day__header">
        <div class="profile-day__number">День {{ day.day }}</div>
        <div v-show="dayMonth" class="profile-day__date">{{ dayMonth }}</div>
        <div :class="[{ 'positively': !locked }, 'profile-day__lamp']"></div>
      </div>
      <!-- <template v-if="!day.availableFrom || !week.id"> -->
      <template v-if="forceLock && day.id === null">
        <LockIcon class="profile-day__locked-icon"/>
        <div class="profile-day__prompt">Данные не внесены</div>
      </template>
      <template v-else>
        <div class="profile-day__profit-desk">
          <div class="profile-day__profit-block">
            <div class="profile-day__profit-block-title">Выручка план</div>
            <MoneyEdit :readonly=true :value="revenueTarget" :confirm="true" @input="setRevenueTarget"/>
          </div>
          <div class="profile-day__profit-block">
            <div class="profile-day__profit-block-title">Выручка факт</div>
            <MoneyEdit :readonly=true :value="revenueFact" :confirm="true" @input="setRevenueFact"/>
          </div>
          <div class="profile-day__profit-block">
            <div class="profile-day__profit-block-title">Прибыль план</div>
            <MoneyEdit :readonly=true :value="profitTarget" :confirm="true" @input="setProfitTarget"/>
          </div>
          <div class="profile-day__profit-block">
            <div class="profile-day__profit-block-title">Прибыль факт</div>
            <MoneyEdit :readonly=true :value="profitFact" :confirm="true" @input="setProfitFact" />
          </div>
        </div>
        <div class="profile-day__hypotheses">
          <div class="profile-day__hypotheses-title">Гипотезы</div>
         <div v-if="day.hypotheses.length" class="profile-day__hypotheses">
            <Hypothesis v-for="hypothesis in day.hypotheses" :key="hypothesis.id" class="profile-day__hypothesis"
              :hypothesis="hypothesis" @click="$emit('closeHypothesis', hypothesis)" />
          </div>
          <div v-else class="profile-day__hypotheses-empty">
            <template v-if="day.id">
              <span>Участник еще не ставил</span>
              <span>гипотезу на этот день</span>
            </template>
            <template v-else>
              <span>План на день еще не заполнен</span>
            </template>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import MoneyEdit from '@/controls/MoneyEdit'
import PlusIcon from '@/icons/PlusIcon'
import LockIcon from '@/icons/LockIcon'
import Hypothesis from '@/controls/Hypothesis'

export default {
  name: 'ProfileDayReadOnly',
  components: {
    MoneyEdit,
    PlusIcon,
    LockIcon,
    Hypothesis
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    day: {
      type: Object,
      required: true
    },
    week: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      hypothesisPlaceholder: 'Ты еще не написал гипотезу на этот день',
      showSidebar: true,
      forceLock: true
    }
  },
  computed: {
    revenueTarget() {
      return this.day.revenueTarget || 0
    },
    revenueFact() {
      return this.day.revenueFact || 0
    },
    profitTarget() {
      return this.day.profitTarget || 0
    },
    profitFact() {
      return this.day.profitFact || 0
    },
    active() {
      return this.day.status === 'current'
    },
    locked() {
      return this.day.status === 'future' || !this.day.availableFrom
    },
    dayMonth() {
      return this.day.availableFrom?.toLocaleString('ru', { month: 'long', day: 'numeric' }) || ''
    }
  },
  methods: {
    composeChanges() {
      return {
        revenueTarget: this.revenueTarget,
        revenueFact: this.revenueFact,
        profitTarget: this.profitTarget,
        profitFact: this.profitFact
      }
    },
    setRevenueTarget(value) {
      const changes = { ...this.composeChanges(), revenueTarget: value }
      this.$emit('changeDay', { day: this.day, changes })
    },
    setRevenueFact(value) {
      const changes = { ...this.composeChanges(), revenueFact: value }
      this.$emit('changeDay', { day: this.day, changes })
    },
    setProfitTarget(value) {
      const changes = { ...this.composeChanges(), profitTarget: value }
      this.$emit('changeDay', { day: this.day, changes })
    },
    setProfitFact(value) {
      const changes = { ...this.composeChanges(), profitFact: value }
      this.$emit('changeDay', { day: this.day, changes })
    }
  }
}
</script>

<style lang="scss">
.profile-day {
  display: flex;
  flex-direction: column;
  letter-spacing: var(--edit-letter-spacing);
  padding: 20px;
  $border: 1px;
  border: $border solid transparent;
  user-select: none;

  &__plug {
    height: 14px;
    width: 100%;
    min-width: 70px;
    background: #546FCE;
    border-radius: 4px;
    margin-bottom: 6px;

    &:last-child {
      margin-bottom: 0;
    }

    &-md {
      width: 80%;
    }

    &-sm {
      width: 50%;
    }
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #171821;
    border-radius: 12px;
    height: 100px;
  }

  &.active {
    border-color: #546FCE;

    .profile-day__number {
      color: #ABBBF2;
    }

    .profile-day__date {
      color: #546FCE;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  &__locked-icon {
    margin: 72px 0 30px;
  }

  &__prompt {
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    margin-bottom: 80px;
    color: #ABBBF2;
  }

  &__number {
    flex: 1;
    text-align: left;
    font-size: 24px;
    line-height: 28px;
    color: #ABBBF2;;
  }

  &__date {
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #546FCE;
    padding: 7px 10px;
    background: #181922;
    border-radius: 8px;
    margin-right: 10px;
    min-width: fit-content;
  }

  &__lamp {
    @extend %lamp;
  }

  &__profit-desk {
    display: flex;
    flex-wrap: wrap;
    gap: 14px 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #546FCE;
    margin-bottom: 20px;
  }

  &__profit-block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: calc(170px - 2*#{$border});

    &-title {
      font-size: 12px;
      line-height: 16px;
      color: #546FCE;
      margin-bottom: 5px;
    }
  }

  &__hypotheses {
    display: flex;
    flex-direction: column;
    position: relative;

    &-title {
      text-align: left;border-radius: 8px;
      color: #546FCE;
      font-size: 14px;
      line-height: 18px;
    }

    &-add {
      cursor: pointer;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  &__hypothese-empty {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100px;
    padding: 0 86px;
    border: none;
    border-radius: 12px;
    background-color: var(--edit-bg-color);
    color: #546FCE;
    font-size: var(--edit-font-size);
    line-height: var(--edit-line-height);
    letter-spacing: var(--edit-letter-spacing);
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
