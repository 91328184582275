import Vue from 'vue'
import VueRouter from 'vue-router'
import { getCookie, setCookieSecure } from '@/utils/cookie'
import controller from '@/controller'
import Dashboard from '@/pages/Dashboard.vue'
import Rating from '@/pages/Rating.vue'
import ProfileWrap from '@/pages/ProfileWrap.vue'
import ProfileWrapReadOnly from '@/pages/ProfileWrapReadOnly.vue'
import TestControls from '@/pages/TestControls'
import Login from '@/pages/auth/Login'
import Logout from '@/pages/auth/Logout'
import Register from '@/pages/auth/Register'
import RequestReset from '@/pages/auth/RequestReset'
import ResetPassword from '@/pages/auth/ResetPassword'
import AuthLayout from '@/layouts/AuthLayout'
import MainLayout from '@/layouts/MainLayout'
import { GAME_ROLE_PARTICIPANT } from '@/model/schema/game'

Vue.use(VueRouter)

const ACCESS_TOKEN = 'accessToken'
const REFRESH_TOKEN = 'refreshToken'

const routes = [
  {
    path: '/',
    name: 'dashboard',
    meta: { needsAuth: true, layout: MainLayout },
    component: Dashboard
  },
  {
    path: '/rating',
    name: 'rating',
    meta: { needsAuth: true, layout: MainLayout },
    component: Rating
  },
  {
    path: '/profile',
    name: 'profile',
    meta: { needsAuth: true, layout: MainLayout },
    component: ProfileWrap
  },
  {
    path: '/profile/:id',
    name: 'profileId',
    meta: { needsAuth: true, layout: MainLayout },
    component: ProfileWrapReadOnly
  },
  {
    path: '/test',
    name: 'test',
    meta: { needsAuth: true, layout: MainLayout },
    component: TestControls
  },
  {
    path: '/login',
    name: 'login',
    meta: { needsAuth: false, layout: AuthLayout },
    component: Login
  },
  {
    path: '/logout',
    name: 'logout',
    meta: { needsAuth: false },
    component: Logout
  },
  {
    path: '/register',
    name: 'register',
    meta: { needsAuth: false, layout: AuthLayout },
    component: Register
  },
  {
    path: '/request-reset',
    name: 'requestReset',
    meta: { needsAuth: false, layout: AuthLayout },
    component: RequestReset
  },
  {
    path: '/resetpassword/:hash',
    name: 'resetPassword',
    meta: { needsAuth: false, layout: AuthLayout },
    component: ResetPassword,
    props: true
  },
  {
    path: '*',
    redirect: '/'
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const accessToken = getCookie(ACCESS_TOKEN)
  const refreshToken = getCookie(REFRESH_TOKEN)
  const needsAuth = to.meta.needsAuth

  if (to.name === from.name) {
    next(false)
  } else if (accessToken && !needsAuth && to.name !== 'logout') {
    next('/profile')
  } else if (!needsAuth) {
    next()
  } else if (accessToken) {
    if ((!controller.game.model.authUser?.id || !from.name) && needsAuth) {
      controller.game.init().then(() => {
        if (controller.auth.model.showTimer && to.path !== '/') {
          next('/')
        } else if (
          controller.game.model.gameRole === GAME_ROLE_PARTICIPANT &&
          !(controller.game.model.currentWeek.userProfitTarget >= 0) &&
          !(controller.game.model.currentWeek.userRevenueTarget >=0 ) &&
          to.name !== 'profile'
        ) {
          next('/profile')
        } else {
          next()
        }
      })
    } else {
      next()
    }
  } else if (!accessToken && refreshToken && needsAuth) {
    controller.auth.refresh(refreshToken).then(() => {
      controller.game.init().then(() => {
        if (controller.auth.model.showTimer && to.path !== '/') {
          next('/')
        } else {
          next()
        }
      })
    })
  } else {
    if (needsAuth) {
      setCookieSecure('pathUserTriedToEnter', to.path, 1200)
    }
    controller.auth.logout()
  }
})

export default router
