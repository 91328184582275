<template>
  <div class="test-step">
    <Checkbox v-if="isDone !== undefined" class="test-step__checkbox" :value="isDone" @input="(value) => $emit('setChecked', value)"/>
    <input :readonly="readOnly" class="test-step__edit" type="text" :value="text" placeholder="Укажи шаг тестирования..." @input="setText"/>
    <div v-if="!readOnly" class="test-step__delete"><DeleteIcon/></div>
    <div v-if="readOnly" class="test-step__locked"></div>
    <div class="test-step__drag"><MoveIcon/></div>
  </div>
</template>

<script>
import Checkbox from '@/controls/Checkbox'
import DeleteIcon from '@/icons/DeleteIcon'
import MoveIcon from '@/icons/MoveIcon'
export default {
  name: 'TestStep',
  components: {
    Checkbox,
    DeleteIcon,
    MoveIcon,
  },
  props: {
    isDone: {
      type: Boolean,
      default: undefined,
    },
    text: {
      type: String,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    setText(event) {
      this.$emit('setText', event.target.value)
    }
  }
}
</script>

<style lang="scss">
.test-step {
  display: flex;
  align-items: center;
  padding: 0 14px;
  height: 50px;
  background: #171821;
  border-radius: 8px;
  margin-bottom: 10px;
  position: relative;
  
  &:last-child {
    margin-bottom: 0;
  }

  &__locked {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 200;
    left: 0;
    top: 0;
  }

  &__checkbox, &__delete, &__drag {
    min-width: 16px;
  }

  &__checkbox {
    margin-right: 15px;
  }

  &__edit, &__delete {
    margin-right: 10px;
  }

  &__delete, &__drag {
    cursor: pointer;
  }

  &__edit {
    outline: none;
    border: none;
    text-align: left;
    width: 100%;
    height: 100%;
    background-color: transparent;
    color: var(--edit-color);

     &::placeholder {
      color: var(--edit-form-placeholder);
    } 
  }

  &__delete {
    min-width: 16px;
    width: 16px;
    height: 16px;
    
    path {
      fill: #546FCE;
    }
  }
}
</style>
