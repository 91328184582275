<template>
  <div class="notification">
    <ErrorIcon class="notification__icon" />
    <div class="notification__text">{{ text }}</div>
  </div>
</template>

<script>
import ErrorIcon from '@/icons/ErrorIcon'

export default {
  name: 'Notification',
  components: {
    ErrorIcon,
  },
  props: {
    text: {
      type: String,
      required: true,
    }
  }
}
</script>

<style lang="scss">
.notification {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 6px;
  width: 260px;
  height: 60px;
  padding: 0 20px;
  border-radius: 12px;
  background: #242732;
  box-shadow: 20.7171px 36.9949px 66.5908px rgba(23, 18, 43, 0.45);
  backdrop-filter: blur(19.5311px);
  font-family: Helvetica, Avenir, Arial, sans-serif;

  &__icon {
    margin-right: 20px;
  }

  &__text {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.3px;
    color: var(--nav-item-active-color);
  }
}
</style>
