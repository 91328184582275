import {
  dashboardSchema,
  profileSchema,
  daySchema,
  sprintSchema,
  hypothesisSchema,
  hypothesisCommentsSchema,
  DAY_STATUS_CURRENT,
  DAY_STATUS_FUTURE,
  DAY_STATUS_PAST,
  GAME_ROLE_PARTICIPANT,
  GAME_ROLE_TRACKER,
  GAME_ROLE_WATCHER,
} from './game'

export default {
  dashboard: dashboardSchema,
  profile: profileSchema,
  day: daySchema,
  sprint: sprintSchema,
  hypothesis: hypothesisSchema,
  comment: hypothesisCommentsSchema,
  DAY_STATUS_CURRENT,
  DAY_STATUS_FUTURE,
  DAY_STATUS_PAST,
  GAME_ROLE_PARTICIPANT,
  GAME_ROLE_TRACKER,
  GAME_ROLE_WATCHER,
}
