<template>
  <div class="comment">
    <Avatar class="comment__avatar" :avatar="avatar"/>
    <div class="comment__name">{{ name }}</div>
    <div class="comment__text">{{ text }}</div>
    <div class="comment__footer">
      <div class="comment__footer-row">
        <div class="comment__footer-col">
          <div class="comment__date">{{ commentDate(date) }}</div>
<!--          <div class="comment__answer">Ответить</div>-->
        </div>
        <div class="comment__footer-col">
          <div class="comment__like" :class="{ 'active': liked }" @click="like">
            <LikeIcon/> {{ likes }}
          </div>
          <div class="comment__dislike" :class="{ 'active': disliked }" @click="dislike" >
            <DislikeIcon/> {{ dislikes }}
          </div>
        </div>
      </div>
      <!-- <div class="comment__show-answers">Посмотреть ответы (4)</div> -->
    </div>
<!--    <div class="comment__answers" v-if="answers.length">-->
<!--      <div class="comment__answer-block">-->
<!--        <Avatar class="comment__answer-block-avatar"/>-->
<!--        <div class="comment__name">Сергей Бурунов</div>-->
<!--        <div class="comment__text"><span>Рома,</span> гипотеза интересная, возьму попробовать</div>-->
<!--        <div class="comment__footer">-->
<!--          <div class="comment__footer-row">-->
<!--            <div class="comment__footer-col">-->
<!--              <div class="comment__date">21.09.21 15:45</div>-->
<!--              <div class="comment__answer">Ответить</div>-->
<!--            </div>-->
<!--            <div class="comment__footer-col">-->
<!--              <div class="comment__like"><LikeIcon/> 409</div>-->
<!--              <div class="comment__dislike active"><DislikeIcon/> 409</div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="comment__entry-field">-->
<!--      <Avatar class="comment__entry-field-avatar"/>-->
<!--      <div class="comment__input-wrap">-->
<!--        <input type="text" class="comment__input">-->
<!--        <div class="comment__send-message"><SendIcon/></div>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<script>
import Avatar from '@/controls/Avatar'
import LikeIcon from '@/icons/LikeIcon'
import DislikeIcon from '@/icons/DislikeIcon'
// import SendIcon from '@/icons/SendIcon'
export default {
  name: 'Comment',
  components: {
    Avatar,
    LikeIcon,
    DislikeIcon,
    // SendIcon,
  },
  props: {
    avatar: {
      type: String,
      required: false,
    },
    name: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    likes: {
      type: Number,
      required: true
    },
    liked: {
      type: Boolean,
      default: false
    },
    dislikes: {
      type: Number,
      required: true
    },
    disliked: {
      type: Boolean,
      default: false
    },
    date: {
      type: String,
      required: true
    },
    answers: {
      type: Array,
      required: false,
    }
  },
  data() {
    return {
      showAnswers: false
    }
  },
  methods: {
    like() {
      this.$emit('like')
    },
    dislike() {
      this.$emit('dislike')
    },
    commentDate(dateString) {
      const date = new Date(dateString)
      return date.toLocaleString('ru', {
        month: 'numeric',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
      }).replaceAll(',', '')
    },
  }
}
</script>

<style lang="scss">
.comment {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 16px 15px 15px 50px;
  border-radius: 8px;
  width: 100%;
  background: #21222D;
  text-align: left;
  color: #546FCE;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 8px;

  &__avatar {
    width: 32px;
    height: 32px;
    position: absolute;
    left: 10px;
    top: 15px;
  }

  &__name, &__date, &__answer, &__show-answers {
    font-size: 12px;
  }

  &__name {
    color: #ABBBF2;
    margin-bottom: 5px;
  }

  &__text, &__like, &__dislike {
    letter-spacing: -0.3px;
  }

  &__text {
    margin-bottom: 14px;

    span {
      color: #ABBBF2;
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 14px;
    border-bottom: 1px solid #546FCE;

    &:last-child {
      padding-bottom: 0;
      border: 0;
    }

    &-row {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &-col {
      display: flex;
      margin-right: 4px;
    }
  }

  &__date, &__like {
    margin-right: 5px;
  }

  &__answer, &__like, &__dislike, &__show-answers, &__send-message {
    cursor: pointer;
  }

  &__answer {
    color: #ABBBF2;
  }

  &__like, &__dislike {
    &-icon {
      margin-right: 4px;
    }
  }

  &__like {
    &.active {
      color: #E28E0F;

      path {
        fill: #E28E0F;
      }
    }
  }

  &__dislike {
    &.active {
      color: #61D4FF;

      path {
        fill: #61D4FF;
      }
    }
  }

  &__show-answers {
    display: flex;
    align-items: center;
    position: relative;

    &:before {
      position: absolute;
      content: '';
      left: -10px;
      transform: translateX(-100%);
      width: 32px;
      height: 1px;
      background-color: #546FCE;
      pointer-events: none;
    }
  }

  &__answer-block {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 15px 0 15px 28px;
    border-bottom: 1px solid #546FCE;

    &:last-child {
      border: 0;
    }

    &-avatar {
      position: absolute;
      left: 0;
      top: 14px;
      width: 20px;
      height: 20px;
    }
  }

  &__entry-field {
    display: flex;
    align-items: center;

    &-avatar {
      width: 24px;
      min-width: 24px;
      height: 24px;
      margin-right: 10px;
    }
  }

  &__input-wrap {
    position: relative;
    display: flex;
    width: 100%;
    background: #171821;
    border-radius: 4px;
    height: 30px;
  }

  &__input {
    height: 100%;
    width: 100%;
    outline: none;
    border: none;
    padding: 0 10px;
    background-color: transparent;
    color: #ABBBF2;
  }

  &__send-message {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0 10px;
  }
}
</style>
