<template>
  <div class="profile-card">
    <div class="profile-card__personal">
      <Avatar class="profile-card__avatar" :avatar="avatar"/>
      <div class="profile-card__info">
        <div class="profile-card__info-name">{{ name }}</div>
        <div class="profile-card__info-city">
          <PointIcon class="profile-card__info-city-icon"/>
          <div :class="[{ 'profile-card__biz-section-value--empty': !city }, 'profile-card__info-city-value']">{{ city || 'Не указано' }}</div>
        </div>
      </div>
      <div class="profile-card__open-settings"><StarActiveIcon/></div>
    </div>
    <div class="profile-card__tracker" @click="openTrackerProfile">
      <TrackerIcon class="profile-card__tracker-icon"/>
      <div :class="[{ 'profile-card__tracker-name--empty': !tracker }, 'profile-card__tracker-name']">{{ tracker || 'Не назначен' }}</div>
    </div>
    <div class="profile-card__proff">
      <div class="profile-card__proff__title">{{ 'Сфера: ' }}</div>{{ bizArea || 'Не указано' }}
    </div>
    <LineIcon class="profile-card__line-icon"/>
    <div class="grid-style">
      <div class="profile-card__biz-section">
        <BriefcaseIcon class="profile-card__biz-section-icon" />
        <div class="profile-card__biz-section-info">
          <div class="profile-card__biz-section-title">{{ 'Ниша' }}</div>
          <div
            v-tooltip="bizNiche"
            :class="[{ 'profile-card__biz-section-value--empty': !bizNiche }, 'profile-card__biz-section-value']"
          >
            {{ bizNiche || 'Не указано' }}
          </div>
        </div>
      </div>
      <div class="profile-card__biz-section">
        <ListIcon class="profile-card__biz-section-icon" />
        <div class="profile-card__biz-section-info">
          <div class="profile-card__biz-section-title">{{ 'Название' }}</div>
          <div
            v-tooltip="bizName"
            :class="[{ 'profile-card__biz-section-value--empty': !bizName }, 'profile-card__biz-section-value']"
          >
            {{ bizName || 'Не указано' }}
          </div>
        </div>
      </div>
      <div class="profile-card__biz-section">
        <ListIcon class="profile-card__biz-section-icon" />
        <div class="profile-card__biz-section-info">
          <div class="profile-card__biz-section-title">{{ 'Точка А: выручка' }}</div>
          <div :class="[{ 'profile-card__biz-section-value--empty': !bizName }, 'profile-card__biz-section-value']">{{ pointARevenue || 'Не указано' }}</div>
        </div>
      </div>
      <div class="profile-card__biz-section">
        <ListIcon class="profile-card__biz-section-icon" />
        <div class="profile-card__biz-section-info">
          <div class="profile-card__biz-section-title">{{ 'Точка А: прибыль' }}</div>
          <div :class="[{ 'profile-card__biz-section-value--empty': !bizName }, 'profile-card__biz-section-value']">{{ pointAProfit || 'Не указано' }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PointIcon from '@/icons/PointIcon'
/* import UserIcon from '@/icons/UserIcon' */
import StarActiveIcon from '@/icons/StarActiveIcon'
import BriefcaseIcon from '@/icons/BriefcaseIcon'
import ListIcon from '@/icons/ListIcon'
import TrackerIcon from '@/icons/TrackerIcon'
import Avatar from '@/controls/Avatar'
import LineIcon from '@/icons/Line'

/* <div v-else class="profile-card__info-city-value--empty">Не указан</div> */

export default {
  name: 'ProfileCard',
  props: {
    avatar: {
      required: false,
      type: String
    },
    name: {
      required: true,
      type: String
    },
    city: {
      required: false,
      type: String
    },
    bizArea: {
      required: false,
      type: String
    },
    bizNiche: {
      required: false,
      type: String
    },
    bizName: {
      required: false,
      type: String
    },
    tracker: {
      type: String,
      required: false
    },
    pointAProfit: {
      type: String,
      required: false
    },
    pointARevenue: {
      type: String,
      required: false
    },
  },
  components: {
    PointIcon,
    /* UserIcon, */
    StarActiveIcon,
    BriefcaseIcon,
    ListIcon,
    TrackerIcon,
    LineIcon,
    Avatar,
  },
  methods: {
    // Доделать, когда будет бэкэнд
    openSettings() {
      this.$emit('openSettings')
    },
    // Доделать, когда будет бэкэнд
    openTrackerProfile() {
      this.$emit('openTrackerProfile')
    }
  }
}
</script>
