<template>
  <div :class="[{ 'active': active, 'disabled': disabled }, { 'light': light },  'button']" @click="click">
    <slot></slot>
    <div :class="{'margin-l': ml}" class="button__text" >{{ text }}</div>
  </div>
</template>

<script>
export default {
  name: 'Button',
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    text: {
      required: true,
    },
    ml: {
      type: Boolean,
      default: false
    },
    light: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    click() {
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss">
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  color: rgba(255, 255, 255, .65);
  font-size: var(--edit-font-size);
  border-radius: 14px;
  user-select: none;
  overflow: hidden;
  position: relative;
  cursor: pointer;

  &.light {
    &.disabled {
      background: #242732;
    }
  }

  &::before {
    position: absolute;
    content: '';
    z-index: 1;
    width: 100%;
    height: 100%;
  }

  &.disabled {
    background: #181922;
    color: rgba(255, 255, 255, .3);
    pointer-events: none;
    cursor: default;
  }

  &:not(.disabled) {
    &:before {
      background: linear-gradient(112.83deg, rgba(93, 170, 240, 0.12) 0%, rgba(255, 255, 255, 0) 110.84%);
    }
  }

  &:hover:not(.disabled), &.active {
    background-color: #546FCE;
    color: #fff;
    transition: .2s;
  }

  &.active:hover:not(.disabled) {
    &::before {
      background-image: unset;
    }

    .button__text {
      color: #FFFFFF;
    }
  }

  &__text {
    z-index: 2;
  }

  .margin-l {
    margin-left: 5px;
  }
}
</style>
