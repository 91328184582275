<template>
  <div class="profile-stat" @click="$emit('change')">
    <div class="profile-stat__circle">
      <JellyIcon class="profile-stat__circle-jelly" />
      <svg class="profile-stat__circle-view" viewBox="0 0 56 56"> 
        <circle class="profile-stat__circle-back" stroke="#B199F3"></circle>
        <circle class="profile-stat__circle-front" :stroke-dasharray="calcCircumference" :stroke-dashoffset="circleValue" 
          stroke="url('#myGradient')" stroke-linecap="round"></circle>
        <defs xmlns="http://www.w3.org/2000/svg">
          <linearGradient id="myGradient" x1="28.8" y1="-8.81506" x2="41.2275" y2="64.8702" gradientUnits="userSpaceOnUse">
          <stop stop-color="#F0F3FF"/>
          <stop offset="1" stop-color="#BBB5FF"/>
          </linearGradient>
        </defs>
      </svg>
      <div class="profile-stat__circle-value">{{ percentValue }}%</div>
    </div>
    <div class="profile-stat__info">
      <div class="profile-stat__info-desc">{{ desc }}, ₽</div>
      <div class="profile-stat__info-value" v-if="targetValue">{{ factValue.toLocaleString('ru') }} / {{ targetValue.toLocaleString('ru') }}</div>
      <div class="profile-stat__info-prompt" v-else-if="showPromt">Составьте план на неделю</div>
      <div class="profile-stat__info-value" v-else> - / - </div>
    </div>
  </div>
</template>

<script>
import JellyIcon from '@/icons/JellyIcon'
export default {
  name: 'ProfileStat',
  components: {
    JellyIcon
  },
  props: {
    factValue: {
      type: Number,
      default: 0,
    },
    targetValue: {
      type: Number,
      default: 0,
    },
    desc: {
      type: String,
      required: true,
    },
    showPromt: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    calcCircumference: function () {
      let circumference = 2 * Math.PI * 24
      return  circumference
    },
    circleValue: function () {
      const maxCircleValue = this.calcCircumference
      const maxCircleValuePer = (maxCircleValue / 100) * this.percentValue
      const currentCircleValue = maxCircleValue - maxCircleValuePer
      
      return currentCircleValue
    },
    percentValue() {
      return this.targetValue ? Math.trunc((this.factValue / this.targetValue) * 100) : 0 
    }
  }
}
</script>

<style lang="scss">
  .profile-stat {
    display: flex;
    padding: 10px 0 10px 10px;
    @extend %card-gradient;
    background-color: #6644AF;
    color: #FFFFFF;
    cursor: pointer;

    &__circle {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 56px;
      min-width: 56px;
      height: 56px;
      margin-right: 10px;

      &-jelly {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
      }

      &-view {
        width: 100%;
        height: 100%;
        fill: transparent;
        z-index: 2;
        transform-origin: 50% 50%;
        transform: rotate(-90deg);
      }

      &-front, &-back {
        cx: 28px;
        cy: 28px;
        r: 24px;
        stroke-width: 8;
      }

      &-value {
        position: absolute;
        font-weight: bold;
        font-size: 12px;
        display: flex;
        align-items: center;
        text-align: center;
      }
    }

    &__info {
      position: inherit;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      overflow: hidden;
      
      &-desc {
        color: #ABBBF2;
        font-size: var(--edit-font-size);
        line-height: var(--edit-line-height);
        margin: 10px 0 4px;
      }

      &-value {
        font-size: 16px;
        white-space: nowrap;
        width: 100%;
      }

      &-prompt {
        font-size: 12px;
        cursor: pointer;
      }
    }

  }
</style>
