export const authSchema = {
  createDefault() {
    return {
      isLoggedIn: false,
      showTimer: false
    }
  },
  serialize(auth) {
    return this.copy({}, auth)
  },
  deserialize(data) {
    return this.copy({}, data)
  },
  copy(dest, source) {
    dest.isLoggedIn = source.isLoggedIn
    dest.showTimer = source.showTimer
    return dest
  }
}
