<template>
<div class="test-controls">
  <div class="test-controls__box">
    <Textarea
      :max="255"
      :value="textareaValue"
      placeholder="Начинай что-нибудь вводить ..."
      @input="setTextareaValue"
    />
  </div>
  <div class="test-controls__box">
    <Edit
      :value="value"
      placeholder="Введите текст..."
      @input="setValue"
    />
  </div>
  <div class="test-controls__box">
    <PassEdit
      :value="pass"
      placeholder="Введите пароль"
      @input="setEditValue"
    />
  </div>
  <div class="test-controls__box">
    <MoneyEdit 
      :value="money"
      @input="setMoney"
    />
  </div>
  <div class="test-controls__box">
    <MoneyEdit 
      :value="money"
      readonly
    />
  </div>
  <Combobox
    :list="comboList"
    :value="currentCombo"
    @setValue="setCurrentCombo"
  />
  <Button :text="'Отправить уведомление'" @click="sendNotification('Тестовый текст')"/>
  <div class="test-controls__box">
    <Checkbox :value="checkboxValue" @input="setCheckboxValue"/>
  </div>
  <div class="test-controls__edit">
    <ProfileForm/>
  </div>
</div>
</template>

<script>
import controller from '@/controller'
import Textarea from '@/controls/Textarea'
import Combobox from '@/controls/Combobox'
import Button from '@/controls/Button'
import Checkbox from '@/controls/Checkbox'
import MoneyEdit from '@/controls/MoneyEdit'
import Edit from '@/controls/Edit'
import PassEdit from '@/controls/PassEdit'
import ProfileForm from '@/controls/ProfileForm'

export default {
  name: 'TestControls',
  components: { 
    Textarea,
    Combobox,
    Button,
    Checkbox,
    MoneyEdit,
    Edit,
    PassEdit,
    ProfileForm,
  },
  data() {
    return {
      value: '',
      pass: '',
      currentCombo: 0,
      comboList: [
        {
          id: 'test',
          name: 'Фастфуд и общепит'
        },
        {
          id: 'test1',
          name: 'Доставка и логистика'
        },
        {
          id: 'test2',
          name: 'Стартаперы'
        },
        {
          id: 'test3',
          name: 'Консалтинг'
        },
        {
          id: 'test4',
          name: 'Маркетинг'
        },
        {
          id: 'test5',
          name: 'Оптовые продажи'
        },
        {
          id: 'test6',
          name: 'Продажа товаров'
        },
        {
          id: 'test7',
          name: 'Спорт и здоровье'
        },
        {
          id: 'test8',
          name: 'Красота'
        },
      ],
      checkboxValue: false,
      money: 90000,
      textareaValue: '',
      editValue: '',
      profileInfo: {
        name: 'Рома Иванов',
        city: '',
        avatar: 'https://sun9-71.userapi.com/impg/HBHnDlZ5tduP_6pIoaxIZGk8WT2uaUMIuLdrzA/I6-9n80xcMk.jpg?size=2560x1920&quality=96&sign=16e1b143cf35f55aeac9f59c08cec00b&type=album',
        bizInfo: {
          area: 'Фастфуд и общепит',
          niche: 'Кофейни',
          name: 'Angel Coffee'
        }
      }
    }
  },
  methods: {
    setValue(value) {
      this.value = value
    },
    setPass(value) {
      this.pass = value
    },
    setCurrentCombo(value) {
      this.currentCombo = value
    },
    setCheckboxValue(value) {
      this.checkboxValue = value
    },
    setMoney(value) {
      this.money = value
    },
    setTextareaValue(value) {
      this.textareaValue = value
    },
    setEditValue(value) {
      this.editValue = value
    },
    sendNotification(value) {
      if (value) {
        let notification = { text: value }
        const id = controller.app.addNotification(notification)
        setTimeout(function() {
          controller.app.removeNotification(id)
        }, 5000)
      }
    }
  }
}
</script>

<style lang="scss">
.test-controls {
  display: flex;
  align-items: center;
  flex-direction: column;

  &__box {
    background: linear-gradient(112.32deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 101.12%); 
    padding: 20px;
    width: 500px;
  }

  &__edit {
    width: 600px;
    height: 400px;
  }
}
</style>
