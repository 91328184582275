<template>
  <div class="watcher-profile">
    <ProfileSimpleForm class="watcher-profile__form" />
  </div>
</template>

<script>
import ProfileSimpleForm from '@/controls/ProfileSimpleForm'

export default {
  name: 'WatcherProfile',
  components: { ProfileSimpleForm },
}
</script>

<style lang="scss">
.watcher-profile {
  display: flex;
  justify-content: center;
  background-color: #21222D;
  border-radius: 24px;
  border: 1px solid rgba(255, 255, 255, 0.1);

  &__form {
    width: 600px;
    grid-template-rows: 5fr 1fr!important;
  }
}

@media screen and (max-width: $large) {
  .watcher-profile {
    &__form {
      width: 100%;
      grid-template-rows: 5fr 1fr!important;
    }
  }
}

</style>
