<template>
  <div>
    <div class="tracker-profile">
      <div class="profile-card">
        <div class="profile-card__personal">
          <Avatar class="profile-card__avatar" :avatar="user.userInfo.avatar"/>
          <div class="profile-card__info">
            <div class="profile-card__info-name">{{ fullname }}</div>
            <div class="profile-card__info-city">
              <PointIcon class="profile-card__info-city-icon"/>
              <div class="profile-card__info-city-value">{{ user.userInfo.city || 'Не указано' }}</div>
            </div>
          </div>
        </div>
      </div>
<!--      <ProfileRating :value="100" :maxValue="150" class="tracker-rating"/>-->
      <div></div>
      <ProfileStat
          :factValue="tracker.total ? parseInt(tracker.total.profitFact) : 0"
          :targetValue="tracker.total ? parseInt(tracker.total.profitTarget) : 0"
          :showPromt="false"
          desc="Выручка команды"
          class="tracker-profile__stat"/>
      <ProfileStat
          :factValue="tracker.total ? parseInt(tracker.total.revenueFact) : 0"
          :targetValue="tracker.total ? parseInt(tracker.total.revenueTarget) : 0"
          :showPromt="false"
          desc="Прибыль команды"
          class="tracker-profile__stat"/>
    </div>
    <div class="rating">
      <div class="rating__nav">
        <Button
            v-for="item in tracker.traction"
            :key="item.number"
            :text="item.number + ' неделя'"
            :class="[{
              'active': (currentTable && currentTable.number === item.number) || (!currentTable && currentWeek.number === item.number),
              'disabled': item.number > tracker.startedWeek.number },
              'rating__nav-item']"
            @click="setCurrentTable(item)"/>
      </div>
      <Table row="Day" :columns="columns" :weeks="tracker.traction" :currentWeek="currentTable || currentWeek"/>
    </div>
    <Sidebar v-if="showSidebar" :component="sidebarComponent" :params="sidebarParams" @closeSidebar="closeSidebar" />
  </div>
</template>

<script>
import ProfileStat from '@/controls/ProfileStat'
import Button from '@/controls/Button'
import Table from '@/controls/Table'
import Avatar from '@/controls/Avatar'
import Sidebar from '@/controls/Sidebar'
import PointIcon from '@/icons/PointIcon'
import model from '@/model'
import { mapProps } from '@/utils/model'
import controller from '@/controller'
import logger from '@/logger'

export default {
  name: 'Profile',
  components: {
    ProfileStat,
    // ProfileRating,
    Button,
    Table,
    Avatar,
    PointIcon,
    Sidebar,
  },
  data() {
    return {
      showSidebar: false,
      sidebarComponent: '',
      sidebarParams: null,
      currentTable: null,
      teamProfit: 680000,
      teamProfitTarget: 1000000,
      teamRevenue: 720000,
      teamRevenueTarget: 1000000,
      columns: [
        "Участник",
        "План выручки",
        "План прибыли",
        "Факт по выручке, ₽",
        "Факт по прибыли, ₽",
        "Гипотезы",
      ],
    }
  },
  computed: {
    ...mapProps(model.game, ['user', 'userInfo', 'tracker']),
    fullname() {
      let name = this.user.userInfo !== null ? `${this.user.userInfo.name} ${this.user.userInfo.lastname}` : ''
      return name.length > 1 ? name : 'Не указано'
    },
    currentWeek() {
      return this.tracker.week || this.currentTable
    },
  },
  methods: {
    setCurrentTable(item) {
      if (this.currentTable?.number !== item.number) {
        controller.game.loadTrackerWeek(item)
        this.currentTable = item
      }
    },
    openSidebar(componentName, params) {
      this.sidebarComponent = componentName
      this.sidebarParams = params
      this.showSidebar = true
    },
    closeSidebar(data) {
      logger.warn('-- closeSidebar data:', data)
      logger.log('sidebarComponent:', this.sidebarComponent)

      switch (this.sidebarComponent) {
        case 'ProfileForm':
          break
        case 'SprintForm':
          break
      }

      this.showSidebar = false
      this.sidebarParams = null
      this.sidebarComponent = ''
    },
  }
}
</script>

<style lang="scss">
.tracker-profile {
  display: grid;
  gap: $padding;
  margin-bottom: $margin;
  padding: 0 20px;

  &__stat {
    align-items: center;
    border-radius: 14px;
    grid-column: span 2;

    .profile-stat__info-desc {
      margin: 2px 0 4px;
    }
  }
  .profile-card{
    grid-column: span 2;
  }
}

.tracker-rating {
  grid-row: 2;
  padding: 0;
  margin-bottom: 0;

  .profile-rating__icon {
    order: -1;
  }
}

@media screen and (min-width: $medium) and (max-width: $large - 2) {
  .tracker-profile {
    grid-template-rows: $size $size;
    grid-template-columns: $size*4+$margin*3 $size*4+$margin*3;
    padding: 0;

    &__stat {
      height: 100%;
      grid-column: unset;
    }

    .profile-card {
      grid-row: unset;
      grid-column: unset;
    }
  }

  .tracker-rating {
    grid-row: 1;
    padding: 0;
    grid-column: 2;
    height: 100%;

    .profile-rating__info {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

@media screen and (min-width: $large) {
  .tracker-profile {
    grid-template-rows: 100px;
    grid-template-columns: $size*4+$margin*3 $size*2 $size*3+$margin+30 $size*3+$margin+30;
    padding: 0;

    &__stat {
      height: 100%;
      grid-column: unset;
    }

    .profile-card {
      grid-row: unset;
      grid-column: unset;
    }
  }

  .tracker-rating {
    grid-row: 1;
    grid-column: 2;
    height: 100%;

    .profile-rating__info {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
</style>
