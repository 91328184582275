
export function setCookieSecure(name, value, expires) {
  if (expires !== undefined) {
    document.cookie = `${name}=${value}; domain=.toolbox.bz; secure; samesite=lax; max-age=${expires}`
  } else {
    document.cookie = `${name}=${value}; domain=.toolbox.bz; secure; samesite=lax`
  }
}

export function setCookie(name, value) {
  const cookie = deserializeCookie(document.cookie)
  cookie[name] = value
  document.cookie = serializeCookie(cookie)
}

// arguments: name1, value1, name2, value2, ...
export function setCookieMulti() {
  if (arguments.length < 2) return

  const cookie = deserializeCookie(document.cookie)
  for (let a = 0; a < arguments.length; a += 2) cookie[arguments[a]] = arguments[a + 1]
  document.cookie = serializeCookie(cookie)
}

export function getCookie(name) {
  return deserializeCookie(document.cookie)[name]
}

// arguments: name1, name2, ...
export function getCookieMulti() {
  const cookie = deserializeCookie(document.cookie)
  return [...arguments].map(name => cookie[name])
}

function deserializeCookie(text) {
  const pairs = text.split(';')
  const cookie = {}
  pairs.forEach(pair => {
    const [name, value] = pair.split('=')
    cookie[name.trim()] = decodeURIComponent(value)
  })
  return cookie
}

function serializeCookie(cookie) {
  const pairs = []
  for (const [name, value] of Object.entries(cookie)) pairs.push(`${name}=${encodeURIComponent(value)}`)
  return pairs.join(';')
}
