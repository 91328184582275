<template>
  <div class="profile-card">
    <div class="profile-card__personal">
      <Avatar class="profile-card__avatar" :avatar="avatar"/>
      <div class="profile-card__info">
        <div class="profile-card__info-name">{{ name }}</div>
        <div class="profile-card__info-city">
          <PointIcon class="profile-card__info-city-icon"/>
          <div :class="[{ 'profile-card__biz-section-value--empty': !city }, 'profile-card__info-city-value']">{{ city || 'Не указано' }}</div>
        </div>
      </div>
      <div class="profile-card__open-settings" @click="openSettings"><SettingsIcon /></div>
    </div>
    <div class="profile-card__tracker"  :class="{'disabled' : disableBtn}"> <!--@click="openTrackerProfile(disableBtn)"-->
      <TrackerIcon class="profile-card__tracker-icon"/>
      <div :class="[{ 'profile-card__tracker-name--empty': !tracker }, 'profile-card__tracker-name']">{{ getFullName === ' ' ? 'Не назначен' : getFullName}}</div>
    </div>
    <div class="profile-card__proff">
      <div class="profile-card__proff__title">{{ 'Сфера: ' }}</div>{{ bizArea || 'Не указано' }}
    </div>
    <LineIcon class="profile-card__line-icon"/>
    <div class="grid-style">
      <div class="profile-card__biz-section">
        <BriefcaseIcon class="profile-card__biz-section-icon" />
        <div class="profile-card__biz-section-info">
          <div class="profile-card__biz-section-title">{{ 'Ниша' }}</div>
          <div
            v-tooltip="bizNiche"
            :class="[{ 'profile-card__biz-section-value--empty': !bizNiche }, 'profile-card__biz-section-value']"
          >
            {{ bizNiche || 'Не указано' }}
          </div>
        </div>
      </div>
      <div class="profile-card__biz-section">
        <ListIcon class="profile-card__biz-section-icon" />
        <div class="profile-card__biz-section-info">
          <div class="profile-card__biz-section-title">{{ 'Название' }}</div>
          <div
              v-tooltip="bizName"
            :class="[{ 'profile-card__biz-section-value--empty': !bizName }, 'profile-card__biz-section-value']"
          >
            {{ bizName || 'Не указано' }}
          </div>
        </div>
      </div>
      <div class="profile-card__biz-section">
        <ListIcon class="profile-card__biz-section-icon" />
        <div class="profile-card__biz-section-info">
          <div class="profile-card__biz-section-title">{{ 'Точка А: выручка' }}</div>
          <div :class="[{ 'profile-card__biz-section-value--empty': !bizName }, 'profile-card__biz-section-value']">
            {{ pointARevenue || 'Не указано' }}
          </div>
        </div>
      </div>
      <div class="profile-card__biz-section">
        <ListIcon class="profile-card__biz-section-icon" />
        <div class="profile-card__biz-section-info">
          <div class="profile-card__biz-section-title">{{ 'Точка А: прибыль' }}</div>
          <div :class="[{ 'profile-card__biz-section-value--empty': !bizName }, 'profile-card__biz-section-value']">
            {{ pointAProfit || 'Не указано' }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PointIcon from '@/icons/PointIcon'
/* import UserIcon from '@/icons/UserIcon' */
import SettingsIcon from '@/icons/SettingsIcon'
import BriefcaseIcon from '@/icons/BriefcaseIcon'
import ListIcon from '@/icons/ListIcon'
import TrackerIcon from '@/icons/TrackerIcon'
import Avatar from '@/controls/Avatar'
import LineIcon from '@/icons/Line'

/* <div v-else class="profile-card__info-city-value--empty">Не указан</div> */

export default {
  name: 'ProfileCard',
  props: {
    avatar: {
      required: false,
      type: String
    },
    name: {
      required: true,
      type: String
    },
    city: {
      required: false,
      type: String
    },
    bizArea: {
      required: false,
      type: String
    },
    bizNiche: {
      required: false,
      type: String
    },
    bizName: {
      required: false,
      type: String
    },
    pointAProfit: {
      type: String,
      required: false
    },
    pointARevenue: {
      type: String,
      required: false
    },
    tracker: {
      type: String,
      required: false
    }
  },
  components: {
    PointIcon,
    /* UserIcon, */
    SettingsIcon,
    BriefcaseIcon,
    ListIcon,
    TrackerIcon,
    Avatar,
    LineIcon
  },
  computed: {
    disableBtn() {
      let isHas = true;
      if(this.tracker && this.tracker.id) isHas = false
      return isHas
    },
    getFullName() {
      let name = '';
      let lastName = '';
      if(this.tracker && this.tracker.userInfo) {
        name = this.tracker.userInfo.name
        lastName = this.tracker.userInfo.lastname
      }
      return name  + ' ' + lastName
    }
  },
  methods: {
    // Доделать, когда будет бэкэнд
    openSettings() {
      this.$emit('openSettings')
    },
    // Доделать, когда будет бэкэнд
    openTrackerProfile(disableBtn) {
      disableBtn ? '' : this.$emit('openTrackerProfile')
    }
  }
}
</script>

<style lang="scss">
.profile-card {
  @extend %card-gradient;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 20px;
  border-radius: 24px;
  position: relative;
  // background: linear-gradient(112.83deg, rgba(179, 163, 244, 0.12) 0%, rgba(255, 255, 255, 0) 110.84%), linear-gradient(112.32deg, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0) 101.12%);
  // backdrop-filter: blur(42px);
  color: var(--edit-color);
  letter-spacing: var(--edit-letter-spacing);


  &__personal {
    position: inherit;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 14px;
  }

  &__avatar {
    min-width: 60px;
    width: 60px;
    height: 60px;
    margin-right: 20px;
  }

  &__info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &-name {
      font-size: var(--edit-font-size-middle);
      line-height: var(--edit-line-height-middle);
      margin-bottom: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 260px;
    }

    &-city {
      display: flex;
      align-items: center;
      font-size: var(--edit-font-size-small);
      line-height: var(--edit-line-height-small);
      letter-spacing: inherit;

      &-icon {
        margin-right: 3px;
      }

      &-value {
        &--empty {
          color: var(--nav-item-color);
        }
      }
    }
  }

  &__open-settings {
    align-self: flex-start;
    width: 18px;
    min-width: 18px;
    height: 18px;
    &:hover{
      cursor: pointer;
    }
  }

  &__tracker {
    position: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #546FCE;
    border-radius: 8px;
    padding: 11px 0;
    width: 100%;
    margin-bottom: 16px;
    cursor: pointer;
    &.disabled {
      cursor: default;
    }
    &-icon {
      margin-right: 10px;
    }

    &-name {
      font-size: var(--edit-font-size);
      color: #ABBBF2;

      &--empty {
        color: #fff;
      }
    }
  }

  &__biz-section {
    position: inherit;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;

    &-icon {
      margin-right: 14px;
    }

    &-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    &-title {
      font-size: var(--edit-font-size-small);
      line-height: var(--edit-line-height-small);
      color: var(--nav-item-color);
      margin-bottom: 3px;
    }

    &-value {
      font-size: 16px;
      line-height: 18px;
      letter-spacing: inherit;
      color: var(--edit-color);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 16ch;
      cursor: pointer;

      &--empty {
        color: var(--nav-item-color);
      }

      @media (max-width: 1240px) {
        max-width: 34ch;
      }
      @media (max-width: 820px) {
        max-width: 16ch;
      }
      @media (max-width: 700px) {
        max-width: 14ch;
      }
      @media (max-width: 647px) {
        max-width: 20ch;
      }
      @media (max-width: 560px) {
        max-width: 17ch;
      }
      @media (max-width: 510px) {
        max-width: 13ch;
      }
      @media (max-width: 456px) {
        max-width: 25ch;
      }
      @media (max-width: 374px) {
        max-width: 23ch;
      }
    }
  }
  &__line-icon {
    width: 100%;
  }
  &__proff {
    display: flex;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.3px;
    &__title {
      color: #546FCE;
      margin: 0 10px 10px 0;
    }
  }
}
.grid-style {
  display: grid;
  column-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  padding-top: 15px;
}
</style>
