<template>
  <div class="request-reset">
    <img :src="loginBg" alt="bg" class="request-reset__bg" />
    <div class="request-reset__card-border">
      <div class="request-reset__card card">
        <l-c-icon class="card__logo" />
        <h1 class="card__title">Восстановить доступ</h1>
        <p class="card__description">Введи email, к которому привязан аккаунт, чтобы восстановить доступ к игре</p>
        <form class="card__form">
          <div class="card__field">
            <label class="card__label">Email</label>
            <edit v-model.trim="email" placeholder="Введи email" />
          </div>
          <div
              v-for="status in requestStatus"
              :key="status.message"
              class="card__request-status"
              :class="{ 'card__request-status--positive': status.code === 200, 'card__request-status--negative': status.code !== 200 }"
          >
            {{ status.message }}
          </div>
          <Button class="card__submit" active text="Восстановить доступ" @click="onRequestReset" />
        </form>
        <div class="card__register">
          Вспомнил пароль?
          <router-link to="login" >Войти</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import controller from '@/controller'

import LCIcon from '@/icons/LCIcon'
import Button from '@/controls/Button'
import Edit from '@/controls/Edit'

import loginBg from '@/assets/login_bg.png'


export default {
  name: 'RequestReset',
  components: { LCIcon, Button, Edit },
  data: () => ({
    email: '',
    password: '',
    requestStatus: [],
    loginBg: loginBg
  }),
  methods: {
    async onRequestReset() {
      this.requestStatus = []
      const response = await controller.auth.requestPasswordReset(this.email)

      if (!response.data.errors.length) {
        this.requestStatus.push({ message: 'Письмо для восстановления успешно отправлено', code: 200 })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.request-reset {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  &__bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    min-height: 100%;
    object-fit: cover;
    z-index: -1;
  }

  &__card-border {
    width: 600px;
    margin-top: 95px;
    height: fit-content;
    border-radius: 24px;
    border: 2px solid #7C8CBA;
    box-shadow: 20.7171px 36.9949px 66.5908px rgba(23, 18, 43, 0.45);

    @media (max-width: 630px) {
      width: 100%;
      margin: 20px 15px 15px;
    }
  }

  &__card {
    width: 100%;
    border-radius: 22px;
    background: linear-gradient(
              247.99deg, 
              rgba(36, 39, 50, 0.6) 0%, 
              rgba(36, 39, 50, 0.7) 48.89%, 
              rgba(36, 39, 50, 0.7) 98.46%);
    backdrop-filter: blur(20px);
    box-shadow: 20px 37px 67px rgba(23, 18, 43, 0.45);
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-direction: column;
    padding: 40px 85px;
    @media (max-width: 630px) {
      padding: 40px 20px;
    }
  }
}

.card {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;

  &__logo {
    align-self: center;
    fill: #ABBBF2;
    margin-bottom: 24px;
  }

  &__title {
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: #ABBBF2;
    margin-top: 0;
    margin-bottom: 20px;
  }

  &__description {
    font-size: 14px;
    font-kerning: -0.3px;
    text-align: center;
    color: #ABBBF2;
  }

  &__field {
    display: flex;
    flex-direction: column;
    margin-bottom: 14px;
    font-size: 12px;
    color: var(--edit-color-normal);
  }

  &__label {
    align-self: flex-start;
    margin-bottom: 5px;
  }

  &__forgot {
    margin-top: 6px;
    align-self: flex-end;
    font-size: 14px;
    letter-spacing: -0.3px;
    text-decoration: none;
    color: var(--edit-color-normal);
  }

  &__request-status {
    margin: 0 15px;
    font-size: 14px;
    letter-spacing: -0.3px;

    &--positive {
      color: #1DCD40;
    }

    &--negative {
      color: #CD1D1D;
    }
  }

  &__submit {
    width: 100%;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  &__register {
    font-size: 14px;
    font-kerning: -0.3px;
    color: #546FCE;

    a {
      color: #ABBBF2;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
