<template>
  <div class="edit">
    <input class="edit__value" type="text" :value="value" :readonly="readonly" :placeholder="placeholder" @input="setValue">
  </div>
</template>

<script>
export default {
  name: 'Edit',
  props: {
    value: {
      type: [String, Number],
      required: true,
    },
    placeholder: {
      default: 'Введите текст...',
      type: String
    },
    readonly: {
      type: Boolean,
      required: false
    }
  },
  methods: {
    setValue(event) {
      this.$emit('input', event.target.value)
    }
  }
}
</script>

<style lang="scss">
.edit{
  display: flex;
  position: relative;
  width: 100%;
  font-size: var(--edit-font-size);
  line-height: var(--edit-line-height);

  &__value {
    outline: none;
    border: 1px solid transparent;
    text-align: left;
    width: 100%;
    padding: 14px 10px;
    border-radius: 8px;
    background-color: var(--edit-bg-form);
    color: var(--nav-item-active-color);

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    &:focus, &.editing {
      border-color: var(--edit-form-border-color);
    }

     &::placeholder {
      color: var(--edit-form-placeholder);
    }
  }
}
</style>
