<template>
  <div :class="[{ 'active': active }, 'profile-day']">
    <template v-if="loading">
      <div class="profile-day__header">
        <div class="profile-day__number">День {{ day.day }}</div>
        <div class="profile-day__date"><div class="profile-day__plug"></div></div>
        <div class="profile-day__lamp"></div>
      </div>
      <div class="profile-day__profit-desk">
        <div class="profile-day__profit-block">
          <div class="profile-day__profit-block-title"><div class="profile-day__plug"></div></div>
          <div class="profile-day__plug"></div>
          <div class="profile-day__plug profile-day__plug-md"></div>
        </div>
        <div class="profile-day__profit-block">
          <div class="profile-day__profit-block-title"><div class="profile-day__plug"></div></div>
          <div class="profile-day__plug"></div>
          <div class="profile-day__plug profile-day__plug-md"></div>
        </div>
        <div class="profile-day__profit-block">
          <div class="profile-day__profit-block-title"><div class="profile-day__plug"></div></div>
          <div class="profile-day__plug"></div>
          <div class="profile-day__plug profile-day__plug-md"></div>
        </div>
        <div class="profile-day__profit-block">
          <div class="profile-day__profit-block-title"><div class="profile-day__plug"></div></div>
          <div class="profile-day__plug"></div>
          <div class="profile-day__plug profile-day__plug-md"></div>
        </div>
      </div>
      <div class="profile-day__hypotheses">
        <div class="profile-day__hypotheses-add"><PlusIcon /></div>
        <div class="profile-day__hypotheses-title">Гипотезы</div>
        <div class="profile-day__wrap">
          <div class="profile-day__plug profile-day__plug-sm"></div>
          <div class="profile-day__plug profile-day__plug-md"></div>
          <div class="profile-day__plug profile-day__plug-sm"></div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="profile-day__header">
        <div class="profile-day__number">День {{ day.day }}</div>
        <div class="profile-day__header-right">
          <div v-show="dayMonth" class="profile-day__date">{{ dayMonth }}</div>
          <div v-if="!locked && day.result" class="profile-day__result">
            <div class="profile-day__result-text profile-day__result_success" v-if="day.result === 'success'">успешный</div>
            <div class="profile-day__result-text profile-day__result_in-progress" v-if="day.result === 'inProgress'">в работе</div>
            <div class="profile-day__result-text profile-day__result_out-of-date" v-if="day.result === 'outOfDate'">просрочен</div>
          </div>
        </div>
      </div>
      <!-- <template v-if="!day.availableFrom || !week.id"> -->
      <template v-if="locked && forceLock">
        <LockIcon class="profile-day__locked-icon"/>
        <template v-if="!week.userProfitTarget || !week.userRevenueTarget">
          <div class="profile-day__message">
            <span>День откроется после внесения плана на неделю</span>
          </div>
          <div class="profile-day__toolbar"></div>
        </template>
        <template v-else>
          <div class="profile-day__prompt">Откроется в 00:00 {{ dayMonth }}</div>
          <div v-if="week.id" class="profile-day__message">
            <span>{{ getTargetCompleteString() }}</span>
          </div>
          <div class="profile-day__toolbar">
            <Button class="profile-day__add-plan" text="Внести план" :active="true" @click="addPlan()" />
          </div>
        </template>
      </template>
      <template v-else>
        <div class="profile-day__profit-desk">
          <div class="profile-day__profit-block">
            <div class="profile-day__profit-block-title">Выручка план</div>
            <currency-input
              v-model="values.revenueTarget"
              @change="setRevenueTarget"
              @undo="values.revenueTarget = day.revenueTarget"
            />
          </div>
          <div class="profile-day__profit-block">
            <div class="profile-day__profit-block-title">Выручка факт</div>
            <currency-input
              v-tooltip="enterFactTooltip(day)"
              v-model="values.revenueFact"
              :readonly="!!enterFactTooltip(day)"
              @change="setRevenueFact"
              @undo="values.revenueFact = day.revenueFact"
            />
          </div>
          <div class="profile-day__profit-block">
            <div class="profile-day__profit-block-title">Прибыль план</div>
            <currency-input
              v-model="values.profitTarget"
              @change="setProfitTarget"
              @undo="values.profitTarget = day.profitTarget"
            />
          </div>
          <div class="profile-day__profit-block">
            <div class="profile-day__profit-block-title">Прибыль факт</div>
            <currency-input
              allow-negative
              v-tooltip="enterFactTooltip(day)"
              v-model="values.profitFact"
              :readonly="!!enterFactTooltip(day)"
              @change="setProfitFact"
              @undo="values.profitFact = day.profitFact"
            />
          </div>
        </div>
        <div class="profile-day__hypotheses">
          <div class="profile-day__hypotheses-row">
            <div class="profile-day__hypotheses-title">Гипотезы</div>
            <div @click="$emit('createHypothesis')" class="profile-day__hypotheses-add-button">
              <AddHypothesisIcon class="profile-day__hypotheses-add-icon" />
              <div class="profile-day__hypotheses-add-text">Добавить гипотезу</div>
            </div>
          </div>
          <div v-if="day.hypotheses.length" class="profile-day__hypotheses">
            <Hypothesis
              v-for="hypothesis in day.hypotheses" 
              :key="hypothesis.id"
              class="profile-day__hypothesis"
              :hypothesis="hypothesis"
              @createHypothesis="$emit('createHypothesis')"
            />
          </div>
          <div v-else class="profile-day__hypotheses-empty">
            <template v-if="day.id">
              <span>Ты еще не написал гипотезу</span>
              <span>на этот день</span>
            </template>
            <template v-else>
              <span>Заполни план на день</span>
              <span>чтобы добавить гипотезу</span>
            </template>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
/* import Textarea from '@/controls/Textarea' */
import Hypothesis from '@/controls/Hypothesis'
import Button from '@/controls/Button'
import PlusIcon from '@/icons/PlusIcon'
import AddHypothesisIcon from '@/icons/AddHypothesisIcon'
import LockIcon from '@/icons/LockIcon'
import CurrencyInput from '@/controls/CurrencyInput'

export default {
  name: 'ProfileDay',
  components: {
    CurrencyInput,
    /* Textarea, */
    Hypothesis,
    Button,
    PlusIcon,
    LockIcon,
    AddHypothesisIcon,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    day: {
      type: Object,
      required: true
    },
    week: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      hypothesisPlaceholder: 'Ты еще не написал гипотезу на этот день',
      values: {
        revenueFact: undefined,
        revenueTarget: undefined,
        profitFact: undefined,
        profitTarget: undefined
      },
      forceLock: true
    }
  },
  computed: {
    active() {
      return this.day.status === 'current'
    },
    locked() {
      return this.day.status === 'future' || !this.week.id
    },
    dayMonth() {
      return this.day.availableFrom?.toLocaleString('ru', { month: 'long', day: 'numeric' }) || ''
    },
    needProfitTarget() {
      const total = this.week.userProfitTarget
      return this.week.dayReports.reduce((acc, day) => {
        return day.id ? acc - day.profitTarget : acc
      }, total)
    },
    needRevenueTarget() {
      const total = this.week.userRevenueTarget
      return this.week.dayReports.reduce((acc, day) => {
        return day.id ? acc - day.revenueTarget : acc
      }, total)
    },
    needProfitFact() {
      const total = this.week.userProfitTarget
      return this.week.dayReports.reduce((acc, day) => {
        return day.id ? acc - day.profitFact : acc
      }, total)
    },
    needRevenueFact() {
      const total = this.week.userRevenueTarget
      return this.week.dayReports.reduce((acc, day) => {
        return day.id ? acc - day.revenueFact : acc
      }, total)
    }
  },
  watch: {
    day(value) {
      this.values = {
        revenueTarget: value.revenueTarget,
        revenueFact: value.revenueFact,
        profitTarget: value.profitTarget,
        profitFact: value.profitFact
      }
    }
  },
  created() {
    this.values = {
      revenueTarget: this.day.revenueTarget,
      revenueFact: this.day.revenueFact,
      profitTarget: this.day.profitTarget,
      profitFact: this.day.profitFact
    }
  },
  methods: {
    composeChanges() {
      return {
        revenueTarget: this.values.revenueTarget,
        revenueFact: this.values.revenueFact,
        profitTarget: this.values.profitTarget,
        profitFact: this.values.profitFact
      }
    },
    addPlan() {
      this.forceLock = false
    },
    setRevenueTarget(value) {
      if (this.day.revenueTarget !== value) {
        const changes = { ...this.composeChanges(), revenueTarget: value }
        this.$emit('changeDay', { day: this.day, changes })
      }
    },
    setRevenueFact(value) {
      if (this.day.revenueFact !== value) {
        const changes = { ...this.composeChanges(), revenueFact: value }
        this.$emit('changeDay', { day: this.day, changes })
      }
    },
    setProfitTarget(value) {
      if (this.day.profitTarget !== value) {
        const changes = { ...this.composeChanges(), profitTarget: value }
        this.$emit('changeDay', { day: this.day, changes })
      }
    },
    setProfitFact(value) {
      if (this.day.profitFact !== value) {
        const changes = { ...this.composeChanges(), profitFact: value }
        this.$emit('changeDay', { day: this.day, changes })
      }
    },
    enterFactTooltip(day) {
      return day.status === 'future' ? 'Нельзя вводить факт за будущее' : null
    },
    getTargetCompleteString() {
      return this.needProfitFact > 0 && this.needRevenueFact > 0
        ? `Осталось внести ${ this.needProfitFact } прибыли и ${ this.needRevenueFact } выручки для выполнения недельного плана`
        : this.needProfitFact > 0 && this.needRevenueFact <= 0
          ? `План по выручке выполнен, осталось внести ${ this.needProfitFact } прибыли для выполнения недельного плана`
          : this.needProfitFact <= 0 && this.needRevenueFact > 0
            ? `План по прибыли выполнен, осталось внести ${ this.needRevenueFact } выручки для выполнения недельного плана`
            : 'Ты уже выполнил план, но не останавливайся, чемпион!'
    },
    showAddHypothesis(day) {
      return day.id && day.revenueTarget >= 0 && day.profitTarget >= 0
    }
  }
}
</script>

<style lang="scss">
.profile-day {
  display: flex;
  flex-direction: column;
  letter-spacing: var(--edit-letter-spacing);
  padding: 20px;
  $border: 1px;
  border: $border solid transparent;
  user-select: none;

  &__plug {
    height: 14px;
    width: 100%;
    min-width: 70px;
    background: #546FCE;
    border-radius: 4px;
    margin-bottom: 6px;

    &:last-child {
      margin-bottom: 0;
    }

    &-md {
      width: 80%;
    }

    &-sm {
      width: 50%;
    }
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #171821;
    border-radius: 12px;
    height: 100px;
  }

  &.active {
    border-color: #546FCE;

    .profile-day__number {
      color: #fff;
    }

    .profile-day__date {
      color: #546FCE;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    &-right {
      display: flex;
      align-items: center;
    }
  }

  &__result {
    width: 90px;
    min-width: 90px;
    height: 32px;
    display: flex;
    background: #181922;
    border-radius: 8px;

    &-text {
      margin: auto;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: -0.3px;
    }

    &_success {
      color: #2AB346;
    }

    &_in-progress {
      color: #ABBBF2;
    }

    &_out-of-date {
      color: #DE3A3A;
    }
  }

  &__locked-icon {
    margin: 10px 0 30px;
  }

  &__prompt {
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    margin-bottom: 20px;
    color: #ABBBF2;
  }

  &__number {
    flex: 1;
    text-align: left;
    font-size: 24px;
    line-height: 28px;
    color: #ABBBF2;
  }

  &__date {
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #546FCE;
    padding: 7px 10px;
    background: #181922;
    border-radius: 8px;
    margin-right: 10px;
    min-width: fit-content;
  }

  &__message {
    display: inline-flex;
    flex-direction: column;
    color: #546FCE;
    align-self: center;
    max-width: 25ch;
  }

  &__toolbar {
    display: flex;
    justify-content: center;
    margin: 40px;
  }

  &__add-plan {
    width: 190px;
  }

  &__lamp {
    @extend %lamp;
  }

  &__profit-desk {
    display: flex;
    flex-wrap: wrap;
    gap: 14px 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #546FCE;
    margin-bottom: 20px;
  }

  &__profit-block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: calc(170px - 2*#{$border});

    &-title {
      font-size: 12px;
      line-height: 16px;
      color: #546FCE;
      margin-bottom: 5px;
    }
  }

  &__hypotheses {
    display: flex;
    flex-direction: column;
    position: relative;

    &-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px;
    }

    &-add {
      &-button {
        display: flex;
        align-items: center;
        padding: 7px 10px;
        background-color: #181922;
        border-radius: 8px;
        cursor: pointer;
      }

      &-icon {
        width: 14px;
        height: 14px;
        margin-right: 8px;
      }

      &-text {
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.3px;
        color: #ABBBF2
      }
    }

    &-title {
      text-align: left;
      color: #546FCE;
      font-size: 14px;
      line-height: 18px;
    }

    &-add {
      cursor: pointer;
      position: absolute;
      right: 0;
      top: 0;
    }

    & > .hypothesis {
      margin-bottom: 10px;
      cursor: pointer;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }


  &__hypothesis-card {
    margin: 14px;
    border: 1px solid #546FCE;
  }

  &__hypotheses-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100px;
    padding: 0 86px;
    border: none;
    border-radius: 12px;
    background-color: #181922;
    color: #546FCE;
    font-size: var(--edit-font-size);
    line-height: var(--edit-line-height);
    letter-spacing: var(--edit-letter-spacing);
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
