<template>
<div class="notifications">
  <transition-group name="list" tag="p">
    <Notification v-for="notification in notifications" :key="notification.id" class="notification" :text="notification.text" />
  </transition-group>
</div>
</template>

<script>
import Notification from '@/controls/Notification'
import model from '@/model'
// import controller from '@/controller'
import { mapProps } from '@/utils/model'

export default {
  name: 'Notifications',
  components: {
    Notification,
  },
  computed: {
    ...mapProps(model.app, ['notifications'])
  },
}
</script>

<style lang="scss">
.notifications {
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 50%;
  bottom: 20px;
  transform: translateX(-50%);
  z-index: 999;
}

.list-enter-active, .list-leave-active {
  transition: all 1s;
}
.list-enter, .list-leave-to{
  opacity: 0;
  transform: translateY(30px);
}
</style>
