<template>
  <div class="toolbar-menu">
    <div class="toolbar-menu__scroll">
      <div class="toolbar-menu__title">Профиль</div>
      <div class="toolbar-menu__avatar">
        <Avatar :avatar="user.userInfo.avatar" class="toolbar-menu__avatar-view"/>
        <div class="toolbar-menu__avatar-add" @click="openFileSelect">
          <input ref="file-input" type="file" @change="onFileSelect($event)" hidden />
          <AddPhotoIcon/>
        </div>
      </div>
      <div class="toolbar-menu__section">
        <div class="toolbar-menu__block">
          <div class="toolbar-menu__label">Имя</div>
          <Edit v-model="user.userInfo.name" placeholder="Укажи имя" />
        </div>
        <div class="toolbar-menu__block">
          <div class="toolbar-menu__label">Фамилия</div>
          <Edit :value="user.userInfo.lastname" placeholder="Укажи фамилию" @input="setLastname"/>
        </div>
        <div class="toolbar-menu__block">
          <div class="toolbar-menu__label">Город</div>
          <Edit :value="user.userInfo.city" placeholder="Укажи свой город" @input="setCity"/>
        </div>
        <div class="toolbar-menu__block">
          <div class="toolbar-menu__label">Email</div>
          <Edit :value="user.userInfo.email" placeholder="Укажи email" @input="setEmail"/>
        </div>
      </div>

      <div class="toolbar-menu__title">Смена пароля</div>
      <div class="toolbar-menu__section">
        <div class="toolbar-menu__block toolbar-menu__row">
          <div class="toolbar-menu__label">Старый пароль</div>
          <PassEdit :value="oldPass" placeholder="Укажи старый пароль" @input="setOldPass"/>
        </div>
        <div class="toolbar-menu__block toolbar-menu__row">
          <div class="toolbar-menu__label">Новый пароль</div>
          <PassEdit :value="newPass" placeholder="Укажи новый пароль" @input="setNewPass"/>
        </div>
        <div class="toolbar-menu__block toolbar-menu__row">
          <div class="toolbar-menu__label">Повторите новый пароль</div>
          <PassEdit :value="repeatNewPass" placeholder="Повтори новый пароль" @input="setRepeatNewPass"/>
        </div>
      </div>
    </div>
    <div class="toolbar-menu__control">
      <Button :disabled="!canSave" class="toolbar-menu__save" text="Сохранить" @click="save"/>
    </div>
  </div>
</template>

<script>
import Avatar from '@/controls/Avatar'
import Edit from '@/controls/Edit'
import PassEdit from '@/controls/PassEdit'
import Button from '@/controls/Button'
import AddPhotoIcon from '@/icons/AddPhotoIcon'
import model from '@/model'
import controller from '@/controller'
import { mapProps } from '@/utils/model'
import { userSchema } from '@/model/schema/game'

export default {
  name: 'ProfileForm',
  components: {
    Avatar,
    Edit,
    PassEdit,
    Button,
    AddPhotoIcon,
  },
  data() {
    return {
      avatarURL: '',
      user: userSchema.createDefault(),
      oldPass: '',
      newPass: '',
      repeatNewPass: ''
    }
  },
  computed: {
    ...mapProps(model.game, {
      'modelUser': 'user',
      'businessCategories': 'businessCategories'
    }),
    name() {
      return model.game.user.userInfo.name
    },
    lastname() {
      return model.game.user.userInfo.lastname
    },
    city() {
      return model.game.user.userInfo.city
    },
    email() {
      return model.game.user.userInfo.email
    },
    avatar() {
      return model.game.user.userInfo.avatar
    },
    needToChangePassword() {
      return this.oldPass !== '' && this.newPass !== '' && this.newPass === this.repeatNewPass
    },
    canSave() {
      return this.needToChangePassword || (this.oldPass === '' && this.newPass === '' && this.repeatNewPass === '')
    }
  },
  created() {
    userSchema.copy(this.user, this.modelUser)
    this.avatarURL = this.user.userInfo.avatar
  },
  watch: {
    name() {
      this.user.userInfo.name = this.name
    },
    lastname() {
      this.user.userInfo.lastname = this.lastname
    },
    city() {
      this.user.userInfo.city = this.city
    },
    email() {
      this.user.userInfo.email = this.email
    },
    avatar() {
      this.user.userInfo.avatar = this.avatar
    }
  },
  methods: {
    openFileSelect() {
      this.$refs['file-input'].click()
    },
    async onFileSelect(event) {
      const file = event.target.files[0]
      this.avatarURL = URL.createObjectURL(file)

      const response = await controller.game.uploadAvatar(file)
      if (response.status === 200) {
        this.user.userInfo.avatar = this.avatarURL
        controller.game.model.user.userInfo.avatar = this.avatarURL
      } else {
        this.avatarURL = ''
      }
    },
    setName(name) {
      this.user.userInfo.name = name
    },
    setLastname(lastname) {
      this.user.userInfo.lastname = lastname
    },
    setCity(city) {
      this.user.userInfo.city = city
    },
    setEmail(email) {
      this.user.userInfo.email = email
    },
    setOldPass(oldPass) {
      this.oldPass = oldPass
    },
    setNewPass(newPass) {
      this.newPass = newPass
    },
    setRepeatNewPass(repeatNewPass) {
      this.repeatNewPass = repeatNewPass
    },
    save() {
      controller.game.updateSimpleProfile(this.user)

      if (this.needToChangePassword) {
        controller.game.updatePassword({
          currentPassword: this.oldPass,
          newPassword1: this.newPass,
          newPassword2: this.repeatNewPass
        })
      }
      this.$emit('closeSidebar')
    }
  }
}
</script>
