<template>
  <div class="hypothese">
    <div class="hypothese__header">
      <Avatar class="hypothese__avatar" :avatar="avatar"/>
      <div class="hypothese__header-info">
        <div class="hypothese__name">{{ name }}</div> 
        <div class="hypothese__date">{{ date.getDate() }}.{{ date.getMonth() + 1 }}.{{ date.getFullYear() }}</div> 
      </div>
    </div>
    <div class="hypothese__body">
      <div class="hypothese__text">{{ text }}</div>
      <div class="hypothese__stats" v-if="comments && likes && dislikes">
        <div class="hypothese__block">
          <CommentIcon class="hypothese__block-icon"/> {{ comments.toLocaleString('ru') }}
        </div>
        <div class="hypothese__block hypothese__like">
          <LikeIcon class="hypothese__block-icon"/> {{ likes.toLocaleString('ru') }}
        </div>
        <div class="hypothese__block hypothese__dislike">
          <DislikeIcon class="hypothese__block-icon"/> {{ dislikes.toLocaleString('ru') }}
        </div>
      </div>
      <!-- <div class="hypothese__key-words">
        <div class="hypothese__key-word"  v-for="word in words" :key="word.id" :style="[{ backgroundColor: word.bgColor, color: word.color}]">{{ word.name }}</div>
      </div> -->
    </div>
  </div>
</template>

<script>
import Avatar from '@/controls/Avatar'
import CommentIcon from '@/icons/CommentIcon'
import LikeIcon from '@/icons/LikeIcon'
import DislikeIcon from '@/icons/DislikeIcon'
export default {
  name: 'Hypothese',
  components: {
    Avatar,
    CommentIcon,
    LikeIcon,
    DislikeIcon,
  },
  props: {
    avatar: {
      type: String,
      required: false
    },
    name: {
      type: String,
      required: true,
    },
    date: {
      type: Date,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    /* words: { */
    /*   type: Array, */
    /*   required: true, */
    /* }, */
    comments: {
      type: Number,
      required: false,
    },
    likes: {
      type: Number,
      required: false,
    },
    dislikes: {
      type: Number,
      required: false,
    }
  },
}
</script>

<style lang="scss">
.hypothese {
  display: flex;
  flex-direction: column;
  position: relative;
  font-size: 14px;
  line-height: 18px;
  border-radius: 8px;
  padding: 20px;
  color: #ABBBF2;
  background-color: var(--edit-bg-color);

  &__header{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 40px;
    text-align: left;
    margin-bottom: 10px;

    &-info {
      display: flex;
      flex-direction: column;
    }
  }

  &__avatar {
    width: 40px;
    height: 40px;
    margin-right: 14px;
    
    .avatar__icon {
      border: 1.5px solid #546FCE;
      border-radius: 50%;
    }
  }

  &__name {
    font-size: 16px;
    letter-spacing: var(--edit-letter-spacing);
    margin-bottom: 3px;
  }

  &__date {
    font-weight: normal;
    font-size: 12px;
    color: #546FCE;
  }

  &__body {
    display: flex;
    flex-direction: column;
  }

  &__text {
    margin-bottom: 16px;
    text-align: left;
  }

  &__key-words {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding-top: 20px;

    &:before {
      position: absolute;
      content: '';
      width: calc(100% - (2*4px));
      left: 4px;
      top: 0;
      height: 1px;
      background-color: #546FCE;
    }
  }

  &__stats {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }

  &__block {
    display: flex;
    align-items: center;
    letter-spacing: var(--edit-letter-spacing);
    margin-right: 20px;
    color: #546FCE;

    &-icon {
      margin-right: 4px;
    }
  }

  &__like, &__dislike {
    cursor: pointer;
  }

  &__like { 
    &.active {
      color: #E28E0F;

      path {
        fill: #E28E0F;
      }
    }
  }

  &__dislike {
    &.active {
      color: #61D4FF;

      path {
        fill: #61D4FF;
      }
    }
  }

  &__key-word {
    display: flex;
    padding: 4px 10px;
    border-radius: 4px;
    font-size: 12px;
  }
}
</style>
