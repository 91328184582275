import Hasher from '@/utils/Hasher'

export default class App {
  constructor(model) {
    this.model = model
  }

  addNotification(msg, time) {
    const id = Hasher.generate()
    const notification = { text: msg.text, id: id}
    this.model.notifications.push(notification)
    if(time) {
      setTimeout(() => {
        this.removeNotification(notification.id)
      }, time)
    }    
    return id
  }

  removeNotification(id) {
    const index = this.model.notifications.findIndex(n => n.id === id)
    this.model.notifications.splice(index, 1)
  }
}
