import Auth from './auth'
import Game from './game'
import App from './app'
import model from '@/model'

export default {
  app: new App(model.app),
  auth: new Auth(model.auth),
  game: new Game(model.game)
}
