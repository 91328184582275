<template>
  <router-link
      :to="routerLink"
      :class="[{
        'player--first': place === 1,
        'player--second': place === 2,
        'player--third': place === 3,
        'eliminated': isLoser },
        'player',
        'player--tracker']">
    <div class="player__info">
      <div class="player__place">
        <component :is="placeView" class="player__place-view"/>
        <div class="player__place-value">{{ place }}</div>
      </div>
      <Avatar class="player__avatar" :avatar="player.tracker.userInfo ? player.tracker.userInfo.avatar : ''"/>
      <div class="player__name">{{ fullname }}</div>
    </div>
    <div class="player__column">
      <div class="player__title">{{ columns[1] }}:</div>
      <div class="player__text">{{ player.profitFact.toLocaleString('ru') || '-' }}</div>
    </div>
    <div class="player__column">
      <div class="player__title">{{ columns[2] }}:</div>
      <div class="player__text">{{ player.revenueFact.toLocaleString('ru') || '-' }}</div>
    </div>
  </router-link>
</template>

<script>
import Avatar from '@/controls/Avatar'
import StoneIcon from '@/icons/StoneIcon'
import BronzeIcon from '@/icons/BronzeIcon'
import SilverIcon from '@/icons/SilverIcon'
import GoldIcon from '@/icons/GoldIcon'
import StarIcon from '@/icons/StarIcon'
import StarActiveIcon from '@/icons/StarActiveIcon'
import model from '@/model'

export default {
  name: 'Player',
  components: {
    Avatar,
    StoneIcon,
    BronzeIcon,
    SilverIcon,
    GoldIcon,
    StarIcon,
    StarActiveIcon,
  },
  props: {
    isLoser: {
      type: Boolean,
      required: false,
    },
    player: {
      type: Object,
      required: true,
    },
    columns: {
      type: Array,
      required: true
    },
    place: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      placesList: {
        1: 'GoldIcon',
        2: 'SilverIcon',
        3: 'BronzeIcon',
      }
    }
  },
  computed: {
    fullname() {
      let name = this.player.tracker.userInfo !== null ? `${this.player.tracker.userInfo.name} ${this.player.tracker.userInfo.lastname}` : ''
      return name.length > 1 ? name : 'Аноним'
    },
    placeView() {
      if (this.place <= 3) return this.placesList[this.place]
      return 'StoneIcon'
    },
    routerLink() {
      if (model.game.initialId === this.player.tracker.id) {
        return '/profile'
      }
      return `/profile/${this.player.tracker.id}`
    }
  }
}
</script>