<template>
  <component :is="profileComponent" />
</template>

<script>
import Profile from '@/pages/ProfileReadOnly'
import TrackerProfile from '@/pages/TrackerProfileReadOnly'
import WatcherProfile from '@/pages/WatcherProfile'
import { mapProps } from '@/utils/model'
import model from '@/model'
import { GAME_ROLE_PARTICIPANT, GAME_ROLE_TRACKER, GAME_ROLE_WATCHER } from '@/model/schema/game'
import controller from '@/controller';

const ROLE_TO_PROFILE = {
  [GAME_ROLE_PARTICIPANT]: 'Profile',
  [GAME_ROLE_TRACKER]: 'TrackerProfile',
  [GAME_ROLE_WATCHER]: 'Profile'
}

export default {
  name: 'ProfileWrapReadOnly',
  components: {
    Profile,
    TrackerProfile,
    WatcherProfile
  },
  computed: {
    ...mapProps(model.game, ['gameRole']),
    profileComponent() {
      return ROLE_TO_PROFILE[this.gameRole] || ''
    }
  },
  created() {
    if (model.game.initialId !== parseInt(this.$route.params.id)) {
      controller.game.loadProfileById(this.$route.params.id)
    } else {
      this.$router.push({name: 'profile'})
    }
  },
}
</script>
