<template>
  <div class="photo-popup">
    <div class="photo-popup__bg" @click.self="close">
      <div class="photo-popup__wrapper">
        <div class="photo-popup__close" @click="close"><close-icon/></div>
        <div class="photo-popup__body">
          <img class="photo-popup__photo" :src="photo" alt="Фото бизнеса" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CloseIcon from '@/icons/CloseIcon'

export default {
  name: 'PhotoPopup',
  components: { CloseIcon },
  props: {
    photo: {
      type: String,
      required: true
    }
  },
  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss">
.photo-popup {
  z-index: 100!important;
  position: fixed;
  top: 0;
  left: 0;

  &__bg {
    width: 100vw;
    height: 100vh;
    background: rgba(0, 2, 12, .65);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__wrapper {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
  }

  &__close {
    width: 30px;
    height: 30px;
    cursor: pointer;
    padding: 10px;

    @media (max-width: 425px) {
      display: none;
    }

    svg g path {
      fill: #C2C4CD;
    }
  }

  &__photo {
    object-fit: cover;
    border-radius: 20px;
    max-width: 85vw;
    max-height: 60vw;
    height: 100%;
    width: 100%;
  }
}


</style>
