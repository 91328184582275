<template>
  <div class="profile-gallery">
    <div class="profile-gallery__image" v-for="(image, index) in viewImages" :key="index">
      <div v-if="image.id >= 0 && !readonly" class="profile-gallery__delete-photo" @click="deletePhoto(image.id)"><DeleteIcon/></div>
      <img
          v-if="image.publicPath"
          alt="Фото бизнеса"
          :src="image.publicPath"
          class="profile-gallery__image-view"
          @click="openPopup(image)"
      />
      <template v-else>
        <PhotoIcon />
        <div v-if="index === 0 && !readonly" class="profile-gallery__image-prompt">Добавь фото своего бизнеса</div>
      </template>
      <div class="profile-gallery__button" v-if="index === 0 && !galleryIsFull && !readonly" @click="openInput">
        <input type="file" ref="photo" hidden @change="addPhoto($event.target.files)"/>
        <AddPhotoIcon/>
      </div>
    </div>
    <photo-popup v-if="selectedPhoto" :photo="selectedPhoto.publicPath" @close="closePopup"/>
  </div>
</template>

<script>
import PhotoIcon from '@/icons/PhotoIcon'
import AddPhotoIcon from '@/icons/AddPhotoIcon'
import DeleteIcon from '@/icons/DeleteIcon'
import PhotoPopup from '@/controls/PhotoPopup'

export default {
  name: 'ProfileGallery',
  props: {
    images: {
      required: true,
      type: Array
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  components: {
    PhotoIcon,
    AddPhotoIcon,
    DeleteIcon,
    PhotoPopup
  },
  data() {
    return {
      VIEW_IMAGE_COUNT: 3,
      selectedPhoto: undefined
    }
  },
  computed: {
    viewImages() {
      return [...this.images.slice(0, this.VIEW_IMAGE_COUNT)]
    },
    galleryIsFull() {
      let count = 0
      this.viewImages.forEach(element => {
        if (element.publicPath) ++count
      })
      return count >= this.VIEW_IMAGE_COUNT;
    }
  },
  methods: {
    addPhoto() {
      this.$emit('addPhoto')
    },
    deletePhoto(index) {
      this.$emit('deletePhoto', index)
    },
    openPopup(photo) {
      this.selectedPhoto = photo
    },
    closePopup() {
      this.selectedPhoto = undefined
    }
  }

}
</script>

