<template>
  <div class="day">
    <div class="day__info" @click="setDay(day)" :disabled="day.status === 'future'" :class="{'day__info--disabled': day.status === 'future'}">
      День {{ day.day }}
      <TriangleIcon :class="[{ 'active': tracker.day.day === day.day }, 'day__icon']"/>
    </div>
    <template v-if="tracker.day.day === day.day && players">
      <TrackerForDayTable
          class="day__player"
          v-for="(player, index) in players.participants"
          :key="player.id"
          :player="player"
          :columns="columns"
          :place="index + 1"/>
    </template>
  </div>
</template>

<script>
import TrackerForDayTable from '@/controls/TrackerForDayTable'
import TriangleIcon from '@/icons/TriangleIcon'
import controller from '@/controller'
import model from '@/model'
import { mapProps } from '@/utils/model'

export default {
  name: 'Day',
  components: {
    TrackerForDayTable,
    TriangleIcon,
  },
  props: {
    day: {
      type: Object,
      required: true,
    },
    week: {
      type: Object,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    players: {}
  },
  computed: {
    ...mapProps(model.game, ['tracker']),
  },
  methods: {
    setDay(day) {
      if (day.status !== 'future' && this.tracker.day.day !== day.day) {
        controller.game.loadPlayers(this.week, day)
      }
    }
  }
}
</script>

<style lang="scss">
.day {
  display: flex;
  flex-direction: column;

  &__info {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: linear-gradient(112.32deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 101.12%);
    border: 1px solid #ABBBF2;
    border-radius: 14px;
    height: 60px;
    padding: 0 20px;
    font-size: 18px;
    white-space: pre;
    font-weight: bold;
    color: #ABBBF2;
    cursor: pointer;

    &--disabled {
      border-color: #525252;
      opacity: .4;
      cursor: default;
    }

    span {
      color: #546FCE;
    }
  }

  &__info, &__player {
    margin-bottom: 6px;
  }

  &__icon {
    position: absolute;
    right: 20px;
    transform-origin: 50% 50%;
    transition: .3s;

    &.active{
      transform: rotate(180deg);
    }
  }
}
</style>
