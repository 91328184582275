import net from '@/net'
import router from '@/router'
import { getCookie, setCookieSecure } from '@/utils/cookie'
import getFingerprint from '@/utils/fingerprint'
import controller from '@/controller'

export default class Auth {
  constructor(model) {
    this.model = model
    this.model.isLoggedIn = !!getCookie('accessToken')
  }

  async login(user) {
    try {
      const response = await net.auth.login(user, await getFingerprint())
      this.setTokens(response.data.data, 1200, 2592000)
      this.model.isLoggedIn = true
      const path = getCookie('pathUserTriedToEnter')
      if (path) {
        setCookieSecure('pathUserTriedToEnter', '', -1)
        await router.push(path)
      } else {
        await router.push('/profile')
      }
      return response
    } catch (e) {
      return e
    }
  }

  async refresh(refreshToken) {
    try {
      const response = await net.auth.refresh(refreshToken, await getFingerprint())
      this.setTokens(response.data.data, 1200, 2592000)
      this.model.isLoggedIn = true
      return response
    } catch (e) {
      await this.logout()
      return e
    }
  }

  async logout() {
    this.clearTokens()
    this.model.showTimer = false
    this.model.isLoggedIn = false
    controller.game.clearUser()
    router.push('/login')
  }

  async register(user) {
    try {
      const response = await net.auth.register(user, await getFingerprint())
      this.setTokens(response.data.data, 1200, 2592000)
      this.model.isLoggedIn = true
      await router.push('/profile')
      return response
    } catch (e) {
      return this.handleError(e)
    }
  }

  async requestPasswordReset(email) {
    try {
      return await net.auth.requestPasswordReset(email)
    } catch (e) {
      const response = e.response
      this.handleAuthResponse(response, () => {})
      return response
    }
  }

  async resetPassword(password, passwordRepeat, hash) {
    try {
      const response = await net.auth.resetPassword(password, passwordRepeat, hash, await getFingerprint())
      this.setTokens(response.data.data, 1200, 2592000)
      this.model.isLoggedIn = true
      await router.push('/')
    } catch (e) {
      const response = e.response
      this.handleAuthResponse(response, () => {})
      return response
    }
  }

  setTokens({ accessToken, refreshToken }, accessExpires, refreshExpires) {
    setCookieSecure('accessToken', accessToken, accessExpires)
    setCookieSecure('refreshToken', refreshToken, refreshExpires)
  }

  clearTokens() {
    setCookieSecure('accessToken', '', -1)
    setCookieSecure('refreshToken', '', -1)
    this.model.isLoggedIn = false
  }

  handleAuthResponse({ status, data }, okFn, defaultStatusFn = () => {}) {
    switch (status) {
      case 200:
        okFn(data)
        break
      default:
        defaultStatusFn(data)
        break;
    }
  }

  handleError(e) {
    const response = e.response
    this.handleAuthResponse(response, () => {})
    return response
  }
}
