import Vue from 'vue'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import VTooltip from 'v-tooltip'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  environment: process.env.NODE_ENV,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['api-games.toolbox.bz', 'lms.toolbox.bz']
    })
  ],
  tracesSampleRate: 1.0
})

Vue.use(VTooltip, {
  defaultHtml: false,
  defaultContainer: 'body'
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
