<template>
  <div class="timer">
    <div class="timer__title">До старта игры осталось</div>
    <div v-if="width >= 1239" class="timer__row">
      <div class="timer__block">
        <div class="timer__number">{{ dateSelect(timeRemains, 'days', 2) }}</div>
        <div class="timer__desc">дней</div>
      </div>
      <div class="timer__block">
        <div class="timer__number">{{ dateSelect(timeRemains, 'hours', 2) }}</div>
        <div class="timer__desc">часов</div>
      </div>
      <div class="timer__block">
        <div class="timer__number">{{ dateSelect(timeRemains, 'minutes', 2) }}</div>
        <div class="timer__desc">минут</div>
      </div>
      <div class="timer__block">
        <div class="timer__number">{{ dateSelect(timeRemains, 'seconds', 2) }}</div>
        <div class="timer__desc">секунд</div>
      </div>
    </div>
    <div v-else class="timer__unified">
      <div class="timer__block--unified">
        <div class="timer__number--unified">
          {{ dateSelect(timeRemains, 'days', 2) }}
        </div>
        <div class="timer__desc--unified">
          дней
        </div>
      </div>:
      <div class="timer__block--unified">
        <div class="timer__number--unified">
          {{ dateSelect(timeRemains, 'hours', 2) }}
        </div>
        <div class="timer__desc--unified">
          часов
        </div>
      </div>:
      <div class="timer__block--unified">
        <div class="timer__number--unified">
          {{ dateSelect(timeRemains, 'minutes', 2) }}
        </div>
        <div class="timer__desc--unified">
          минут
        </div>
      </div>:
      <div class="timer__block--unified">
        <div class="timer__number--unified">
          {{ dateSelect(timeRemains, 'seconds', 2) }}
        </div>
        <div class="timer__desc--unified">
          секунд
        </div>
      </div>
    </div>
    <div class="timer__prompt">{{ awaitsText }}</div>
    <Button class="timer__btn" :class="{ 'timer__btn--active': !canClick }" :text="canClick ? 'Я жду!' : 'Красавчик, скоро начнем!'" @click="waitButtonClicked"/>
  </div>
</template>

<script>
import Button from '@/controls/Button'
import declineByNumber from '@/utils/declineByNumber'

export default {
  name: 'Timer',
  filters: {

  },
  props: {
    waiting: {
      type: Number,
      required: true
    },
    ddl: {
      type: Date,
      required: true
    },
    canClick: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    now: new Date(),
    timer: undefined,
    width: innerWidth
  }),
  created() {
    setTimeout(() => {
      this.timer = setInterval(() => {
        this.now = new Date()
        if (this.timeRemains.getTime() <= 86401000) {
          this.ddlReached()
        }
      }, 1000)
    }, 1000 - new Date().getMilliseconds())
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    clearInterval(this.timer)
    window.removeEventListener('resize', this.onResize)
  },
  computed: {
    awaitsText() {
      return `${declineByNumber(this.waiting, ['Ждет', 'Ждут', 'Ждут'])} ${this.waiting} ${declineByNumber(this.waiting, ['человек', 'человека', 'человек'])}`
    },
    timeRemains() {
      const timeDeltaMS = this.ddl - this.now
      return new Date(timeDeltaMS)
    }
  },
  methods: {
    onResize() {
      this.width = innerWidth
    },
    dateSelect(value, part, padding) {
      let datePart = 0
      switch (part) {
        case 'days':
          datePart = value.getUTCDate() - 2
          break
        case 'hours':
          datePart = value.getUTCHours()
          break
        case 'minutes':
          datePart = value.getUTCMinutes()
          break
        case 'seconds':
          datePart = value.getUTCSeconds()
      }
      return String(datePart.toString()).padStart(padding, '0')
    },
    ddlReached() {
      this.$emit('ddl-reached')
    },
    waitButtonClicked() {
      if (this.canClick) {
        this.$emit('wait-clicked')
      }
    }
  },
  components: {
    Button
  }
}
</script>

<style lang="scss">
.timer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - (115px + 60px));
  background: #21222D;
  border-radius: 24px;
  color: #ABBBF2;
  letter-spacing: -0.3px;

  .button {
    width: 190px;
  }

  &__title {
    font-size: 44px;
    display: flex;
    align-items: center;
    text-align: center;
    margin-bottom: 60px;

    @media (max-width: 768px) {
      font-size: 32px;
      margin-bottom: 20px;
    }
    @media (max-width: 425px) {
      font-size: 24px;
    }
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
  }

  &__block {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 180px;
    height: 160px;
    border: 1px solid #546FCE;
    background: #2F3145;
    border-radius: 24px;
    margin-right: 30px;

    &:before, &:after {
      content: '';
      position: absolute;
      right: -10px;
      background-color: #546FCE;
      width: 10px;
      height: 10px;
      transform: translateX(100%);
      border-radius: 50%;
      font-size: 100px;
    }

    &:before {
      top: 60px;
    }

    &:after {
      bottom: 60px;
    }

    &:last-child {
      margin-right: 0;

      &:before, &:after {
        content: none;
      }
    }

    &--unified {
      margin: 0 10px;
    }
  }

  &__number {
    font-size: 80px;

    &--unified {
      font-size: 30px;
      color: #ABBBF2;

      @media (min-width: 425px) {
        font-size: 48px;
      }

      @media (min-width: 768px) {
        font-size: 56px;
      }
    }
  }

  &__desc {
    font-size: 24px;
    color: #546FCE;

    &--unified {
      font-size: 14px;
    }
  }

  &__prompt {
    font-size: 18px;
    color: #546FCE;
    margin-bottom: 42px;
    @media (min-width: 1240px) {
      font-size: 26px;
    }
    @media (min-width: 425px) and (max-width: 1239px) {
      font-size: 22px;
    }
  }

  &__btn {
    max-width: 190px;
    @media (max-width: 425px) {
      max-width: 270px;
    }
  }

  &__btn--active {
    cursor: default!important;
    background-color: #6644AF!important;
    color: #fff!important;
    transition: .2s!important;
    &::before {
      background-image: linear-gradient(187.48deg, rgba(44, 42, 110, 0.2) -10.89%, rgba(30, 31, 55, 0.2) 32.57%, rgba(33, 34, 45, 0.2) 71.73%)!important;
    }
  }

  &__unified {
    display: flex;
    border: 1px solid #546FCE;
    background: #2F3145;
    margin: 10px 20px;
    padding: 20px;
    border-radius: 12px;
    font-size: 36px;
    color: #546FCE;

    @media (min-width: 425px) {
      font-size: 48px;
    }

    @media (min-width: 768px) {
      font-size: 56px;
    }
  }
}
</style>
